import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import localize from "lang/localize";
import { DOWNLOAD_APP_APPLE, DOWNLOAD_APP_ANDROID } from "config";

const propTypes = {
  language: PropTypes.string
};

const DownloadAppAlert = props => {
  const [showDownloadAlert, setShowDownloadAlert] = useState(false);
  const downloadLink = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      window.setTimeout(() => {
        if (
          /iPad|iPhone|iPod/.test(navigator.userAgent) &&
          !!DOWNLOAD_APP_APPLE
        ) {
          setShowDownloadAlert(true);
          downloadLink.current = DOWNLOAD_APP_APPLE;
        } else if (
          /Android/.test(navigator.userAgent) &&
          !!DOWNLOAD_APP_ANDROID
        ) {
          setShowDownloadAlert(true);
          downloadLink.current = DOWNLOAD_APP_ANDROID;
        }
      }, 500);
    }
  }, []);

  const handleDownload = () => {
    window.open(downloadLink.current, "_blank");
  };

  return (
    <div>
      {showDownloadAlert && (
        <div className="dim-background">
          <div className="download-modal">
            <strong className="leftmargin-10 bottommargin-20">
              {localize("alert_download_app_text", props.language)}
            </strong>
            <div className="bottommargin-10 fullwidth">
              <button
                className="button inline cta fullwidth"
                onClick={handleDownload}
              >
                <img
                  className="gametize-icon"
                  src="/favicon.ico"
                  alt="gametize icon"
                />
                <span> {localize("alert_open_app_text", props.language)} </span>
              </button>
              <button
                className="button inline fullwidth"
                onClick={() => setShowDownloadAlert(false)}
              >
                {localize("alert_continue_browser_text", props.language)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DownloadAppAlert.propTypes = propTypes;

export default DownloadAppAlert;
