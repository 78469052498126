import React, { Component } from "react";
import PropTypes from "prop-types";

import Dropdown from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import "./Profile.css";
import placeholder_team from "images/core/placeholders/placeholder_team.jpg";
import { TEAM, PROJECT_TEAM, PROJECT_USER_ENQUIRY } from "App/Routes";
import ClaimResultContainer from "components/Project/Activity/ClaimResultContainer";
import Claim from "components/Project/Activity/Claim";
import Link from "components/shared/Link/Link";
import Loading from "components/shared/Loading";

import { getChallengeType } from "services/challengeServices";
import localize from "lang/localize";

import {
  SINGLE_PROJECT_APP,
  USE_ENQUIRY_PAGE,
  CONTACT_EMAIL_DEFAULT,
  CONTACT_EMAIL_TITLE_DEFAULT
} from "config";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  sessionKey: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  userRankStatus: PropTypes.string,
  userRankStatusImage: PropTypes.string,
  showCompletions: PropTypes.bool.isRequired,
  completions: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  meta: PropTypes.array,
  items: PropTypes.array.isRequired,
  itemsLoading: PropTypes.bool,
  navLink: PropTypes.string.isRequired,
  team: PropTypes.string,
  teamId: PropTypes.number,
  teamImg: PropTypes.string,
  claims: PropTypes.array,
  type: PropTypes.oneOf(["project", "challenge", "claim"]),
  projectId: PropTypes.number,
  more: PropTypes.bool,
  handleMore: PropTypes.func,
  handleLike: PropTypes.func,
  removeClaim: PropTypes.func,
  userId: PropTypes.number,
  tab: PropTypes.oneOf(["achievements", "activity"]).isRequired,
  handleComments: PropTypes.func.isRequired,
  language: PropTypes.string,
  contactEmail: PropTypes.string
};

const defaultProps = {
  team: null,
  teamId: null,
  teamImg: placeholder_team,
  contactEmail: CONTACT_EMAIL_DEFAULT
};

class ProfilePage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("userProfilePage")) {
      bodyDOM.className += " userProfilePage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("userProfilePage")) {
      bodyDOM.classList.remove("userProfilePage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderButtons() {
    return (
      <div className="textcenter">
        <button
          id="achievements"
          className={
            "button inline toggle " +
            (this.props.tab === "achievements" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleTab}
        >
          <span>
            {localize("nav_bar_title_items_text", this.props.language)}
          </span>
        </button>
        <button
          id="activity"
          className={
            "button inline toggle " +
            (this.props.tab === "activity" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleTab}
        >
          <span>{localize("button_home_activity", this.props.language)}</span>
        </button>
      </div>
    );
  }

  renderTeam() {
    let teamImg = this.props.teamImg;
    let team = this.props.team;

    return (
      <div id="team" className="container highlightbg topbar">
        <Link
          to={
            this.props.projectId && !SINGLE_PROJECT_APP
              ? PROJECT_TEAM.format(this.props.projectId, this.props.teamId)
              : TEAM.format(this.props.teamId)
          }
        >
          <img
            className="iconxs circle floatleft topmargin-5 rightmargin-10"
            src={teamImg}
            alt={team}
          />
          <div className="toppadding-10">
            <div dangerouslySetInnerHTML={{ __html: team }} />
          </div>
        </Link>
      </div>
    );
  }

  renderMenuDropdownButton() {
    /*
      renders dropdown button (report user) if both conditions are true:
      (1) there is sessionKey
      (2) profile belongs to another user
    */
    if (this.props.id !== this.props.userId && this.props.sessionKey) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            onSelect={(eventKey, event) => {
              event.preventDefault();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={e => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {USE_ENQUIRY_PAGE ? (
                <Link
                  to={PROJECT_USER_ENQUIRY.format(
                    this.props.projectId,
                    this.props.id
                  )}
                >
                  {localize("report_user", this.props.language)}
                </Link>
              ) : (
                <a
                  href={
                    "mailto:" +
                    this.props.contactEmail +
                    "?subject=" +
                    (CONTACT_EMAIL_TITLE_DEFAULT
                      ? CONTACT_EMAIL_TITLE_DEFAULT
                      : "I want to make a report on Gametize (User ID: " +
                        this.props.id +
                        ")")
                  }
                >
                  {localize("report_user", this.props.language)}
                </a>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  renderProfile() {
    let name = this.props.name;
    let img = this.props.img;

    return (
      <div>
        <div className="hide-from-md">
          <div className="pure-u-1-6" />
          <div className="pure-u-1-24" />
          <h2 className="pure-u-7-12 textcenter">{name}</h2>
          <div className="pure-u-1-24" />
          <div className="pure-u-1-6">{this.renderMenuDropdownButton()}</div>
        </div>
        <div className="hide-from-md pure-g profile-container">
          <div className="pure-u-1-3 relative">
            <p className="textcenter">
              {this.props.points.abbreviateNumber()}
              <br />
              {localize("points_just_text", this.props.language)}
            </p>
          </div>
          <div className="pure-u-1-3">
            <span className="square-image-wrapper">
              <span className="square-image circle border-gray-light">
                <img src={img} alt={name} />
              </span>
            </span>
          </div>
          {this.props.showCompletions ? (
            <div id="completions" className="pure-u-1-3 relative">
              <p className="textcenter">
                {this.props.completions.abbreviateNumber()}
                <br />
                {localize("completions_text", this.props.language)}
              </p>
            </div>
          ) : null}
        </div>

        <div className="hide-below-md pure-g profile-container">
          <div className="pure-u-md-4-24" />
          <div className="pure-u-md-4-24">
            {this.props.userRankStatusImage && (
              <div id="rankStatus" className="photo-icon-wrap">
                <img
                  className="rank-status l-rank-status"
                  src={this.props.userRankStatusImage}
                  alt={this.props.userRankStatus}
                />
              </div>
            )}
            <span className="square-image-wrapper">
              <span className="square-image circle border-gray-light">
                <img src={img} alt={name} />
              </span>
            </span>
          </div>
          <div className="pure-u-md-12-24 horizontalpadding relative">
            <div className="textcenter">
              <h2>{name}</h2>
              <p className="profile-points-completions">
                {this.props.points.abbreviateNumber() +
                  " " +
                  localize("points_just_text", this.props.language)}
              </p>
              {this.props.showCompletions && (
                <p className="profile-points-completions">
                  {this.props.completions + " "}
                  {localize("completions_text", this.props.language)}
                </p>
              )}
            </div>
          </div>
          <div className="pure-u-md-4-24">
            {this.renderMenuDropdownButton()}
          </div>
        </div>
      </div>
    );
  }

  renderMeta() {
    if (!this.props.meta) {
      return null;
    } else {
      return (
        <div className="pure-g">
          <div className="pure-u-1 pure-u-sm-16-24 automargin toppadding">
            {this.props.meta.map((field, index) => {
              let key = field.key;
              let keyValue = field.value;

              return (
                <p className="smaller textcenter" id="metaField" key={index}>
                  <strong>{key}</strong>: {keyValue}
                </p>
              );
            })}
          </div>
        </div>
      );
    }
  }

  renderAchievements() {
    const items = this.props.items;

    if (this.props.itemsLoading) {
      return (
        <div id="profileAchievements">
          <h3>{localize("nav_bar_title_items_text", this.props.language)}</h3>
          <Loading />
        </div>
      );
    } else {
      return (
        <div id="profileAchievements">
          <h3>{localize("nav_bar_title_items_text", this.props.language)}</h3>
          {items && items.length > 0 ? (
            <Link to={this.props.navLink}>
              {items.map(item => {
                let imageSmall = item.imageSmall;

                return (
                  <div
                    className="pure-u-1-3 pure-u-md-1-6 innerpadding"
                    key={item.id}
                  >
                    <span className="square-image-wrapper">
                      <span className="square-image circle">
                        <img src={imageSmall} alt="" />
                        {!item.earned && <div className="lockoverlay circle" />}
                      </span>
                    </span>
                  </div>
                );
              })}
            </Link>
          ) : (
            <h6 className="fadeout">
              {this.props.id === this.props.userId
                ? localize("user_self_no_item_text", this.props.language)
                : localize("user_no_item_text", this.props.language)}
            </h6>
          )}
        </div>
      );
    }
  }

  renderActivity() {
    return (
      <div id="profileActivity">
        <h3>{localize("nav_bar_title_claims_text", this.props.language)}</h3>
        {this.props.claims && this.props.claims.length > 0 ? (
          this.props.claims.map((claim, index) => (
            <div className="claimcard-line bottompadding" key={claim.id}>
              <Claim
                key={claim.id}
                projectId={this.props.projectId}
                claimUserId={claim.userId}
                userId={this.props.userId}
                userName={claim.userName}
                userImage={claim.userPhotoSmall}
                userRankStatus={claim.userRankStatus}
                userRankStatusImage={claim.userRankStatusImage}
                createdAtFormatted={claim.createdAtFormatted}
                points={claim.points}
                challengeId={claim.challenge.id}
                challengeTitle={claim.challenge.title}
                challengeType={getChallengeType(
                  claim.challenge.challengeTypeId,
                  claim.challenge.photoOnly,
                  claim.challenge.multiSelect,
                  claim.challenge.challengeType
                )}
                challengeTypeId={claim.challenge.challengeTypeId}
                isConfirmationChallenge={
                  claim.challenge.challengeTypeId === 11 &&
                  claim.challenge.confirmation === true
                }
                correctAnswer={claim.correctAnswer}
                challengePhotoOnly={claim.challenge.photoOnly}
                privateClaim={claim.challenge.privateClaim}
                language={this.props.language}
              />
              <ClaimResultContainer
                id={claim.id}
                claimUserId={claim.userId}
                userId={this.props.userId}
                type={this.props.type}
                challengeTypeId={claim.challenge.challengeTypeId}
                claimImage={claim.claimPhotoSmall}
                entries={claim.entries}
                claimMessage={claim.message}
                likeNo={claim.likeNo}
                commentNo={claim.commentNo}
                ratedLike={claim.ratedLike}
                handleLike={event => this.props.handleLike(event, claim.id)}
                correctAnswer={claim.correctAnswer}
                removeClaim={this.props.removeClaim}
                privateClaim={claim.challenge.privateClaim}
                userName={claim.userName}
                handleComments={this.props.handleComments}
              />
            </div>
          ))
        ) : (
          <h6 className="fadeout">
            {localize("empty_claim_table_text", this.props.language)}
          </h6>
        )}
        {this.props.isLoadingMore ? <Loading /> : null}
        {/* {this.props.more && (
          <button className="button automargin" onClick={this.props.handleMore}>
            {localize("more_text", this.props.language)}
          </button>
        )} */}
      </div>
    );
  }

  render() {
    return (
      <div className="profile">
        {this.props.team ? this.renderTeam() : null}
        <div className="container verticalpadding darkbg">
          <div className="innerblock">
            {this.renderProfile()}
            {this.renderMeta()}
          </div>
        </div>
        {this.props.showCompletions && (
          <div className="container verticalpadding">
            <div className="innerblock pure-g">
              <div className="pure-u-2-24 pure-u-md-4-24" />
              <div className="pure-u-20-24 pure-u-md-16-24">
                {this.renderButtons()}
              </div>
              <div className="pure-u-2-24 pure-u-md-4-24" />
            </div>
          </div>
        )}
        <div className="container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1-24 pure-u-md-4-24" />
            <div className="pure-u-22-24 pure-u-md-16-24">
              {this.props.tab === "achievements" && this.renderAchievements()}
              {this.props.tab === "activity" && this.renderActivity()}
            </div>
            <div className="pure-u-1-24 pure-u-md-4-24" />
          </div>
        </div>
      </div>
    );
  }
}

ProfilePage.propTypes = propTypes;
ProfilePage.defaultProps = defaultProps;

export default ProfilePage;
