import React, { Component } from "react";
import { connect } from "react-redux";

import LoginHomePage from "./LoginHomePage";

import {
  setSessionKey,
  setUserId,
  setProject,
  showAlertWithTimeout
} from "actions";
import { SECRET_KEY } from "config";
import { GET_TOPICS, GET_FACEBOOK_OAUTH, GET_GOOGLE_OAUTH } from "services/api";
import getApiGenerator from "services/getApiGenerator";
import pushApiGenerator from "services/pushApiGenerator";
import localStorageService from "services/localStorageService";
import sessionStorageService from "services/sessionStorageService";
import loginServices from "services/loginServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    projectId: state.projectId,
    guestEnabled:
      state.project && state.project.guestEnabled
        ? state.project.guestEnabled
        : false,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: sessionKey => {
      dispatch(setSessionKey(sessionKey));
    },
    setUserId: user => {
      dispatch(setUserId(user));
    },
    setProject: project => {
      dispatch(setProject(project));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

export class LoginHomeContainer extends Component {
  constructor() {
    super();
    this.state = {
      showGuestLoginDialog: false
    };

    /* Guest Login Dialogs */
    this.handleOpenGuestLoginDialog = this.handleOpenGuestLoginDialog.bind(
      this
    );
    this.handleCloseGuestLoginDialog = this.handleCloseGuestLoginDialog.bind(
      this
    );

    /* OAuth */
    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseFacebookError = this.responseFacebookError.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseGoogleError = this.responseGoogleError.bind(this);
    this.responseMicrosoft = this.responseMicrosoft.bind(this);
    this.handleSaveSessionAndRedirect = this.handleSaveSessionAndRedirect.bind(
      this
    );
  }

  componentDidMount() {
    if (this.props.projectId) {
      this.getProjectGuestEnabled();
    }

    if (this.props.sessionKey && this.props.userId) {
      let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

      /* loginRedirectUrl check is necessary for IE. */
      /* Somehow componentWillMount() will run for LoginContainer in IE, */
      /* even after hitting login submit where routing is supposed to occur */
      loginServices.postLoginReplaceAndNavigate(
        this.props.projectId,
        loginRedirectUrl,
        false
      );
    }
  }

  getProjectGuestEnabled() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (!(err || res.body.code !== 200)) {
        this.props.setProject(res.body.game);
      }
    });
  }

  handleOpenGuestLoginDialog = event => {
    event.preventDefault();

    this.setState({
      showGuestLoginDialog: true
    });
  };

  handleCloseGuestLoginDialog = event => {
    event.preventDefault();

    this.setState({
      showGuestLoginDialog: false
    });
  };

  handleSaveSessionAndRedirect(userId, sessionKey, isFirstLogin) {
    localStorageService.saveState({
      user_id: userId,
      sessionKey: sessionKey
    });

    if (!!userId && !!sessionKey) {
      this.props.setUserId(userId);
      this.props.setSessionKey(sessionKey);
    }

    // loads loginRedirectUrl from sessionStorage (if available)
    // eventually loginRedirectUrl will be dumped when new page is loaded
    // (not REGISTER nor LOGIN nor RESET_PASSWORD)
    // for the aforementioned dumping, see SingleProjectApp.js
    let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

    /* Use isFirstLogin flag to determine if tours should be displayed */
    loginServices.postLoginReplaceAndNavigate(
      this.props.projectId,
      loginRedirectUrl,
      isFirstLogin
    );
  }

  responseMicrosoft(err, response) {
    // let query = {
    //   mobile: true,
    //   type: "login",
    //   request_token: response.accessToken,
    //   api_key: SECRET_KEY
    // };
    // let req = pushApiGenerator(GET_MICROSOFT_OAUTH, query);
    // req.end((err, res) => {
    //   if (err || res.body.code !== 200) {
    //     if (res.body.error) {
    //       this.props.showAlertWithTimeout({
    //         text: res.body.error,
    //         type: "error"
    //       });
    //     }
    //   } else {
    //     //releasing id token from microsoft login to prevent auto-logins
    //     const sessionStorage = window.sessionStorage;
    //     sessionStorage.removeItem("msal.idtoken");
    //     this.handleSaveSessionAndRedirect(
    //       res.body.userId,
    //       res.body.sessionKey,
    //       res.body.firstLogin
    //     );
    //   }
    // });
  }

  responseFacebook(response) {
    let query = {
      mobile: true,
      type: "login",
      request_token: response.accessToken,
      api_key: SECRET_KEY
    };

    let req = pushApiGenerator(GET_FACEBOOK_OAUTH, query);

    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        let isFirstLogin = res.body.firstLogin;

        if (!isFirstLogin) {
          // If user has logged in to platform before, refer to local storage to see if user has logged in to this specific app before
          // 1. If user has never logged in to any account in this browser, show tour screen
          // 2. If previous login is for a different account, show tour screen
          // 3. If previous login is for the same account, do not show tour screen (even if the account has been used in this browser in the past before)
          const lastUserId = localStorageService.getItem("last_user_id");

          if (!lastUserId) {
            // First time logging in to this app in current browser
            isFirstLogin = true;
          } else if (parseInt(lastUserId) !== this.state.userId) {
            // Check if previous login in current browser is for the same account
            isFirstLogin = true;
          }

          localStorageService.setItem("last_user_id", this.state.userId);
        }

        this.handleSaveSessionAndRedirect(
          res.body.userId,
          res.body.sessionKey,
          isFirstLogin
        );
      }
    });
  }

  responseFacebookError(error) {
    return false;
  }

  responseGoogle(response) {
    let query = {
      mobile: true,
      type: "login",
      request_token: response.credential,
      api_key: SECRET_KEY
    };

    let req = pushApiGenerator(GET_GOOGLE_OAUTH, query);

    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        let isFirstLogin = res.body.firstLogin;

        if (!isFirstLogin) {
          // If user has logged in to platform before, refer to local storage to see if user has logged in to this specific app before
          // 1. If user has never logged in to any account in this browser, show tour screen
          // 2. If previous login is for a different account, show tour screen
          // 3. If previous login is for the same account, do not show tour screen (even if the account has been used in this browser in the past before)
          const lastUserId = localStorageService.getItem("last_user_id");

          if (!lastUserId) {
            // First time logging in to this app in current browser
            isFirstLogin = true;
          } else if (parseInt(lastUserId) !== this.state.userId) {
            // Check if previous login in current browser is for the same account
            isFirstLogin = true;
          }

          localStorageService.setItem("last_user_id", this.state.userId);
        }

        this.handleSaveSessionAndRedirect(
          res.body.userId,
          res.body.sessionKey,
          isFirstLogin
        );
      }
    });
  }

  responseGoogleError(error, details) {
    if (error && details) {
      this.props.showAlertWithTimeout({
        text: details,
        type: "error"
      });
    }
  }

  render() {
    return (
      <LoginHomePage
        projectId={this.props.projectId}
        language={this.props.language}
        /* Guest Login Dialog */
        guestEnabled={this.props.guestEnabled}
        showGuestLoginDialog={this.state.showGuestLoginDialog}
        handleOpenGuestLoginDialog={this.handleOpenGuestLoginDialog}
        handleCloseGuestLoginDialog={this.handleCloseGuestLoginDialog}
        /* OAuth */
        responseFacebook={this.responseFacebook}
        responseFacebookError={this.responseFacebookError}
        responseMicrosoft={this.responseMicrosoft}
        responseGoogle={this.responseGoogle}
        responseGoogleError={this.responseGoogleError}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginHomeContainer);
