import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
  LeaderboardContainer
} from "components/Project/Leaderboard/LeaderboardContainer";

/**
 * CUSTOM FOR CYBERWELLNESS
 * Override componentWillUnmount()
 */
class CyberwellnessLeaderboardContainer extends LeaderboardContainer {
  componentWillUnmount() {
    // Remove this line from core, as sidebar elements are missing.
    //this.state.requests.forEach(req => req.abort());
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessLeaderboardContainer);
