import en from "./en.json";
import enReact from "./en-react.json";
import zhHans from "./zhHans.json";
import zhHansReact from "./zhHans-react.json";
import zhHant from "./zhHant.json";
import zhHantReact from "./zhHant-react.json";
import msMY from "./msMY.json";
import msMYReact from "./msMY-react.json";
import es from "./es.json";
import esReact from "./es-react.json";
import idReg from "./id.json";
import idReact from "./id-react.json";
import sv from "./sv.json";
import svReact from "./sv-react.json";
import th from "./th.json";
import thReact from "./th-react.json";
import tr from "./tr.json";
import trReact from "./tr-react.json";
import vi from "./vi.json";
import viReact from "./vi-react.json";
import ptBR from "./ptBR.json";
import ptBRReact from "./ptBR-react.json";
import fr from "./fr.json";
import frReact from "./fr-react.json";
import ko from "./ko.json";
import koReact from "./ko-react.json";

/* Custom: custom lang packs */
/* For new languages, use English instead of maintaining a new file since they are not requested for this project */
import enCustom from "./en-custom.json";
import zhHansCustom from "./zhHans-custom.json";
import zhHantCustom from "./zhHant-custom.json";
import msMYCustom from "./msMY-custom.json";
import esCustom from "./es-custom.json";
import idCustom from "./id-custom.json";
import svCustom from "./sv-custom.json";
import thCustom from "./th-custom.json";
import trCustom from "./tr-custom.json";

function localize(id, language) {
  let lang;

  /* Use lower case */
  switch (language) {
    case "en":
    case "en-ar":
    case "en-au":
    case "en-bz":
    case "en-ca":
    case "en-cb":
    case "en-cn":
    case "en-dk":
    case "en-hk":
    case "en-in":
    case "en-id":
    case "en-ie":
    case "en-jm":
    case "en-ja":
    case "en-my":
    case "en-nz":
    case "en-no":
    case "en-ph":
    case "en-pr":
    case "en-sg":
    case "en-za":
    case "en-se":
    case "en-gb":
    case "en-us":
    case "en-zw":
    case "eng":
      lang = Object.assign({}, en, enReact, enCustom);
      break;
    case "zh-hans":
    case "zh-sg":
    case "zh-cn":
      lang = Object.assign({}, zhHans, zhHansReact, zhHansCustom);
      break;
    case "zh-hant":
    case "zh-tw":
    case "zh-hk":
    case "zh-mo":
      lang = Object.assign({}, zhHant, zhHantReact, zhHantCustom);
      break;
    case "ms":
    case "ms-my":
      lang = Object.assign({}, msMY, msMYReact, msMYCustom);
      break;
    case "es":
    case "es-es":
    case "es-es_tradnl":
    case "es-mx":
    case "es-gt":
    case "es-cr":
    case "es-pa":
    case "es-do":
    case "es-ve":
    case "es-co":
    case "es-pe":
    case "es-ar":
    case "es-ec":
    case "es-cl":
    case "es-uy":
    case "es-py":
    case "es-bo":
    case "es-sv":
    case "es-hn":
    case "es-ni":
    case "es-pr":
    case "es-us":
    case "spa":
      lang = Object.assign({}, es, esReact, esCustom);
      break;
    case "fr":
      lang = Object.assign({}, fr, frReact, enCustom); // Fully machine translated
      break;
    case "pt":
    case "pt-br":
      lang = Object.assign({}, ptBR, ptBRReact, enCustom); // Translation was provided by a Brazilian client
      break;
    case "id":
    case "id-id":
    case "ind":
      lang = Object.assign({}, idReg, idReact, idCustom);
      break;
    case "sv":
    case "swe":
      lang = Object.assign({}, sv, svReact, svCustom);
      break;
    case "th":
    case "tha":
      lang = Object.assign({}, th, thReact, thCustom);
      break;
    case "tr":
    case "tur":
      lang = Object.assign({}, tr, trReact, trCustom);
      break;
    case "vi":
    case "vie":
      lang = Object.assign({}, vi, viReact, enCustom);
      break;
    case "ko":
      lang = Object.assign({}, ko, koReact, enCustom);
      break;
    default:
      lang = Object.assign({}, en, enReact, enCustom);
  }

  return lang[id];
}

export default localize;
