import React from "react";
import PropTypes from "prop-types";

import ChallengeCompletedModal from "components/Challenge/ChallengeCompletedModal/ChallengeCompletedModal";

import Modal from "react-modal";

import localize from "lang/localize";

const propTypes = {
  challengeCompletedArr: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  goToNextChallenge: PropTypes.func.isRequired,
  goToChallengeAFriend: PropTypes.func.isRequired,
  language: PropTypes.string,
  /* Custom: goToNextTopic and goToHome */
  goToNextTopic: PropTypes.func,
  goToHome: PropTypes.func
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class CyberwellnessChallengeCompletedModal extends ChallengeCompletedModal {
  /* Custom: handling for topicIdNext and allTopicsCompleted */
  renderMainCTAButtons(
    challenge,
    nextChallengeId,
    lastChallengeCompletedId,
    topicIdNext,
    allTopicsCompleted
  ) {
    if (allTopicsCompleted) {
      /* all topics completed, return to home */
      return (
        <div className="modal-close-container">
          {this.renderChallengeAFriendButtonContainer(
            challenge,
            lastChallengeCompletedId
          )}
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button fullwidth button-modal-close"
                onClick={() => {
                  this.props.handleCloseModal(lastChallengeCompletedId);
                }}
              >
                {localize(
                  "button_feedback_view_challenge",
                  this.props.language
                )}
              </button>
            </div>
            <div className="pure-u-1-8" />
          </div>
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button cta fullwidth nomargin lowercase"
                onClick={() => {
                  this.props.goToHome(lastChallengeCompletedId);
                }}
                id="goToHomeButton"
              >
                {localize("back_button_text_start", this.props.language).format(
                  localize("icon_home", this.props.language)
                )}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (topicIdNext) {
      /* next topic available */
      return (
        <div className="modal-close-container">
          {this.renderChallengeAFriendButtonContainer(
            challenge,
            lastChallengeCompletedId
          )}
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button fullwidth button-modal-close"
                onClick={() => {
                  this.props.handleCloseModal(lastChallengeCompletedId);
                }}
              >
                {localize(
                  "button_feedback_view_challenge",
                  this.props.language
                )}
              </button>
            </div>
            <div className="pure-u-1-8" />
          </div>
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button cta fullwidth nomargin lowercase"
                onClick={() => {
                  this.props.goToNextTopic(
                    topicIdNext,
                    lastChallengeCompletedId
                  );
                }}
                id="goToNextTopicButton"
              >
                {localize("button_next_topic", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (nextChallengeId) {
      /* next challenge available */
      return (
        <div className="modal-close-container">
          {this.renderChallengeAFriendButtonContainer(
            challenge,
            lastChallengeCompletedId
          )}
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button fullwidth button-modal-close"
                onClick={() => {
                  this.props.handleCloseModal(lastChallengeCompletedId);
                }}
              >
                {localize(
                  "button_feedback_view_challenge",
                  this.props.language
                )}
              </button>
            </div>
            <div className="pure-u-1-8" />
          </div>
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button cta fullwidth nomargin"
                onClick={() => {
                  this.props.goToNextChallenge(
                    nextChallengeId,
                    lastChallengeCompletedId
                  );
                }}
                id="goToNextChallengeButton"
              >
                {localize(
                  "button_feedback_view_next_challenge",
                  this.props.language
                )}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      /* everything else - just view challenge button */
      return (
        <div className="modal-close-container">
          {this.renderChallengeAFriendButtonContainer(
            challenge,
            lastChallengeCompletedId
          )}
          <div className="pure-g">
            <div className="pure-u-1-8" />
            <div className="pure-u-3-4">
              <button
                className="button button-modal-close fullwidth nomargin"
                onClick={() => {
                  this.props.handleCloseModal(lastChallengeCompletedId);
                }}
              >
                {localize(
                  "button_feedback_view_challenge",
                  this.props.language
                )}
              </button>
            </div>
            <div className="pure-u-1-8" />
          </div>
        </div>
      );
    }
  }

  /* Custom: this.renderMainCtaButtons() accepts topicIdNext and allTopicsCompleted as param */
  render() {
    if (
      this.props.challengeCompletedArr.length > 0 &&
      this.props.challengeCompletedArr[0].challengeCompleted
    ) {
      /* Initialising variables */
      const lastChallengeCompletedId = this.props.challengeCompletedArr[0].id;
      const challenge = this.props.challengeCompletedArr[0].challengeCompleted;
      const challengeTypeId = challenge.challengeTypeId;
      const nextChallengeId = challenge.nextChallengeId;
      /* Custom: topicIdNext and allTopicsCompleted */
      const topicIdNext = challenge.topicIdNext;
      const allTopicsCompleted = challenge.allTopicsCompleted;

      return (
        <div>
          <Modal
            isOpen={this.props.showModal}
            contentLabel={localize(
              "feedback_default_text",
              this.props.language
            )}
            onRequestClose={() => {
              this.props.handleCloseModal(lastChallengeCompletedId);
            }}
            shouldCloseOnOverlayClick={false}
            className="modal-content challenge-completed-modal-content"
            overlayClassName="modal-overlay"
          >
            {this.renderCloseButton(lastChallengeCompletedId)}

            <div className="modal-body">
              {this.renderPointsWrapper(challenge, challengeTypeId)}

              {this.renderCorrectAnswer(challenge)}

              {challenge.completionMessage &&
                this.renderCompletionMessage(challenge)}

              {challenge.tag && this.renderPersonality(challenge)}

              {challenge.items &&
                challenge.items.length >= 1 &&
                this.renderAchievements(challenge)}
            </div>

            {this.renderMainCTAButtons(
              challenge,
              nextChallengeId,
              lastChallengeCompletedId,
              topicIdNext,
              allTopicsCompleted
            )}
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  }
}

CyberwellnessChallengeCompletedModal.propTypes = propTypes;

export default CyberwellnessChallengeCompletedModal;
