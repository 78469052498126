import React from "react";
import PropTypes from "prop-types";

import Dotdotdot from "react-dotdotdot";

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  language: PropTypes.string
};

const HomeFlashcard = props => (
  <div className="home-flashcard topic-card card">
    <div className="pure-g">
      {/* Flashcard Image (Desktop View) */}
      <div className="pure-u-lg-7-24 home-flashcard-img">
        <div
          className="card-img"
          style={{
            backgroundImage: "url(" + props.image + ")"
          }}
        ></div>
      </div>
      <div className="pure-u-lg-17-24 home-flashcard-info">
        {/* Flashcard Image (Mobile View) */}
        <div className="pure-u-lg-7-24 home-flashcard-img-mobile">
          <div
            className="card-img"
            style={{
              backgroundImage: "url(" + props.image + ")"
            }}
          ></div>
        </div>
        <div className="innerpadding">
          {/* Flashcard Title */}
          <div className="home-flashcard-title">
            <Dotdotdot clamp={3}>{props.title}</Dotdotdot>
          </div>
          {/* Flashcard Description */}
          <p
            className="card-desc"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
      </div>
    </div>
  </div>
);

HomeFlashcard.propTypes = propTypes;

export default HomeFlashcard;
