import React from "react";

import "./Project.css";
import BreadcrumbsContainer from "custom-components/shared/Breadcrumbs/BreadcrumbsContainer";
import ProjectPage from "components/Project/ProjectPage";
import DesktopProjectPage from "./DesktopProjectPage";
import localize from "lang/localize";

class CyberwellnessProjectPage extends ProjectPage {
  /*
    Custom: removal of 640 desktop project checks and MobileProjectPage,
    use custom DesktopProjectPage for all rendering
  */
  render() {
    const FLAVOR_TEXT_ARR = [
      localize("showcase_suggestion_1", this.props.language),
      localize("showcase_suggestion_2", this.props.language),
      localize("showcase_suggestion_3", this.props.language)
    ];

    return (
      <div className="project container nopadding">
        {/* Fixed-positioned background for Screen */}
        <div className="background-cyberwellness"></div>

        {this.props.showPopup && this.renderPopupAlertDialog()}
        {this.props.showShareGameDialog && this.renderShareGameDialog()}
        {this.props.showTopicsListDialog && this.renderTopicsListDialog()}
        <BreadcrumbsContainer />
        <div className="desktop-project pure-g bottompadding-floating">
          {/* Render desktop Project page here */}
          <DesktopProjectPage
            categoryId={this.props.id}
            categories={this.props.categories}
            topics={this.props.topics}
            more={this.props.more}
            randomNumber={this.state.randomNumber}
            flavorTextArr={FLAVOR_TEXT_ARR}
            showcaseProject={this.props.showcaseProject}
            addAnchors={this.addAnchors}
            handleMore={this.props.handleMore}
            renderTopics={this.renderTopics}
            language={this.props.language}
            /* Custom: topic lock check function to be passed down */
            getTopicLock={this.getTopicLock}
          />
        </div>
      </div>
    );
  }
}

export default CyberwellnessProjectPage;
