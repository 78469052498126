import React from "react";
import PropTypes from "prop-types";

import LoginContainer from "components/Login/LoginContainer";

import sessionStorageService from "services/sessionStorageService";
import urlServices from "services/urlServices";

import localize from "lang/localize";

const propTypes = {
  showLogin: PropTypes.bool,
  message: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number
};

const defaultProps = {
  showLogin: false
};

const NotLoggedIn = props => {
  // save current path into sessionStorage
  // if not redirected to actual login page (project or not)
  if (!urlServices.isLoginRedirectionPage()) {
    sessionStorageService.setItem(
      "loginRedirectUrl",
      urlServices.defaultPath()
    );
  }

  if (props.showLogin && props.projectId) {
    return (
      <div id="logincontainer">
        <LoginContainer id={props.projectId} />
      </div>
    );
  } else if (props.showLogin) {
    return (
      <div id="logincontainer">
        <LoginContainer />
      </div>
    );
  } else {
    return (
      <div className="container verticalpadding">
        <div className="innerblock">
          <div className="textcenter">
            <span id="message">
              {props.message
                ? props.message
                : localize("require_login_view_page", props.language)}
            </span>
          </div>
        </div>
      </div>
    );
  }
};

NotLoggedIn.propTypes = propTypes;
NotLoggedIn.defaultProps = defaultProps;

export default NotLoggedIn;
