import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "components/shared/Link/Link";

import placeholder_notification from "images/core/placeholders/placeholder_notification_icon.jpg";

import anchorme from "anchorme";

const propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  type: PropTypes.string,
  link: PropTypes.string,
  bold: PropTypes.bool
};

const defaultProps = {
  img: placeholder_notification,
  bold: false
};

class Notification extends Component {
  addAnchors(text) {
    // add anchor tags to links
    if (text) {
      text = anchorme(text, {
        exclude: urlObj => {
          urlObj.encoded = urlObj.encoded.replace(/%25/g, "%");
          return false;
        },
        attributes: [{ name: "class", value: "link break-url" }]
      });
    }
    return text;
  }

  render() {
    let titleAnchored = this.addAnchors(this.props.title);
    let img = this.props.img;

    return (
      <Link to={this.props.link}>
        <div className="pure-g bottompadding">
          <div className="pure-u-5-24 pure-u-sm-3-24">
            <img className="iconsmall" src={img} alt="" />
          </div>
          <div className="pure-u-1-24 pure-u-sm-1-24" />
          <div className="pure-u-17-24 pure-u-sm-19-24">
            {this.props.bold ? (
              <p className="nomargin">
                <strong dangerouslySetInnerHTML={{ __html: titleAnchored }} />
              </p>
            ) : (
              <p
                className="nomargin"
                dangerouslySetInnerHTML={{ __html: titleAnchored }}
              />
            )}
            <div className="nomargin smalltext">{this.props.time}</div>
          </div>
          <div className="pure-u-1-24" />
        </div>
      </Link>
    );
  }
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
