import React from "react";

import dbs_logo from "images/custom/home/dbs_logo.png";
import { HOME } from "App/Routes";
import Link from "components/shared/Link/Link";
import Topbar from "components/Topbar/Topbar";

import { USE_LOGIN_HOME_TEMPLATE_2, ENABLE_LOGIN_HOME } from "config";
import localize from "lang/localize";

class CyberwellnessTopbar extends Topbar {
  /* Custom: grid sizes. removed topbar title */
  renderTopbar() {
    return (
      <div className="topbar topbarbg pure-g fixed fullwidth">
        <div className="pure-u-7-24 pure-u-sm-6-24 pure-u-md-9-24">
          {this.renderBack()}
        </div>
        <div className="pure-u-10-24 pure-u-sm-12-24 pure-u-md-6-24">
          <div className="title-wrap">
            <Link to={HOME}>
              {localize("dbs_cyberwellness_text", this.props.language)}
            </Link>
          </div>
        </div>
        <div className="pure-u-7-24 pure-u-sm-6-24 pure-u-md-9-24">
          {this.renderRightTopbar()}
        </div>
      </div>
    );
  }

  /**
   * Render DBS logo
   * (Adds an additional class to logo when user is in logged out state.)
   */
  renderDBSLogoContainer() {
    if (
      this.props.state === "login" ||
      this.props.state === "login-secondary" ||
      this.props.state === "login-home" ||
      this.props.state === "reset-password" ||
      this.props.state === "post-login"
    ) {
      return (
        <div className="dbs-logo-container without-topbar-menu flex align-items-center">
          <img src={dbs_logo} className="imgresponsive" alt="" />
        </div>
      );
    } else {
      return (
        <div className="dbs-logo-container flex align-items-center">
          <img src={dbs_logo} className="imgresponsive" alt="" />
        </div>
      );
    }
  }

  /* Custom: removal of language selection icon, inclusion of DBS logo */
  renderRightTopbar() {
    /* No menu icon on login and register pages */
    return (
      <div className="flex align-items-center justify-content-flexend">
        {this.renderDBSLogoContainer()}
        <div className="icons-wrap">
          {this.props.state !== "login" &&
            this.props.state !== "login-secondary" &&
            this.props.state !== "login-home" &&
            this.props.state !== "reset-password" &&
            this.props.state !== "post-login" &&
            this.renderTopbarMenu()}
        </div>
      </div>
    );
  }

  /* Custom: back button text in Project view */
  renderBackText() {
    switch (this.props.state) {
      case "login":
        if (ENABLE_LOGIN_HOME) {
          return localize("icon_home", this.props.language);
        } else if (this.props.state === "login-secondary") {
          return localize("nav_bar_title_login_text", this.props.language);
        } else {
          return null;
        }
      case "login-secondary":
        if (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_2) {
          return localize("icon_home", this.props.language);
        } else if (this.props.state === "login-secondary") {
          return localize("nav_bar_title_login_text", this.props.language);
        } else {
          return null;
        }
      case "reset-password":
      case "post-login":
        return localize("nav_bar_title_login_text", this.props.language);
      case "projects-list":
      case "search-projects":
        return localize("nav_bar_title_games_home_text", this.props.language);
      /* Custom: inclusion of home-single below (together with existing projecthome) for rendering Home text */
      case "home-single":
      case "projecthome":
        if (this.props.isEmbeddedProject) {
          return null;
        } else {
          return localize("nav_bar_title_games_home_text", this.props.language);
        }
      case "topic":
      case "topic-category":
        return this.props.project && this.props.project.title
          ? localize("dbs_login_title", this.props.language)
          : localize("back_button_back_text", this.props.language);
      case "challenge":
      case "challenge-category":
        return this.props.topic && this.props.topic.title
          ? this.props.topic.title
          : localize("back_button_back_text", this.props.language);
      case "challenge-comments":
        return this.props.challenge && this.props.challenge.title
          ? this.props.challenge.title
          : localize("back_button_back_text", this.props.language);
      /* Custom: removed "home-single" null handling below */
      case "home-multi":
      case "login-tour":
        return null;
      default:
        return localize("back_button_back_text", this.props.language);
    }
  }

  /* Custom: removal of null handling of "home-single" */
  renderBack() {
    /* Special case: at topic page - you are a project administrator, but not a project player */
    if (
      (this.props.state === "topic" || this.props.state === "topic-category") &&
      !this.props.projectId
    ) {
      return null;
    } else if (
      this.props.state === "home-multi" ||
      this.props.state === "login-tour" ||
      this.props.state === "home"
    ) {
      /* Custom: "home-single" removed from "else-if" statement above
      /* There's no reason for a back button in the Player home page */
      return null;
    } else if (
      this.props.state === "projecthome" &&
      this.props.isEmbeddedProject
    ) {
      /* For embedded projects, no way to go back home */
      return null;
    } else if (this.props.backLink !== "") {
      /*
      For cases that involve routing
    */
      return (
        <div className="back-button-wrap">
          {/* Use Back with no copywriting for mobile sizes */}
          <Link to={this.props.backLink} className="hide-from-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-angle-left" aria-hidden="true" />
              {localize("back_button_back_text", this.props.language)}
            </span>
          </Link>
          {/* Use renderBackText() for desktop sizes */}
          <Link to={this.props.backLink} className="hide-below-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-angle-left" aria-hidden="true" />
              {this.renderBackText()}
            </span>
          </Link>
        </div>
      );
    } else {
      /*
      For cases that involve simulated browser back button
    */

      if (window.history.length <= 1) {
        return null; // Remove back button if no history
      }

      return (
        <div className="back-button-wrap">
          <span
            className="back-button-text text-truncated back-button-history-text"
            onClick={this.props.handleBack}
          >
            <i className="fas fa-angle-left" aria-hidden="true" />
            {this.renderBackText()}
          </span>
        </div>
      );
    }
  }
}

export default CyberwellnessTopbar;
