import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Project.css";
import TopicCard from "./TopicCard";
import PopupAlertDialog from "./PopupAlertDialog/PopupAlertDialog";
import ShareGameDialog from "components/shared/Dialogs/ShareGameDialog/ShareGameDialog";
import DesktopProjectPage from "./DesktopProjectPage";
import MobileProjectPage from "./MobileProjectPage";

import anchorme from "anchorme";

import sessionStorageService from "services/sessionStorageService";
import localize from "lang/localize";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  sessionKey: PropTypes.string,
  projectId: PropTypes.number /* Project ID */,
  id: PropTypes.string /* Category ID */,
  project: PropTypes.object.isRequired,
  projectImage: PropTypes.string,
  buttons: PropTypes.array,
  categories: PropTypes.array,
  topics: PropTypes.array.isRequired,
  more: PropTypes.bool,
  showcaseProject: PropTypes.object,
  popup: PropTypes.object,
  showPopup: PropTypes.bool.isRequired,
  handleClosePopupAlertDialog: PropTypes.func.isRequired,
  showShareGameDialog: PropTypes.bool.isRequired,
  handleOpenShareGameDialog: PropTypes.func.isRequired,
  handleCloseShareGameDialog: PropTypes.func.isRequired,
  handleMore: PropTypes.func,
  user: PropTypes.object,
  team: PropTypes.object,
  notifications: PropTypes.number.isRequired,
  inboxUnread: PropTypes.number.isRequired,
  language: PropTypes.string,
  handleKeyPress: PropTypes.func,
  handleSearchChange: PropTypes.func,
  handleSubmit: PropTypes.func
};

const defaultProps = {
  more: false,
  projectId: null
};

class ProjectPage extends Component {
  constructor() {
    super();
    this.state = {
      randomNumber: Math.floor(Math.random() * 3)
    };
    this.renderTopics = this.renderTopics.bind(this);
  }

  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("projectPage")) {
      bodyDOM.className += " projectPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("projectPage")) {
      bodyDOM.classList.remove("projectPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  addAnchors(text) {
    // add anchor tags to links
    if (text) {
      text = anchorme(text, {
        exclude: urlObj => {
          urlObj.encoded = urlObj.encoded.replace(/%25/g, "%");
          return false;
        },
        attributes: [
          { name: "target", value: "blank" },
          { name: "class", value: "link default break-url" },
          { name: "rel", value: "external" }
        ]
      });
    }
    return text;
  }

  getTopicLock(unlockable, locked) {
    if (unlockable && locked !== false) {
      return true;
    }
    return false;
  }

  /*
    isMobile bool prop determines if these Topic cards
    are rendered in mobile or desktop view: for
    ScrollableAnchor library
  */
  renderTopics(topics, isMobile) {
    return topics.map((topic, index) => (
      <div className="pure-u-1 pure-u-sm-12-24" key={topic.id}>
        <TopicCard
          sessionKey={this.props.sessionKey}
          projectId={this.props.projectId}
          isMobile={isMobile}
          allowReset={topic.allowReset || false}
          title={topic.title}
          description={topic.description}
          totalChallenges={topic.challengeNo}
          completedChallenges={topic.userChallengeCompletedNo || 0}
          img={topic.bannerImage}
          id={topic.id}
          started={topic.joined || false}
          locked={this.getTopicLock(topic.unlockable, topic.locked)}
          expired={typeof topic.expired === "boolean" ? topic.expired : false}
          completed={
            topic.challengeNo === topic.userChallengeCompletedNo || false
          }
          language={this.props.language}
        />
      </div>
    ));
  }

  renderPopupAlertDialog() {
    return (
      <PopupAlertDialog
        showDialog={true}
        handleClosePopupAlertDialog={this.props.handleClosePopupAlertDialog}
        popup={this.props.popup}
      />
    );
  }

  renderShareGameDialog() {
    return (
      <ShareGameDialog
        showDialog={this.props.showShareGameDialog}
        handleCloseDialog={this.props.handleCloseShareGameDialog}
        projectId={this.props.projectId}
        project={this.props.project}
        language={this.props.language}
      />
    );
  }

  render() {
    const IS_640_DESKTOP_PROJECT =
      sessionStorageService.getItem("640_desktop_project") === "true"
        ? true
        : false;
    const FLAVOR_TEXT_ARR = [
      localize("showcase_suggestion_1", this.props.language),
      localize("showcase_suggestion_2", this.props.language),
      localize("showcase_suggestion_3", this.props.language)
    ];

    return (
      <div className="project container nopadding">
        {this.props.showPopup && this.renderPopupAlertDialog()}
        {this.props.showShareGameDialog && this.renderShareGameDialog()}
        <div
          className={
            "desktop-project innerblock verticalpadding horizontalpadding pure-g bottompadding-floating" +
            (IS_640_DESKTOP_PROJECT ? " hide-below-md-640" : " hide-below-md")
          }
        >
          {/* Render desktop Project page here */}
          <DesktopProjectPage
            isLoadingMore={this.props.isLoadingMore}
            categoryId={this.props.id}
            categories={this.props.categories}
            topics={this.props.topics}
            more={this.props.more}
            randomNumber={this.state.randomNumber}
            flavorTextArr={FLAVOR_TEXT_ARR}
            showcaseProject={this.props.showcaseProject}
            addAnchors={this.addAnchors}
            handleMore={this.props.handleMore}
            renderTopics={this.renderTopics}
            language={this.props.language}
          />
        </div>
        <div
          className={
            "mobile-project" +
            (IS_640_DESKTOP_PROJECT ? " hide-from-md-640" : " hide-from-md")
          }
        >
          {/* Render mobile Project page here */}
          <MobileProjectPage
            project={this.props.project}
            projectId={this.props.projectId}
            projectImage={this.props.projectImage}
            buttons={this.props.buttons}
            topics={this.props.topics}
            user={this.props.user}
            team={this.props.team}
            categoryId={this.props.id}
            notifications={this.props.notifications}
            inboxUnread={this.props.inboxUnread}
            more={this.props.more}
            randomNumber={this.state.randomNumber}
            flavorTextArr={FLAVOR_TEXT_ARR}
            showcaseProject={this.props.showcaseProject}
            addAnchors={this.addAnchors}
            handleMore={this.props.handleMore}
            handleOpenShareGameDialog={this.props.handleOpenShareGameDialog}
            renderTopics={this.renderTopics}
            language={this.props.language}
            handleSubmit={this.props.handleSubmit}
            handleKeyPress={this.props.handleKeyPress}
            handleSearchChange={this.props.handleSearchChange}
          />
        </div>
      </div>
    );
  }
}

ProjectPage.propTypes = propTypes;
ProjectPage.defaultProps = defaultProps;

export default ProjectPage;
