import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import ActivityPage from "./ActivityPage";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import Loading from "components/shared/Loading";

import { LOAD_MORE_COUNT } from "config";
import {
  setProject,
  setButtons,
  setTopic,
  showAlertWithTimeout,
  addProfileDrawerPoints,
  showAwardableActionWithTimeout,
  showAchievements
} from "actions";
import {
  GET_PROJECT_ACTIVTY,
  GET_CHALLENGE_ACTIVTY,
  GET_CLAIM_ACTIVTY,
  GET_PROJECT_BUTTONS
} from "services/api";
import { likeClaim } from "services/challengeServices";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

const propTypes = {
  type: PropTypes.oneOf(["project", "challenge", "claim"]),
  id: PropTypes.string
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopic: topic => {
      dispatch(setTopic(topic));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    },
    addProfileDrawerPoints: points => {
      dispatch(addProfileDrawerPoints(points));
    },
    showAwardableActionWithTimeout: awardableAction => {
      dispatch(showAwardableActionWithTimeout(awardableAction));
    },
    showAchievements: achievements => {
      dispatch(showAchievements(achievements));
    }
  };
};

export class ActivityContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      claims: null,
      page: 1,
      more: false,
      isLoadingMore: false
    };

    this.removeClaim = this.removeClaim.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getActivity = this.getActivity.bind(this);
  }

  componentDidMount() {
    this.getActivity();
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  removeClaim(claimId) {
    this.setState({
      claims: this.state.claims.filter(element => element.id !== claimId)
    });
  }

  getURL(type, id, projectId) {
    if (type === "project") {
      return GET_PROJECT_ACTIVTY.format(projectId);
    } else if (type === "challenge") {
      return GET_CHALLENGE_ACTIVTY.format(id);
    } else if (type === "claim") {
      return GET_CLAIM_ACTIVTY.format(id);
    } else {
      return;
    }
  }

  getActivity(page) {
    const url = this.getURL(
      this.props.type,
      this.props.id,
      this.props.projectId
    );
    let query = { page: page, limit: LOAD_MORE_COUNT };
    getApiGenerator(url, query, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            claims: [],
            more: false,
            page: 1,
            isLoadingMore: false
          });
        }
      } else {
        if (this.props.type === "claim") {
          const claims = [res.body];
          this.setState({
            claims: claims,
            isLoadingMore: false
          });
        } else {
          this.setState({
            claims: this.state.claims
              ? this.state.claims.slice().concat(res.body.data)
              : res.body.data,
            page: this.state.page + 1,
            more: res.body.more,
            isLoadingMore: false
          });
        }
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        if (res.body.quest) {
          this.props.setTopic(res.body.quest);
        }
      }
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
      }
    });
  }

  handleMore = debounce(() => {
    const {
      getActivity,
      state: { more, isLoadingMore, page }
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.scrollingElement.scrollHeight * 0.9
      ) {
        this.setState(() => ({
          isLoadingMore: true
        }));
        getActivity(page);
      }
    }
  }, 100);

  handleLike = (event, id) => {
    likeClaim(event, id, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        if (res.body.actionPoints && res.body.actionPoints > 0) {
          this.props.addProfileDrawerPoints(res.body.actionPoints);
          this.props.showAwardableActionWithTimeout({
            numberStr: "" + res.body.actionPoints.abbreviateNumber(),
            unit: localize("points_just_text", this.props.language)
          });
        }
        if (res.body.items) {
          this.props.showAchievements(res.body.items);
        }
        const claims = this.state.claims.slice();
        const claim = claims.filter(claim => claim.id === id)[0];
        claim.ratedLike = res.body.rated;
        claim.likeNo = res.body.likeNo;
        this.setState({
          claims: claims
        });
      }
    });
  };

  handleComments = (id, commentNo) => {
    const claims = this.state.claims.slice();
    const claim = claims.filter(claim => claim.id === id)[0];
    claim.commentNo = commentNo;
    this.setState({
      claims: claims
    });
  };

  render() {
    if (this.state.claims && this.state.claims.length !== 0) {
      return (
        <ActivityPage
          isLoadingMore={this.state.isLoadingMore}
          type={this.props.type}
          projectId={this.props.projectId || 0}
          userId={this.props.userId}
          claims={this.state.claims}
          more={this.state.more}
          handleMore={this.handleMore}
          handleLike={this.handleLike}
          removeClaim={this.removeClaim}
          handleComments={this.handleComments}
          language={this.props.language}
        />
      );
    } else if (this.state.claims) {
      return (
        <GenericErrorPage
          message={localize("empty_claim_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

ActivityContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ActivityContainer);
