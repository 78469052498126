import getApiGenerator from "./getApiGenerator";
import { LIKE_ACTION, BOOKMARK_ACTION } from "./api";

/*
 * challenge icons and points
 */

function getChallengeType(
  challengeTypeId,
  photoOnly,
  multiSelect,
  challengeType
) {
  if (challengeTypeId === 1 && photoOnly) {
    return "photo";
  } else if (challengeTypeId === 11) {
    if (multiSelect === true) {
      return "survey-multi";
    } else if (multiSelect === false) {
      return "survey-single";
    } else {
      /* Current workaround, because certain APIs do not return "multiSelect" flag */
      return "survey";
    }
  } else if (challengeType !== undefined) {
    return challengeType;
  } else {
    return "normal";
  }
}

function getPoints(challengeTypeId: number, points: number) {
  if (challengeTypeId === 4) {
    return 0;
  } else if (points !== undefined) {
    // no points for flashcard
    return points;
  } else {
    return 10;
  } // default if points not specified
}

/*
 * GET actions (bookmark, like)
 */

function likeChallenge(event, id, sessionKey) {
  event.preventDefault();
  let query = { type: "challenge", type_id: id };
  return getApiGenerator(LIKE_ACTION, query, sessionKey);
}

function bookmarkChallenge(event, id, isBookmarked, sessionKey) {
  event.preventDefault();
  let query = { type_id: id, flag: !isBookmarked };
  return getApiGenerator(BOOKMARK_ACTION, query, sessionKey);
}

function likeClaim(event, id, sessionKey) {
  event.preventDefault();
  let query = { type: "claim", type_id: id };
  return getApiGenerator(LIKE_ACTION, query, sessionKey);
}

export {
  getChallengeType,
  getPoints,
  likeChallenge,
  bookmarkChallenge,
  likeClaim
};
