import React from "react";
import { connect } from "react-redux";

import {
  mapStateToProps,
  FloatingButtonsContainer
} from "components/FloatingButtons/FloatingButtonsContainer";
import FloatingButtons from "components/FloatingButtons/FloatingButtons";

export class CyberwellnessFloatingButtonsContainer extends FloatingButtonsContainer {
  /* Custom: Removed IS_MOBILE_PROJECT_PAGE check */
  render() {
    const HAS_BUTTONS = !!this.props.buttons;
    const HAS_VALID_STATE = !!this.props.floatingButtonsState;
    const HAS_PROJECT = !!(this.props.project && this.props.project.id);
    const PROJECT_ID = this.props.project
      ? this.props.project.id || null
      : null;

    if (!HAS_BUTTONS || !HAS_VALID_STATE || !HAS_PROJECT) {
      return null;
    } else {
      return (
        <FloatingButtons
          buttons={this.props.buttons}
          projectId={PROJECT_ID}
          language={this.props.language}
        />
      );
    }
  }
}

export default connect(mapStateToProps)(CyberwellnessFloatingButtonsContainer);
