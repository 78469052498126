import React from "react";
import { connect } from "react-redux";

import { HOME } from "App/Routes";
import {
  mapStateToProps,
  ProjectContainer
} from "components/Project/ProjectContainer";
import ProjectPage from "./ProjectPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { LOAD_MORE_COUNT, SINGLE_PROJECT_APP } from "config";
import {
  setProject,
  setButtons,
  setTopicCategory,
  showAlertWithTimeout,
  /* Custom: inclusion of setLanguage */
  setLanguage
} from "actions";
import getApiGenerator from "services/getApiGenerator";
/* Custom: inclusion of localStorageService for setting language */
import localStorageService from "services/localStorageService";
import { GET_TOPICS } from "services/api";

/* Custom: inclusion of reduceLanguages for setting language */
import reduceLanguages from "lang/reduceLanguages";
import localize from "lang/localize";

import sessionStorageService from "services/sessionStorageService";

/* Custom: inclusion of setLanguage */
export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopicCategory: topicCategory => {
      dispatch(setTopicCategory(topicCategory));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    },
    setLanguage: language => {
      dispatch(setLanguage(language));
    }
  };
};

/*
  In Project Container, this.props.id refers to category ID
  while this.props.projectId refers to projectId.
*/
export class CyberwellnessProjectContainer extends ProjectContainer {
  /* Custom: language selection based on userLanguage key */
  getTopics(page, category_id = this.props.id || "", limit = LOAD_MORE_COUNT) {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: page,
        category_id: category_id,
        limit: limit
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error
          });
        }
      } else {
        const categories = res.body.categories
          ? res.body.categories.map(category => ({
              id: category.id,
              title: category.name,
              selected: category.selected,
              description: category.description,
              image: category.image
            }))
          : null;

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        let compiledProject = categories
          ? Object.assign({}, res.body.game, { categories: categories })
          : res.body.game;

        this.setState({
          categories: categories,
          project: res.body.game,
          topics:
            this.state.topics && page !== 1
              ? this.state.topics.slice().concat(res.body.data)
              : res.body.data,
          more: res.body.more,
          page: page + 1,
          showcaseProject: !!res.body.showcaseProjects
            ? res.body.showcaseProjects[0]
            : null,
          code: res.body.code,
          error: ""
        });
        this.props.setProject(compiledProject);
        this.props.setButtons(BUTTONS);

        if (categories) {
          this.props.setTopicCategory(
            categories.filter(category => category.selected === true)[0]
          );
        }
        this.getProjectPopup();

        /* Custom: setting of languages */
        if (
          res.body.userLanguage &&
          typeof res.body.userLanguage === "string"
        ) {
          let reducedLanguage = reduceLanguages(
            res.body.userLanguage.toLowerCase()
          );

          localStorageService.setItem("language", reducedLanguage);
          this.props.setLanguage(reducedLanguage);
        }

        this.setState({
          data: res.body.data ? [res.body.data[0], res.body.data[1]] : []
        });
      }
    });
  }

  /* Custom: render custom ProjectPage */
  render() {
    const UNAVAILABLE_GAME_ERROR_LIST = [
      localize("unavailable_game_private", this.props.language),
      localize("unavailable_game_unpublished", this.props.language),
      localize("unavailable_game_deleted", this.props.language),
      localize("unavailable_game_no_topics", this.props.language),
      localize("unavailable_game_invalid", this.props.language)
    ];
    const UNAVAILABLE_GAME_ERROR_LIST_LOGGEDIN = [
      localize("unavailable_game_private_loggedin", this.props.language),
      localize("unavailable_game_unpublished", this.props.language),
      localize("unavailable_game_deleted", this.props.language),
      localize("unavailable_game_no_topics", this.props.language),
      localize("unavailable_game_invalid", this.props.language)
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    if (
      this.state.topics &&
      (this.state.topics.length !== 0 || this.state.categories)
    ) {
      /*
        Setting a key prop for ProjectPage will allow the interface to be reset.
        This is evident in mobile Project view, where you will be directed back
        to the first slide. States are reset in componentDidUpdate().
      */
      return (
        <ProjectPage
          isLoadingMore={this.state.isLoadingMore}
          sessionKey={this.props.sessionKey}
          ref={projectPage => (this.projectPage = projectPage)}
          key={this.props.projectId}
          projectId={this.props.projectId}
          id={this.props.id}
          project={this.state.project}
          projectImage={this.state.project.bannerImage}
          buttons={this.props.buttons}
          categories={this.state.categories}
          topics={this.state.topics}
          more={this.state.more}
          showcaseProject={this.state.showcaseProject}
          popup={this.state.popup}
          showPopup={this.state.showPopup}
          handleClosePopupAlertDialog={this.handleClosePopupAlertDialog}
          showShareGameDialog={this.state.showShareGameDialog}
          handleOpenShareGameDialog={this.handleOpenShareGameDialog}
          handleCloseShareGameDialog={this.handleCloseShareGameDialog}
          showTopicsListDialog={this.state.showTopicsListDialog}
          handleOpenTopicsListDialog={this.handleOpenTopicsListDialog}
          handleCloseTopicsListDialog={this.handleCloseTopicsListDialog}
          handleMore={() => this.handleMore()}
          user={this.props.user}
          team={this.props.team}
          notifications={this.props.notifications || 0}
          inboxUnread={this.props.inboxUnread || 0}
          language={this.props.language}
          handleKeyPress={this.handleKeyPress}
          handleSearchChange={this.handleSearchChange}
          handleSubmit={event => {
            this.handleSubmit(event);
          }}
        />
      );
    } else if (this.state.topics && !this.props.sessionKey) {
      /* No information is returned, but not logged in */
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_game_long", this.props.language)}
          messageList={UNAVAILABLE_GAME_ERROR_LIST}
          endMessage={localize(
            "unavailable_game_login_prompt",
            this.props.language
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.topics) {
      /* No information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={SINGLE_PROJECT_APP || IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_game_long", this.props.language)}
          messageList={UNAVAILABLE_GAME_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessProjectContainer);
