import React from "react";
import PropTypes from "prop-types";

import DesktopProjectPage from "components/Project/DesktopProjectPage";
import TopicRow from "./TopicRow";

import { SHOW_TOPIC_CATEGORIES } from "config";
import localize from "lang/localize";

const propTypes = {
  categoryId: PropTypes.string,
  categories: PropTypes.array,
  topics: PropTypes.array.isRequired,
  more: PropTypes.bool,
  randomNumber: PropTypes.number.isRequired,
  flavorTextArr: PropTypes.array.isRequired,
  showcaseProject: PropTypes.object,
  addAnchors: PropTypes.func,
  handleMore: PropTypes.func,
  renderTopics: PropTypes.func,
  language: PropTypes.string,
  /* Custom: topic lock check function to be passed down */
  getTopicLock: PropTypes.func
};

class CyberwellnessDesktopProjectPage extends DesktopProjectPage {
  /* Custom: custom function for rendering topic rows */
  renderCustomTopicsView(topics) {
    return topics.map((topic, index) => {
      return (
        <div className="pure-u-1 topic-row-container" key={topic.id}>
          <TopicRow
            sessionKey={this.props.sessionKey}
            title={topic.title}
            description={topic.description}
            img={topic.bannerImage}
            id={topic.id}
            locked={this.props.getTopicLock(topic.unlockable, topic.locked)}
            expired={typeof topic.expired === "boolean" ? topic.expired : false}
            started={topic.joined || false}
            completed={
              topic.challengeNo === topic.userChallengeCompletedNo || false
            }
            totalChallenges={topic.challengeNo}
            language={this.props.language}
          />
        </div>
      );
    });
  }

  /* Custom: Only a single column */
  render() {
    return (
      <div className="pure-u-1-1">
        {SHOW_TOPIC_CATEGORIES &&
          this.props.categories &&
          this.renderCategory()}
        {/* Custom: use this.renderCustomTopicsView(this.props.topics) instead of this.props.renderTopics(this.props.topics, false) */}
        {this.props.topics.length !== 0 ? (
          /* isMobile is false */
          this.renderCustomTopicsView(this.props.topics)
        ) : (
          <p className="textcenter verticalpadding">
            {localize("empty_game_table_text", this.props.language)}
          </p>
        )}
        {this.props.topics.length !== 0 &&
          !this.props.more &&
          !!this.props.showcaseProject &&
          this.renderProjectShowcaseCard()}
        {this.props.more && (
          <button
            className="button topmargin automargin"
            onClick={this.props.handleMore}
          >
            {localize("more_text", this.props.language)}
          </button>
        )}
      </div>
    );
  }
}

CyberwellnessDesktopProjectPage.propTypes = propTypes;

export default CyberwellnessDesktopProjectPage;
