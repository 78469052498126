import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import Loading from "components/shared/Loading";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import ProjectsListPage from "./ProjectsListPage";

import getApiGenerator from "services/getApiGenerator";
import { LOAD_MORE_COUNT } from "config";
import { GET_PROJECTS_LIST } from "services/api";
import { MASTERS_ID } from "config";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    language: state.language
  };
};

export class ProjectsListContainer extends Component {
  constructor() {
    super();
    this.state = {
      projects: null,
      more: false,
      page: 1,
      user: null,
      isLoadingMore: false
    };
    this.handleMore = this.handleMore.bind(this);
    this.getProjects = this.getProjects.bind(this);
  }

  componentDidMount() {
    if (this.props.sessionKey) {
      this.getProjects(this.state.page);
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.getProjects(this.state.page);
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
    window.removeEventListener("scroll", this.handleMore);
  }

  getProjects(page) {
    getApiGenerator(
      GET_PROJECTS_LIST.format(MASTERS_ID),
      {
        page: page,
        limit: LOAD_MORE_COUNT
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ projects: [], isLoadingMore: false });
        }
      } else {
        this.setState({
          projects: this.state.projects
            ? this.state.projects.slice().concat(res.body.data)
            : res.body.data,
          more: res.body.more,
          page: this.state.page + 1,
          user: res.body.user,
          isLoadingMore: false
        });
      }
    });
  }

  handleMore = debounce(() => {
    const {
      getProjects,
      state: { more, isLoadingMore, page }
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.scrollingElement.scrollHeight * 0.9
      ) {
        this.setState(() => ({
          isLoadingMore: true
        }));
        getProjects(page);
      }
    }
  }, 100);

  render() {
    if (!this.props.user.id) {
      return (
        <NotLoggedInContainer
          language={this.props.language}
          showLogin={false}
        />
      );
    }

    if (this.state.projects && this.state.projects.length !== 0) {
      return (
        <ProjectsListPage
          projects={this.state.projects}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          language={this.props.language}
          user={this.state.user}
          isLoadingMore={this.state.isLoadingMore}
        />
      );
    } else if (this.state.projects) {
      return (
        <GenericErrorPage
          message={localize("empty_bundles_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps)(ProjectsListContainer);
