import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./TopicPage.css";
import { TOPIC } from "App/Routes";
import BreadcrumbsContainer from "custom-components/shared/Breadcrumbs/BreadcrumbsContainer";
import TopicPage from "components/Topic/TopicPage";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";

const propTypes = {
  sessionKey: PropTypes.string,
  project: PropTypes.object,
  challenges: PropTypes.array.isRequired,
  categoryId: PropTypes.string,
  achievements: PropTypes.array,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  totalChallenges: PropTypes.number,
  completedChallenges: PropTypes.number,
  personality: PropTypes.object,
  allowReset: PropTypes.bool.isRequired,
  isSearch: PropTypes.bool,
  showTopicChallengesCompleted: PropTypes.bool,
  showTopicAchievements: PropTypes.bool,
  topicTitle: PropTypes.string,
  topicId: PropTypes.number,
  showBookmarkLoginDialog: PropTypes.bool,
  handleOpenBookmarkLoginDialog: PropTypes.func,
  handleCloseBookmarkLoginDialog: PropTypes.func,
  showLikeLoginDialog: PropTypes.bool,
  handleOpenLikeLoginDialog: PropTypes.func,
  handleCloseLikeLoginDialog: PropTypes.func,
  showResetActivityDialog: PropTypes.bool,
  handleOpenResetActivityDialog: PropTypes.func,
  handleCloseResetActivityDialog: PropTypes.func,
  isLoggedIn: PropTypes.bool.isRequired,
  language: PropTypes.string,
  contactEmail: PropTypes.string,
  /* Custom: topic description */
  topicDescription: PropTypes.string,
  /* Custom: next topic id */
  topicIdNext: PropTypes.number
};

const defaultProps = {
  more: false,
  completedChallenges: 0,
  totalChallenges: 0,
  personality: undefined,
  isSearch: false
};

class CyberwellnessTopicPage extends TopicPage {
  /* Custom: new custom function */
  renderTopicInfo() {
    return (
      <div className="topic-info-container padding bottommargin-20">
        <h1 className="bottommargin-10">{this.props.topicTitle}</h1>
        <div
          className="topic-info-description"
          dangerouslySetInnerHTML={{ __html: this.props.topicDescription }}
        />
      </div>
    );
  }

  /* Custom: new custom function */
  renderNextTopicPanel() {
    if (
      this.props.totalChallenges < 0 ||
      this.props.completedChallenges < 0 ||
      this.props.totalChallenges > this.props.completedChallenges
    ) {
      return null;
    }
    if (this.props.topicIdNext) {
      return (
        <div className="next-topic-panel text-align-center horizontalpadding-10 verticalpadding-10 bottommargin-20">
          <p className="bottommargin-20">
            <strong>
              {localize("text_next_topic_topicview", this.props.language)}
            </strong>
          </p>
          <p className="nomargin">
            <Link
              to={TOPIC.format(this.props.topicIdNext)}
              id="cta"
              className="button display-inline-block cta"
            >
              {localize("button_next_topic", this.props.language)}
            </Link>
          </p>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render top section of page - doesn't appear for challenge category view
   *
   * Comprises:
   * - Activity reset buttons for other topic types
   * - Progress bar
   */
  renderTopSection() {
    if (
      !this.props.categoryId &&
      this.props.allowReset &&
      this.props.sessionKey
    ) {
      /* with dropdown button for reset activity, and sticky progress bar */
      return (
        <div className="pure-g position-sticky-offset-topbar bottommargin">
          <div className="pure-u-19-24 pure-u-sm-21-24 pure-u-xl-22-24 box-sizing-border-box-all horizontalpadding-5 flex align-items-center">
            {this.props.showTopicChallengesCompleted &&
              this.renderProgressBar()}
          </div>
          <div className="pure-u-5-24 pure-u-sm-3-24 pure-u-xl-2-24 horizontalpadding-5 box-sizing-border-box-all flex justify-content-flexend align-items-center">
            <div className="dropdown-button-group main-topic-button-dropdown">
              <Dropdown
                dropup={false}
                onSelect={(eventKey, event) => {
                  event.preventDefault();
                  this.props.handleOpenResetActivityDialog();
                }}
                pullRight={true}
              >
                <Dropdown.Toggle
                  btnStyle="flat"
                  noCaret={true}
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  <span className="more-icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <MenuItem eventKey={1} onSelect={(eventKey, event) => {}}>
                    {localize("icon_reset_topic", this.props.language)}
                  </MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {this.renderResetActivityDialog()}
        </div>
      );
    } else if (!this.props.categoryId) {
      /* without dropdown button for reset activity, and progress bar */
      return (
        <div className="pure-g position-sticky-offset-topbar bottommargin">
          <div className="pure-u-1-1 box-sizing-border-box-all horizontalpadding-5 flex align-items-center">
            {this.props.showTopicChallengesCompleted &&
              this.renderProgressBar()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  /* Custom: inclusion of renderTopicInfo() */
  render() {
    return (
      <Fragment>
        <BreadcrumbsContainer />
        {this.renderTopicCommentsNav()}
        <div className="topic-container container verticalpadding bottompadding-floating">
          {/* Fixed-positioned background for Screen */}
          <div className="background-cyberwellness"></div>

          <div className="innerblock pure-g">
            <div className="pure-u-1 pure-u-sm-2-24" />
            <div className="pure-u-1 pure-u-sm-20-24">
              {this.renderTopicInfo()}
              {this.renderNextTopicPanel()}
              {this.renderTopSection()}
              {this.props.showTopicAchievements && this.renderAchievements()}
              {this.renderPersonality()}
              {this.props.showBookmarkLoginDialog &&
                this.renderBookmarkLoginDialog()}
              {this.props.showLikeLoginDialog && this.renderLikeLoginDialog()}
              {this.renderChallenges(this.props.challenges)}
              {this.props.more ? (
                <button
                  id="moreButton"
                  className="button automargin"
                  onClick={this.props.handleMore}
                >
                  {localize("more_text", this.props.language)}
                </button>
              ) : null}
            </div>
            <div className="pure-u-1 pure-u-sm-2-24" />
          </div>
        </div>
      </Fragment>
    );
  }
}

CyberwellnessTopicPage.propTypes = propTypes;
CyberwellnessTopicPage.defaultProps = defaultProps;

export default CyberwellnessTopicPage;
