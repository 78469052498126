import React, { Component } from "react";
import PropTypes from "prop-types";

import "./FeaturedProjectCard.css";
import { PROJECT_HOME } from "App/Routes";
import placeholder_project from "images/core/placeholders/placeholder_project.png";
import Link from "components/shared/Link/Link";
import ShareGameDialog from "components/shared/Dialogs/ShareGameDialog/ShareGameDialog";

import anchorme from "anchorme";
import Dotdotdot from "react-dotdotdot";

const propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  description: PropTypes.string,
  project: PropTypes.object.isRequired,
  language: PropTypes.string
};

const defaultProps = {
  img: placeholder_project
};

class FeaturedProjectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareGameDialog: false
    };

    this.handleOpenShareGameDialog = this.handleOpenShareGameDialog.bind(this);
    this.handleCloseShareGameDialog = this.handleCloseShareGameDialog.bind(
      this
    );
  }

  addAnchors(text) {
    // add anchor tags to links
    if (text) {
      text = anchorme(text, {
        exclude: urlObj => {
          urlObj.encoded = urlObj.encoded.replace(/%25/g, "%");
          return false;
        },
        attributes: [
          { name: "target", value: "blank" },
          { name: "class", value: "link default break-url" }
        ]
      });
    }
    return text;
  }

  handleOpenShareGameDialog(event) {
    event.preventDefault();

    this.setState({
      showShareGameDialog: true
    });
  }

  handleCloseShareGameDialog() {
    this.setState({
      showShareGameDialog: false
    });
  }

  renderShareGameDialog() {
    return (
      <ShareGameDialog
        showDialog={this.state.showShareGameDialog}
        handleCloseDialog={this.handleCloseShareGameDialog}
        projectId={this.props.id}
        project={this.props.project}
        language={this.props.language}
      />
    );
  }

  renderProjectShareButton() {
    if (this.props.project && this.props.project.projectShareLink) {
      return (
        <button
          className="button icon info inline boxshadow"
          onClick={e => {
            e.preventDefault();
            this.handleOpenShareGameDialog(e);
          }}
        >
          <i className="fas fa-share-alt"></i>
        </button>
      );
    } else {
      return null;
    }
  }

  render() {
    let description = this.props.description
      ? this.addAnchors(this.props.description)
      : "";
    let img = this.props.img ? this.props.img : placeholder_project;
    let text = this.props.text;

    return (
      <div className="project-cards-wrapper">
        <div className="project-card-container">
          <Link
            to={PROJECT_HOME.format(this.props.id)}
            className="project-card featured-project-card project-card-bg featured-project-card-bg pure-g"
            style={{
              backgroundImage: "url(" + img + ")",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          >
            <div className="title-wrapper title text-shadow">
              <Dotdotdot clamp={1}>
                <p>{text}</p>
              </Dotdotdot>
            </div>
            <div className="relative">
              {this.renderProjectShareButton()}
              {this.props.description && description.trim() !== "" && (
                <div className="description-wrapper">
                  <Dotdotdot clamp={3}>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </Dotdotdot>
                </div>
              )}
            </div>
          </Link>
          {this.state.showShareGameDialog && this.renderShareGameDialog()}
        </div>
      </div>
    );
  }
}

FeaturedProjectCard.propTypes = propTypes;
FeaturedProjectCard.defaultProps = defaultProps;

export default FeaturedProjectCard;
