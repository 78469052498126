import { connect } from "react-redux";

import {
  mapStateToProps,
  FrameLoginContainer
} from "components/Login/FrameLoginSuccessContainer";

/* Custom: Inclusion of setLanguage */
import { setSessionKey, setUserId, setLanguage } from "actions";
import localStorageService from "services/localStorageService";
import reduceLanguages from "lang/reduceLanguages";

/* Custom: inclusion of setLanguage */
export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: sessionKey => {
      dispatch(setSessionKey(sessionKey));
    },
    setUserId: user => {
      dispatch(setUserId(user));
    },
    setLanguage: language => {
      dispatch(setLanguage(language));
    }
  };
};

export class CyberwellnessFrameLoginContainer extends FrameLoginContainer {
  constructor() {
    super();
    this.state = {
      sessionKey: null,
      userId: null,
      lang: null
    };
  }

  /*
    No need for redirects here, because it's handled by
    the parent LoginContainer's componentDidUpdate
  */
  /* Custom: Set language to localStorage and redux store after setting userId and sessionKey */
  handleLogin() {
    localStorageService.saveState({
      user_id: this.state.userId,
      sessionKey: this.state.sessionKey
    });
    this.props.setUserId(this.state.userId);
    this.props.setSessionKey(this.state.sessionKey);

    /* If there is a valid lang string in the component state, set it */
    if (typeof this.state.lang === "string") {
      let reducedLanguage = reduceLanguages(this.state.lang.toLowerCase());

      localStorageService.setItem("language", reducedLanguage);
      this.props.setLanguage(reducedLanguage);
    }
  }

  /* Custom: call this.retrieveLanguage after setting userId to component state */
  retrieveSessionKeyAndUserId(item) {
    const PARAM_STRING = "sessionKey=";

    if (item.indexOf(PARAM_STRING) !== -1) {
      this.setState(
        {
          sessionKey: item
            .slice(item.indexOf(PARAM_STRING))
            .slice(PARAM_STRING.length)
            .split("?")[0]
            .split("&")[0]
            .split("/")[0]
        },
        () => {
          let userIdString = this.state.sessionKey.split("-")[0];
          this.setState(
            {
              userId: isNaN(userIdString) ? false : parseInt(userIdString, 10)
            },
            () => {
              /*
              Custom: Attempt to set language after
              userId and sessionKey are valid
            */
              this.retrieveLanguage(item);
            }
          );
        }
      );
    } else {
      this.setState({
        sessionKey: false,
        userId: false
      });
    }
  }

  /* Custom: New custom retrieveLanguage() function */
  retrieveLanguage(item) {
    const PARAM_STRING = "userLanguage=";

    if (item.indexOf(PARAM_STRING) !== -1) {
      this.setState({
        lang: item
          .slice(item.indexOf(PARAM_STRING))
          .slice(PARAM_STRING.length)
          .split("?")[0]
          .split("&")[0]
          .split("/")[0]
      });
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessFrameLoginContainer);
