import {
  DEFAULT_LANGUAGE,
  VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS,
  AVAILABLE_LANGUAGES_VARIATIONS
} from "config";

function reduceLanguages(language) {
  let lang;

  /*
    STEP ONE: Check if language string is available within Gametize platforms.
    At this stage, we do not yet care if the language variable offered is
    available on our Platform or Project.
  */
  if (
    typeof language === "string" &&
    AVAILABLE_LANGUAGES_VARIATIONS.indexOf(language.toLowerCase()) >= 0
  ) {
    switch (language.toLowerCase()) {
      case "en":
      case "en-ar":
      case "en-au":
      case "en-bz":
      case "en-ca":
      case "en-cb":
      case "en-cn":
      case "en-dk":
      case "en-hk":
      case "en-in":
      case "en-id":
      case "en-ie":
      case "en-jm":
      case "en-ja":
      case "en-my":
      case "en-nz":
      case "en-no":
      case "en-ph":
      case "en-pr":
      case "en-sg":
      case "en-za":
      case "en-se":
      case "en-gb":
      case "en-us":
      case "en-zw":
      case "eng":
        lang = "en";
        break;
      case "zh-hans":
      case "zh-sg":
      case "zh-cn":
      case "zh-hans-sg": // CUSTOM FOR CYBERWELLNESS
      case "zh-hans-hk": // CUSTOM FOR CYBERWELLNESS
        lang = "zh-hans";
        break;
      case "zh-hant":
      case "zh-tw":
      case "zh-hk":
      case "zh-mo":
      case "zh-hant-tw": // CUSTOM FOR CYBERWELLNESS
      case "zh-hant-hk": // CUSTOM FOR CYBERWELLNESS
        lang = "zh-hant";
        break;
      case "ms":
      case "ms-my":
        lang = "ms";
        break;
      case "pt":
      case "pt-br":
        lang = "pt";
        break;
      case "fr":
        lang = "fr";
        break;
      case "es":
      case "es-es":
      case "es-es_tradnl":
      case "es-mx":
      case "es-gt":
      case "es-cr":
      case "es-pa":
      case "es-do":
      case "es-ve":
      case "es-co":
      case "es-pe":
      case "es-ar":
      case "es-ec":
      case "es-cl":
      case "es-uy":
      case "es-py":
      case "es-bo":
      case "es-sv":
      case "es-hn":
      case "es-ni":
      case "es-pr":
      case "es-us":
      case "spa":
        lang = "es";
        break;
      case "id":
      case "id-id":
      case "ind":
        lang = "id";
        break;
      case "sv":
      case "swe":
        lang = "sv";
        break;
      case "th":
      case "tha":
        lang = "th";
        break;
      case "tr":
      case "tur":
        lang = "tr";
        break;
      case "vi":
      case "vie":
        lang = "vi";
        break;
      case "ko":
        lang = "ko";
        break;
      default:
        lang = DEFAULT_LANGUAGE;
    }
  } else {
    /* If language string is not available within Gametize platforms */
    lang = DEFAULT_LANGUAGE;
  }

  /*
    STEP TWO: Second check if lang variable matches VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS.

    Now, we care if the language/lang supplied is valid/available. If the chosen language
    or DEFAULT_LANGUAGE is not available for this project nor platform, we force a default:
    the first language included in both PLATFORM_AVAILABLE_LANGUAGES_STRINGS and
    AVAILABLE_LANGUAGES_STRINGS, or "en".

    If it doesn't reach the parent if-block here, just return lang.
  */
  if (VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS.indexOf(lang) < 0) {
    if (VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS.length > 0) {
      lang = VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS[0];
    } else {
      lang = "en";
    }
  }

  return lang;
}

export default reduceLanguages;
