import React, { Component } from "react";
import PropTypes from "prop-types";

import Claim from "./Claim";
import ClaimResultContainer from "./ClaimResultContainer";
import "./Activity.css";

import { getChallengeType } from "services/challengeServices";
import Loading from "components/shared/Loading";

// import localize from "lang/localize";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  type: PropTypes.oneOf(["project", "challenge", "claim"]),
  claims: PropTypes.array,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  removeClaim: PropTypes.func,
  handleComments: PropTypes.func.isRequired,
  language: PropTypes.string
};

class ActivityPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("activityPage")) {
      bodyDOM.className += " activityPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("activityPage")) {
      bodyDOM.classList.remove("activityPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderActivity() {
    return (
      <div className="verticalpadding">
        {this.props.claims.map((claim, index) => (
          <div className="claimcard-line bottompadding" key={claim.id}>
            <Claim
              key={claim.id}
              projectId={this.props.projectId}
              claimUserId={claim.userId}
              userId={this.props.userId}
              userName={claim.userName}
              userImage={claim.userPhotoSmall}
              userRankStatus={claim.userRankStatus}
              userRankStatusImage={claim.userRankStatusImage}
              createdAtFormatted={claim.createdAtFormatted}
              points={claim.points}
              challengeId={claim.challenge.id}
              challengeTitle={claim.challenge.title}
              challengeType={getChallengeType(
                claim.challenge.challengeTypeId,
                claim.challenge.photoOnly,
                claim.challenge.multiSelect,
                claim.challenge.challengeType
              )}
              challengeTypeId={claim.challenge.challengeTypeId}
              isConfirmationChallenge={
                claim.challenge.challengeTypeId === 11 &&
                claim.challenge.confirmation === true
              }
              challengePhotoOnly={claim.challenge.photoOnly}
              correctAnswer={claim.correctAnswer}
              privateClaim={claim.challenge.privateClaim}
              language={this.props.language}
            />
            <ClaimResultContainer
              id={claim.id}
              claimUserId={claim.userId}
              userId={this.props.userId}
              type={this.props.type}
              challengeTypeId={claim.challenge.challengeTypeId}
              claimImage={claim.claimPhotoSmall}
              claimVideo={claim.claimVideo}
              claimVideoContainer={claim.claimVideoContainer}
              claimVideoThumbnail={
                claim.claimVideoThumbnail ? claim.claimVideoThumbnail : null
              }
              entries={claim.entries}
              claimMessage={claim.message}
              likeNo={claim.likeNo}
              commentNo={claim.commentNo}
              ratedLike={claim.ratedLike}
              handleLike={event => this.props.handleLike(event, claim.id)}
              removeClaim={this.props.removeClaim}
              privateClaim={claim.challenge.privateClaim}
              userName={claim.userName}
              handleComments={this.props.handleComments}
            />
          </div>
        ))}
        {this.props.isLoadingMore ? <Loading /> : null}
        {/* {this.props.more && (
          <button className="button automargin" onClick={this.props.handleMore}>
            {localize("more_text", this.props.language)}
          </button>
        )} */}
      </div>
    );
  }

  render() {
    return (
      <div className="activity">
        <div className="container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1 pure-u-md-4-24" />
            <div className="pure-u-1 pure-u-md-16-24">
              {this.renderActivity()}
            </div>
            <div className="pure-u-1 pure-u-md-4-24" />
          </div>
        </div>
      </div>
    );
  }
}

ActivityPage.propTypes = propTypes;

export default ActivityPage;
