import React from "react";

import QuizChallenge from "components/Challenge/ClaimForm/QuizChallenge";
import QuizInput from "components/shared/Inputs/QuizInput";

import localize from "lang/localize";

class CyberwellnessQuizChallenge extends QuizChallenge {
  render() {
    /* Confirmation Challenge */
    if (this.props.isConfirmationChallenge) {
      return (
        <div className="pure-g">
          <div className="pure-u-1-1 textcenter">
            {this.props.isLoggedIn ? (
              this.props.options.map(option => (
                <QuizInput
                  key={option.id}
                  id={option.id}
                  selected={this.state.selected.indexOf(option.id) !== -1}
                  image={option.image}
                  title={option.title}
                  onClick={id => {
                    this.handleConfirmationClick(id);
                  }}
                  isConfirmationChallenge={this.props.isConfirmationChallenge}
                />
              ))
            ) : (
              <div>
                {this.props.options.map(option => (
                  <QuizInput
                    key={option.id}
                    id={option.id}
                    selected={this.state.selected.indexOf(option.id) !== -1}
                    image={option.image}
                    title={option.title}
                    onClick={this.props.handleOpenClaimLoginDialog}
                    isConfirmationChallenge={this.props.isConfirmationChallenge}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      );
      /* Not Confirmation Challenge */
    } else {
      return (
        <div className="pure-g">
          <div className="pure-u-1-1">
            {this.renderOptions()}
            {this.props.isLoggedIn ? (
              <button
                className={
                  "button automargin " +
                  (this.props.submitted ? "inactive" : "cta")
                }
                onClick={this.handleSubmit.bind(this)}
                disabled={this.props.submitted}
                id="submitCompletionButton"
              >
                {this.props.submitted
                  ? localize("button_claim_submitting", this.props.language)
                  : localize("button_claim_get_points", this.props.language)}
              </button>
            ) : (
              <button
                className={
                  "button automargin " +
                  (this.props.submitted ? "inactive" : "cta")
                }
                onClick={this.props.handleOpenClaimLoginDialog}
                disabled={this.props.submitted}
                id="loginBeforeSubmitCompletionButton"
              >
                {this.props.submitted
                  ? localize("button_claim_submitting", this.props.language)
                  : localize("button_claim_get_points", this.props.language)}
              </button>
            )}
          </div>
        </div>
      );
    }
  }
}

export default CyberwellnessQuizChallenge;
