const listenerServices = {
  addListener: function(event, handler) {
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let listenerEvent = eventMethod === "attachEvent" ? "on" + event : event;

    eventer(listenerEvent, handler, false);
  },

  removeListener: function(event, handler) {
    let eventMethod = window.removeEventListener
      ? "removeEventListener"
      : "detachEvent";
    let eventer = window[eventMethod];
    let listenerEvent = eventMethod === "detachEvent" ? "on" + event : event;

    eventer(listenerEvent, handler, false);
  }
};

export default listenerServices;
