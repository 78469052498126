import React, { Component } from "react";
import CacheBuster from "./CacheBuster";

import Router from "router";
import { connect } from "react-redux";

import ReactGA from "react-ga4";

/* Custom: import from custom-components */
import TopbarContainer from "custom-components/Topbar/TopbarContainer";
// import TopbarContainer from "custom-components/Topbar/TopbarContainer";
/* Custom: remove sidebar */
// import SidebarContainer from "components/Sidebar/SidebarContainer.js";
import AlertContainer from "components/shared/Alert/AlertContainer";
import DownloadAppAlert from "../components/shared/Alert/DownloadAppAlert";
import AwardableActionsContainer from "components/shared/AwardableActions/AwardableActionsContainer";
/* Custom: import from custom-components */
// import ChallengeCompletedModalContainer from "components/Challenge/ChallengeCompletedModal/ChallengeCompletedModalContainer.js";
import ChallengeCompletedModalContainer from "custom-components/Challenge/ChallengeCompletedModal/ChallengeCompletedModalContainer.js";
/* Custom: import from custom-components */
// import ProfileDrawerContainer from "components/ProfileDrawer/ProfileDrawerContainer.js";
import ProfileDrawerContainer from "custom-components/ProfileDrawer/ProfileDrawerContainer.js";
/* Custom: import from custom-components */
// import FloatingButtonsContainer from "components/FloatingButtons/FloatingButtonsContainer.js";
import FloatingButtonsContainer from "custom-components/FloatingButtons/FloatingButtonsContainer.js";
import AchievementsModalContainer from "components/shared/Achievements/AchievementsModal/AchievementsModalContainer";

import Loading from "components/shared/Loading";
import * as Pages from "./Pages";
import * as Urls from "./UrlVariables";

import localStorageService from "services/localStorageService";
import sessionStorageService from "services/sessionStorageService";
import urlServices from "services/urlServices";
import logoutServices from "services/logoutServices";

import {
  PROJECT_ID,
  REQUIRE_LOGIN,
  ENABLE_TOUR,
  ENABLE_TOUR_PUBLIC,
  ENABLE_INBOX,
  ENABLE_TOPIC_COMMENTS,
  ENABLE_CHALLENGE_COMMENTS,
  ENABLE_CLAIM_COMMENTS,
  ENABLE_REGISTER_PAGES,
  ENABLE_SETTINGS_PAGES,
  ENABLE_NOTIFICATIONS_PAGES,
  ENABLE_OAUTH_LOGIN,
  ENABLE_LOGIN_HOME,
  USE_LOGIN_HOME_TEMPLATE_1,
  ENABLE_SSO_LOGIN,
  SHOW_TOPIC_CATEGORIES,
  SHOW_CHALLENGE_CATEGORIES,
  ENABLE_LANG_URL_PARAM,
  DEFAULT_LANGUAGE,
  VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS,
  VALID_PLATFORM_AVAILABLE_LANGUAGES_VARIATIONS,
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
  ENABLE_GOOGLE_ANALYTICS
} from "config";
import {
  setSessionKey,
  setUser,
  setUserId,
  setProjectId,
  setProject,
  setTopic,
  setTopicCategory,
  setChallengeCategory,
  setTopbar,
  setSidebarState,
  setProfileDrawer,
  setFloatingButtonsState,
  setLanguage,
  showAlertWithTimeout
} from "actions";
import {
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
  LOGIN,
  PROJECT_LOGIN
} from "App/Routes";

import reduceLanguages from "lang/reduceLanguages";

/* validate sessions */
import pushApiGenerator from "services/pushApiGenerator";
import { VALIDATE_SESSION } from "services/api";
import QRChallengeModalProjectHome from "../components/Challenge/ClaimForm/QRModalProjectHome";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    projectId: state.projectId,
    newPage: state.profileDrawer.newPage,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: sessionKey => {
      dispatch(setSessionKey(sessionKey));
    },
    setUser: user => {
      dispatch(setUser(user));
    },
    setUserId: userId => {
      dispatch(setUserId(userId));
    },
    setProjectId: projectId => {
      dispatch(setProjectId(projectId));
    },
    setProject: project => {
      dispatch(setProject(project));
    },
    setTopic: topic => {
      dispatch(setTopic(topic));
    },
    setTopicCategory: topicCategory => {
      dispatch(setTopicCategory(topicCategory));
    },
    setChallengeCategory: challengeCategory => {
      dispatch(setChallengeCategory(challengeCategory));
    },
    setTopbar: info => {
      dispatch(setTopbar(info));
    },
    setSidebarState: state => {
      dispatch(setSidebarState(state));
    },
    setProfileDrawer: info => {
      dispatch(setProfileDrawer(info));
    },
    setFloatingButtonsState: state => {
      dispatch(setFloatingButtonsState(state));
    },
    setLanguage: language => {
      dispatch(setLanguage(language));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

export class SingleProjectApp extends Component {
  constructor() {
    super();
    this.state = {
      component: <Loading />
    };
  }

  componentDidMount() {
    /* Initialising Google Analytics */
    if (ENABLE_GOOGLE_ANALYTICS) {
      ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);
      ReactGA.send("pageview");
    }

    this.initializeEmbeddedProject();
    this.initialize640DesktopProject();
    this.setIOSBodyStyles(true);
    this.initializeLanguage();
    this.props.setProjectId(PROJECT_ID);
    this.restoreSession();
    this.addRoutes();
    this.reloadOnLogin();
  }

  componentWillUnmount() {
    this.setIOSBodyStyles(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.newPage === true &&
      prevProps.newPage !== this.props.newPage
    ) {
      /* Validate sessionKey */
      if (this.props.sessionKey && this.props.sessionKey !== "") {
        this.validateSession();
      }

      /* Remove loginRedirectUrl from sessionStorage if the new page isn't a
         login, register, reset password page, and login tour */
      if (!urlServices.isLoginRedirectionPage()) {
        sessionStorageService.removeItem("loginRedirectUrl");
      }
    }

    if (this.props.sessionKey !== prevProps.sessionKey) {
      if (this.props.sessionKey === null || this.props.sessionKey === "") {
        this.props.setUser({ id: null });
      }
    }

    // if no project, reset project
    if (
      this.props.projectId !== prevProps.projectId &&
      typeof this.props.projectId !== "number"
    ) {
      this.props.setProject({});
    }

    /* Reset topic and challenge categories */
    this.props.setTopicCategory(null);
    this.props.setChallengeCategory(null);

    // Cases requiring login:
    // If current page is not login/register/recover-password page.
    // Tour always requires login unless ENABLE_TOUR_PUBLIC is true.
    if (
      REQUIRE_LOGIN &&
      !this.props.userId &&
      !this.props.sessionKey &&
      !urlServices.isAccountAccessPage()
    ) {
      // Do not redirect if tour can be viewed by public
      if (urlServices.isTour() && ENABLE_TOUR_PUBLIC) {
        return;
      }

      // Save current page url as loginRedirectUrl
      // (For all pages except tour page)
      if (!urlServices.isLoginRedirectionPage()) {
        sessionStorageService.setItem(
          "loginRedirectUrl",
          urlServices.defaultPath()
        );
      }

      /* use Router.replaceAndNavigate(*_LOGIN[_HOME]) instead of Router.navigate(*_LOGIN[_HOME]) */
      /* to prevent people from being able to click back to the original non-login page */
      if (this.props.projectId && ENABLE_LOGIN_HOME) {
        Router.replaceAndNavigate(
          PROJECT_LOGIN_HOME.format(this.props.projectId)
        );
      } else if (this.props.projectId) {
        Router.replaceAndNavigate(PROJECT_LOGIN.format(this.props.projectId));
      } else if (ENABLE_LOGIN_HOME) {
        Router.replaceAndNavigate(LOGIN_HOME);
      } else {
        Router.replaceAndNavigate(LOGIN);
      }
    }
  }

  restoreSession() {
    let loadedState = localStorageService.loadState();
    if (
      loadedState !== undefined &&
      loadedState.sessionKey !== undefined &&
      loadedState.user_id !== undefined
    ) {
      this.props.setUserId(loadedState.user_id);
      this.props.setSessionKey(loadedState.sessionKey);
      return true;
    } else {
      return false;
    }
  }

  addRoutes() {
    const setPage = (
      page,
      { projectId = null, id = null, id2 = null } = {}
    ) => {
      /*
        By default: set topic to null upon loading every page,
        and let API call regenerate Topic
      */
      this.props.setTopic({});
      this.setState({ component: page.component(id, id2) });
      this.props.setTopbar({
        state: page.topbarState,
        title: page.topbarTitle
      });
      this.props.setProfileDrawer({
        newPage: true,
        state: page.profileDrawerState
      });
      this.props.setSidebarState(page.sidebarState);

      /* Floating buttons */
      this.props.setFloatingButtonsState(page.floatingButtonsState);
    };

    /* REGEX TO TAKE NOTE OF */
    /*
       ((\/)?\?.*)?
       This regex is for matching parameterised URLs with '?'s annd '='s and '&'s. We
       use this for handling appended parameters to existing URLs, that the marketing
       team needs for tracking their URLs. Our app does not need or use the parameters
       for now, but this ensures that users can still access our URLs through the
       parameters, without error 404s due to our strict regex-URL matching.
       - '(\/)?\?' matches 1 or 0 forward slashes, then a question mark
       - '.*' matches 0 or more of any character (except line breaks) after the question mark
       - '((\/)?\?.*)?' putting them together, '/whatever', '/whatever/?',
         '/whatever?stuff1=here&stuff2=there', and '/whatever/?stuff1=here&stuff2=there'
         all work
    */
    Router.add(Urls.LOGIN, () => setPage(Pages.LOGIN))
      .add(Urls.POST_LOGIN_INTERCEPT, () => setPage(Pages.POST_LOGIN_INTERCEPT))
      .add(Urls.FRAME_LOGIN_SUCCESS, id =>
        setPage(Pages.FRAME_LOGIN_SUCCESS, { id })
      )
      .add(Urls.RESET_PASSWORD, () => setPage(Pages.RESET_PASSWORD))
      .add(Urls.UPDATE_DEVICE_TOKEN, id2 =>
        setPage(Pages.UPDATE_DEVICE_TOKEN, { id2 })
      )
      .add(Urls.TEAM, id => setPage(Pages.TEAM, { id }))
      .add(Urls.TEAM_INVITE, id => setPage(Pages.TEAM_INVITE, { id }))
      .add(Urls.TEAM_UPDATE, id => setPage(Pages.TEAM_UPDATE, { id }))
      .add(() => setPage(Pages.ERROR))
      /* Custom: new HOME page for SingleProjectApp */
      .add(Urls.HOME, () => setPage(Pages.HOME))
      /* Custom: switch PROJECT_HOME from Urls.HOME to Urls.PROJECT_HOME */
      .add(Urls.PROJECT_HOME, () => setPage(Pages.PROJECT_HOME))
      .add(Urls.FRAME, id => setPage(Pages.FRAME, { id }))
      .add(Urls.TOPIC, id => setPage(Pages.TOPIC, { id }))
      .add(Urls.CHALLENGE, id => setPage(Pages.CHALLENGE, { id }))
      .add(Urls.CHALLENGE_A_FRIEND, id =>
        setPage(Pages.CHALLENGE_A_FRIEND, { id })
      )
      .add(Urls.CHALLENGE_ENQUIRY, id =>
        setPage(Pages.CHALLENGE_ENQUIRY, { id })
      )
      .add(Urls.ACHIEVEMENTS, () => setPage(Pages.PROJECT_ACHIEVEMENTS))
      .add(Urls.PROJECT_USER_ACHIEVEMENTS, id =>
        setPage(Pages.PROJECT_USER_ACHIEVEMENTS, { id })
      )
      .add(Urls.PROJECT_BOOKMARKS, () => setPage(Pages.PROJECT_BOOKMARKS))
      .add(Urls.PROJECT_PROFILE, () => setPage(Pages.PROJECT_PROFILE))
      .add(Urls.PROJECT_SEARCH, id => setPage(Pages.PROJECT_SEARCH, { id }))
      .add(Urls.PROJECT_TEAMS, () => setPage(Pages.PROJECT_TEAMS))
      .add(Urls.PROJECT_TEAM_CREATE, () => setPage(Pages.PROJECT_TEAM_CREATE))
      .add(Urls.PROJECT_USER, id => setPage(Pages.PROJECT_USER, { id }))
      .add(Urls.PROJECT_USER_ENQUIRY, id =>
        setPage(Pages.PROJECT_USER_ENQUIRY, { id })
      )
      .add(Urls.PROJECT_USERS, () => setPage(Pages.PROJECT_USERS))
      .add(Urls.PROJECT_REWARDS, () => setPage(Pages.PROJECT_REWARDS))
      .add(Urls.ITEM, id => setPage(Pages.ITEM, { id }))
      .add(Urls.PROJECT_LEADERBOARD, () => setPage(Pages.PROJECT_LEADERBOARD))
      .add(Urls.PROJECT_REFER_A_FRIEND, () =>
        setPage(Pages.PROJECT_REFER_A_FRIEND)
      )
      .add(Urls.PROJECT_ACTIVITY, () => setPage(Pages.PROJECT_ACTIVITY))
      .add(Urls.CHALLENGE_ACTIVITY, id =>
        setPage(Pages.CHALLENGE_ACTIVITY, { id })
      )
      .add(Urls.CHALLENGE_MY_ACTIVITY, id =>
        setPage(Pages.CHALLENGE_MY_ACTIVITY, { id })
      )
      .add(Urls.CLAIM, id => setPage(Pages.CLAIM, { id }))
      .add(Urls.CLAIM_ENQUIRY, id => setPage(Pages.CLAIM_ENQUIRY, { id }));

    if (SHOW_TOPIC_CATEGORIES) {
      Router.add(Urls.TOPIC_CATEGORY, id =>
        setPage(Pages.TOPIC_CATEGORY, { id })
      );
    }

    if (SHOW_CHALLENGE_CATEGORIES) {
      Router.add(Urls.CHALLENGE_CATEGORY, (id, id2) =>
        setPage(Pages.CHALLENGE_CATEGORY, { id, id2 })
      );
    }

    if (ENABLE_TOUR) {
      Router.add(Urls.LOGIN_TOUR, () => setPage(Pages.LOGIN_TOUR));
    }

    if (ENABLE_INBOX) {
      Router.add(Urls.INBOX, () => setPage(Pages.INBOX));
    }

    if (ENABLE_TOPIC_COMMENTS) {
      Router.add(Urls.TOPIC_COMMENTS, id =>
        setPage(Pages.TOPIC_COMMENTS, { id })
      ).add(Urls.TOPIC_COMMENTS_THREAD, (id, id2) =>
        setPage(Pages.TOPIC_COMMENTS_THREAD, { id, id2 })
      );
    }

    if (ENABLE_CHALLENGE_COMMENTS) {
      Router.add(Urls.CHALLENGE_COMMENTS, id =>
        setPage(Pages.CHALLENGE_COMMENTS, { id })
      ).add(Urls.CHALLENGE_COMMENTS_THREAD, (id, id2) =>
        setPage(Pages.CHALLENGE_COMMENTS_THREAD, { id, id2 })
      );
    }

    if (ENABLE_CLAIM_COMMENTS) {
      Router.add(Urls.CLAIM_COMMENTS, id =>
        setPage(Pages.CLAIM_COMMENTS, { id })
      ).add(Urls.CLAIM_COMMENTS_THREAD, (id, id2) =>
        setPage(Pages.CLAIM_COMMENTS_THREAD, { id, id2 })
      );
    }

    if (ENABLE_REGISTER_PAGES) {
      Router.add(Urls.REGISTER, () => setPage(Pages.REGISTER));
    }

    if (ENABLE_SETTINGS_PAGES) {
      Router.add(Urls.SETTINGS, () => setPage(Pages.SETTINGS));
    }

    if (ENABLE_NOTIFICATIONS_PAGES) {
      Router.add(Urls.PROJECT_NOTIFICATIONS, () =>
        setPage(Pages.PROJECT_NOTIFICATIONS)
      );
    }

    if (ENABLE_LOGIN_HOME) {
      Router.add(Urls.LOGIN_HOME, () => setPage(Pages.LOGIN_HOME));

      if (USE_LOGIN_HOME_TEMPLATE_1 && ENABLE_OAUTH_LOGIN) {
        Router.add(Urls.LOGIN_SSO, () => setPage(Pages.LOGIN_SSO));
      }
    }

    if (ENABLE_SSO_LOGIN) {
      Router.add(Urls.SSO_LOGIN_SUCCESS, id =>
        setPage(Pages.SSO_LOGIN_SUCCESS, { id })
      );
    }

    Router.check();
    Router.listen();
  }

  reloadOnLogin() {
    const checkLogin = () => {
      if (this.restoreSession()) {
        // research if there is a better way to remount
        let component = this.state.component;
        this.setState({ component: <Loading /> }, () => {
          this.setState({ component: component });
        });
      }
    };

    window.addEventListener("storage", checkLogin);
  }

  initializeLanguage() {
    let langUrlParam = urlServices.getUrlParamValueByKey("lang");
    let loadedLanguage = localStorageService.getItem("language");

    /* Initialize language variable */
    let language = "";
    if (
      ENABLE_LANG_URL_PARAM &&
      typeof langUrlParam === "string" &&
      Array.isArray(VALID_PLATFORM_AVAILABLE_LANGUAGES_VARIATIONS) &&
      VALID_PLATFORM_AVAILABLE_LANGUAGES_VARIATIONS.indexOf(
        langUrlParam.toLowerCase()
      ) !== -1
    ) {
      /* If there is a valid URL param lang, set it */
      let reducedLanguage = reduceLanguages(langUrlParam.toLowerCase());

      localStorageService.setItem("language", reducedLanguage);
      this.props.setLanguage(reducedLanguage);
    } else if (
      !loadedLanguage &&
      (!this.props.language || this.props.language === "")
    ) {
      /*
        Checks if there is already a language loaded from localStorage
        or Redux store. In this else-if block, no language is loaded in
        localStorage nor Redux store, so we assume it is a first-time
        visitor.
      */

      /*
        For first-time visitors, if there is a DEFAULT_LANGUAGE
        which is also one of the VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS,
        set the language variable to DEFAULT_LANGUAGE
      */
      if (
        typeof DEFAULT_LANGUAGE === "string" &&
        Array.isArray(VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS) &&
        VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS.indexOf(DEFAULT_LANGUAGE) !==
          -1
      ) {
        language = DEFAULT_LANGUAGE;
      } else {
        /*
        Else for first-time visitors, we will rely on the browser's
        navigator object for setting the language.
      */
        language =
          (navigator.languages && navigator.languages[0]) ||
          navigator.language ||
          navigator.userLanguage;
      }

      let reducedLanguage = reduceLanguages(language);

      localStorageService.setItem("language", reducedLanguage);
      this.props.setLanguage(reducedLanguage);
    } else if (!this.props.language || this.props.language === "") {
      /*
      In this else-if block, a language is loaded from localStorage,
      but no language is found in the Redux store. Thus, we will
      re-populate language in Redux store.
    */
      this.props.setLanguage(reduceLanguages(loadedLanguage));
    }
  }

  setIOSBodyStyles(set) {
    let isIOS =
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
      !window.MSStream;

    if (set && isIOS) {
      if (!document.body.classList.contains("ios-body")) {
        document.body.classList.add("ios-body");
      }

      if (!document.documentElement.classList.contains("ios-body")) {
        document.documentElement.classList.add("ios-body");
      }
    } else {
      if (document.body.classList.contains("ios-body")) {
        document.body.classList.remove("ios-body");
      }

      if (document.documentElement.classList.contains("ios-body")) {
        document.documentElement.classList.remove("ios-body");
      }
    }
  }

  initializeEmbeddedProject() {
    let isEmbeddedProject = -1;

    if (
      typeof urlServices.getUrlParamValueByKey("embedded_project") ===
        "string" &&
      urlServices.getUrlParamValueByKey("embedded_project").toLowerCase() ===
        "true"
    ) {
      isEmbeddedProject = 1;
    } else if (
      typeof urlServices.getUrlParamValueByKey("embedded_project") ===
        "string" &&
      urlServices.getUrlParamValueByKey("embedded_project").toLowerCase() ===
        "false"
    ) {
      isEmbeddedProject = 0;
    }

    /* If isEmbeddedProject remains at -1, we assume no changes required */
    if (isEmbeddedProject === 1) {
      this.setEmbeddedProject(true);
    } else if (isEmbeddedProject === 0) {
      this.setEmbeddedProject(false);
    }
  }

  initialize640DesktopProject() {
    let is640DesktopProject = -1;

    if (
      typeof urlServices.getUrlParamValueByKey("640_desktop_project") ===
        "string" &&
      urlServices.getUrlParamValueByKey("640_desktop_project").toLowerCase() ===
        "true"
    ) {
      is640DesktopProject = 1;
    } else if (
      typeof urlServices.getUrlParamValueByKey("640_desktop_project") ===
        "string" &&
      urlServices.getUrlParamValueByKey("640_desktop_project").toLowerCase() ===
        "false"
    ) {
      is640DesktopProject = 0;
    }

    /* If isEmbeddedProject remains at -1, we assume no changes required */
    if (is640DesktopProject === 1) {
      this.set640DesktopProject(true);
    } else if (is640DesktopProject === 0) {
      this.set640DesktopProject(false);
    }
  }

  setEmbeddedProject(set) {
    if (set === true) {
      sessionStorageService.setItem("embedded_project", "true");
    } else {
      sessionStorageService.removeItem("embedded_project");
    }
  }

  set640DesktopProject(set) {
    if (set === true) {
      sessionStorageService.setItem("640_desktop_project", "true");
    } else {
      sessionStorageService.removeItem("640_desktop_project");
    }
  }

  validateSession() {
    let data = {
      token: Math.random() /* Dummy parameter so that data will not be null */
    };

    pushApiGenerator(VALIDATE_SESSION, data, this.props.sessionKey).end(
      (err, res) => {
        if (
          err ||
          res.body.code !== 200 ||
          (this.props.userId && this.props.userId !== res.body.userId)
        ) {
          logoutServices.sessionLogout(
            this.props.projectId,
            this.props.language,
            this.props.setSessionKey,
            this.props.setUser,
            this.props.showAlertWithTimeout,
            /* Custom: inclusion of setLanguage */
            this.props.setLanguage
          );
        }
      }
    );
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) {
            return null;
          }
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <div className="page-wrapper">
              <ChallengeCompletedModalContainer />
              <TopbarContainer />
              <AlertContainer />
              <DownloadAppAlert language={this.props.language} />
              <div className="fullwidth page-container">
                {/* Custom: removed SidebarContainer here */}
                {/*
                  IE has an issue with stacking contexts, so Profile Drawer
                  will not show up if placed after .page, even if the z-index
                  is higher. Read: https://stackoverflow.com/questions/12517158/z-index-in-internet-explorer-not-working
                */}
                <ProfileDrawerContainer />
                <div className="page fullwidth">
                  <FloatingButtonsContainer />
                  <QRChallengeModalProjectHome />
                  <AwardableActionsContainer />
                  {/*
                    Use AchievementsModalContainer if you intend to retrieve Achievements
                    from Redux store. Otherwise, just use AchievementsModal.
                  */}
                  <AchievementsModalContainer />
                  <div className="component-container">
                    {this.state.component}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProjectApp);
