import React, { Component } from "react";
import PropTypes from "prop-types";

import { PROJECT_USER, USER, PROJECT_PROFILE, PROFILE } from "App/Routes";
import Link from "components/shared/Link/Link";

const propTypes = {
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired
};

class Team extends Component {
  getLink() {
    if (this.props.userId === this.props.id && this.props.projectId) {
      return PROJECT_PROFILE.format(this.props.projectId);
    } else if (this.props.userId === this.props.id) {
      return PROFILE;
    } else if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.props.id);
    } else {
      return USER.format(this.props.id);
    }
  }

  render() {
    let img = this.props.img;
    let name = this.props.name;
    let link = this.getLink();

    return (
      <Link to={link}>
        <div className="pure-g bottompadding">
          <div className="pure-u-1-24" />
          <div className="pure-u-8-24">
            <img className="iconlarge circle imgcenter" src={img} alt={name} />
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-14-24 relative">
            <div className="center">
              <strong>{name}</strong>
            </div>
          </div>
          <div className="pure-u-1-24" />
        </div>
      </Link>
    );
  }
}

Team.propTypes = propTypes;

export default Team;
