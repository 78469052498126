import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import TextboxInput from "components/shared/Inputs/TextboxInput";

/* For video challenge */
import VideoInput from "components/shared/Inputs/VideoInput";

import localize from "lang/localize";

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  submitted: PropTypes.bool.isRequired,
  videoDurationLimit: PropTypes.number,
  videoDurationLimitFormatted: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  handleOpenClaimLoginDialog: PropTypes.func.isRequired,
  language: PropTypes.string
};

class VideoChallenge extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
      videoname: null,
      video: null
    };
  }

  onTextChange(event) {
    this.setState({ text: event.target.value });
  }

  onVideoChange(video) {
    if (video[0]) {
      this.setState({
        video: video[0],
        videoname: video[0].name
      });
    }
  }

  onVideoReset() {
    this.setState({
      video: null,
      videoname: null
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.handleSubmit(
      { comment: this.state.text },
      this.state.video,
      this.state.videoname
    );
    return true;
  }

  handleOpenClaimLoginDialog(event) {
    event.preventDefault();

    this.props.handleOpenClaimLoginDialog();
  }

  render() {
    return (
      <Fragment>
        <div className="pure-form">
          {/*
              Set the above back to <form className="pure-form">
              if we are no longer using <input type="file">
              for image inputs
          */}
          <div className="pure-u-1-1 pure-u-md-16-24 align-fields-to-image-input">
            <TextboxInput
              placeholder={localize(
                "comment_placeholder_text",
                this.props.language
              )}
              onChange={this.onTextChange.bind(this)}
              readOnly={!this.props.isLoggedIn}
              onClick={
                !this.props.isLoggedIn
                  ? this.handleOpenClaimLoginDialog.bind(this)
                  : () => {}
              }
              className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
            />
          </div>
          <div className="pure-u-1-1 pure-u-md-8-24">
            <VideoInput
              videoname={this.state.videoname}
              videoDurationLimit={this.props.videoDurationLimit}
              videoDurationLimitFormatted={
                this.props.videoDurationLimitFormatted
              }
              onDrop={this.onVideoChange.bind(this)}
              resetFiles={this.onVideoReset.bind(this)}
              showAlertWithTimeout={this.props.showAlertWithTimeout}
              disabled={!this.props.isLoggedIn}
              handleDisabledClick={this.handleOpenClaimLoginDialog.bind(this)}
              language={this.props.language}
            />
          </div>
        </div>
        <div>
          {this.props.isLoggedIn ? (
            <button
              className={
                "button automargin " +
                (this.props.submitted || this.state.video == null
                  ? "inactive"
                  : "cta")
              }
              onClick={this.handleSubmit.bind(this)}
              disabled={this.props.submitted || this.state.video == null}
              id="submitCompletionButton"
            >
              {this.props.submitted
                ? localize("button_claim_submitting", this.props.language)
                : localize("button_claim_get_points", this.props.language)}
            </button>
          ) : (
            <button
              className={
                "button automargin " +
                (this.props.submitted || this.state.video == null
                  ? "inactive"
                  : "cta")
              }
              onClick={this.handleOpenClaimLoginDialog.bind(this)}
              disabled={this.props.submitted || this.state.video == null}
              id="loginBeforeSubmitCompletionButton"
            >
              {this.props.submitted
                ? localize("button_claim_submitting", this.props.language)
                : localize("button_claim_get_points", this.props.language)}
            </button>
          )}
          {/*
            Set the below back to </form> if we are
            no longer using <input type="file"> for
            image inputs
          */}
        </div>
      </Fragment>
    );
  }
}

VideoChallenge.propTypes = propTypes;

export default VideoChallenge;
