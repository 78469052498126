import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./Challenge.css";
import { TOPIC } from "App/Routes";
import ChallengePage from "components/Challenge/ChallengePage";
import ClaimForm from "./ClaimForm/ClaimForm";
import ChallengeResult from "./ChallengeResult/ChallengeResult";
import LocationMap from "components/Challenge/LocationMap";
import BreadcrumbsContainer from "custom-components/shared/Breadcrumbs/BreadcrumbsContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import Link from "components/shared/Link/Link";

import { CONTACT_EMAIL_DEFAULT } from "config";
import localize from "lang/localize";

const propTypes = {
  user: PropTypes.object.isRequired,
  sessionKey: PropTypes.string,
  challengeTypeId: PropTypes.number.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  photoRequired: PropTypes.bool,
  noPhoto: PropTypes.bool,
  quizOptions: PropTypes.array,
  privateClaim: PropTypes.bool,
  fields: PropTypes.array,
  completionMessage: PropTypes.string,
  multiSelect: PropTypes.bool,
  claimed: PropTypes.bool,
  claimedBefore: PropTypes.bool,
  expired: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  videoDurationLimit: PropTypes.number,
  // location
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  addressFormatted: PropTypes.string,
  // challengeresult
  id: PropTypes.number.isRequired,
  correctAnswerNo: PropTypes.number,
  wrongAnswerNo: PropTypes.number,
  correctAnswerTitle: PropTypes.string,
  correctAnswer: PropTypes.bool,
  // image
  imageMedium: PropTypes.string,
  // embed
  medias: PropTypes.array,
  // text
  footnote: PropTypes.string,
  title: PropTypes.string,
  repeat: PropTypes.bool,
  repeatUntilCorrect: PropTypes.bool,
  repeatAtFormatted: PropTypes.string,
  videoDurationLimitFormatted: PropTypes.string,
  gridWidth: PropTypes.number,
  // stats
  commentNo: PropTypes.number,
  claimNo: PropTypes.number,
  previousChallengeId: PropTypes.number,
  nextChallengeId: PropTypes.number,
  challengeType: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  challengeReferralLink: PropTypes.string,
  isLiked: PropTypes.bool.isRequired,
  likeNo: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  postClaim: PropTypes.func.isRequired,
  postQRClaim: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  adminToClaim: PropTypes.bool.isRequired,
  // login dialogs
  showClaimLoginDialog: PropTypes.bool.isRequired,
  handleOpenClaimLoginDialog: PropTypes.func.isRequired,
  handleCloseClaimLoginDialog: PropTypes.func.isRequired,
  showBookmarkLoginDialog: PropTypes.bool.isRequired,
  handleOpenBookmarkLoginDialog: PropTypes.func.isRequired,
  handleCloseBookmarkLoginDialog: PropTypes.func.isRequired,
  showLikeLoginDialog: PropTypes.bool.isRequired,
  handleOpenLikeLoginDialog: PropTypes.func.isRequired,
  handleCloseLikeLoginDialog: PropTypes.func.isRequired,
  // image dialogs
  showImageDialog: PropTypes.bool.isRequired,
  handleOpenImageDialog: PropTypes.func.isRequired,
  handleCloseImageDialog: PropTypes.func.isRequired,
  // embed dialogs
  showEmbedDialog: PropTypes.bool.isRequired,
  handleOpenEmbedDialog: PropTypes.func.isRequired,
  handleCloseEmbedDialog: PropTypes.func.isRequired,
  // qr dialogs
  showQRModal: PropTypes.bool,
  handleOpenQRModal: PropTypes.func,
  handleCloseQRModal: PropTypes.func,
  // minigame dialogs
  showExternalChallengeDialog: PropTypes.bool,
  handleOpenExternalChallengeDialog: PropTypes.func,
  handleCloseExternalChallengeDialog: PropTypes.func,
  // language
  language: PropTypes.string,
  // contact email
  contactEmail: PropTypes.string,
  // locked content handling
  locked: PropTypes.bool,
  lockedChallengeTopicId: PropTypes.number,
  lockedChallengeTopicTitle: PropTypes.string,
  // project and topic information
  project: PropTypes.object,
  topic: PropTypes.object,
  /* Custom: next topic id */
  topicIdNext: PropTypes.number
};

const defaultProps = {
  contactEmail: CONTACT_EMAIL_DEFAULT
};

class CyberwellnessChallengePage extends ChallengePage {
  /* Custom: Next Topic Button */
  renderNextTopicButton() {
    if (this.props.topicIdNext) {
      return (
        <Link
          to={TOPIC.format(this.props.topicIdNext)}
          id="cta"
          className="button display-inline-block cta lowercase topic-next-button"
        >
          {localize("button_next_topic", this.props.language)}
        </Link>
      );
    } else {
      return null;
    }
  }

  /* Custom: Modified layout */
  renderChallengeInfo() {
    if (this.props.locked) {
      return (
        <div className="pure-g innerblock">
          <div className="pure-u-2-24 prev-next-button-position">
            {this.renderPreviousChallengeButton()}
          </div>
          <div className="pure-u-20-24 topmargin-30">
            <GenericErrorPage
              routeUrl={TOPIC.format(this.props.lockedChallengeTopicId)}
              routeName={"“" + this.props.lockedChallengeTopicTitle + "”"}
              message={localize(
                "locked_challenge_with_route",
                this.props.language
              ).format(this.props.lockedChallengeTopicTitle)}
              language={this.props.language}
            />
          </div>
          <div className="pure-u-2-24 prev-next-button-position">
            {this.renderNextChallengeButton()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="pure-g">
          {/* Support for Topic Progress Bar */}
          {/* Custom: Only display for challenges and not flashcards */}
          {this.renderTopicProgressBarForChallengeOnly()}
          {/* Challenge Media */}
          <div className="pure-u-1 pure-u-md-8-24 relative">
            {this.renderMedia()}
          </div>
          <div className="pure-u-md-1-24" />
          <div className="pure-u-1 pure-u-md-15-24 topmargin-10">
            {this.renderText()}
            {/* Custom: removed renderStats() */}
            {this.renderButtons()}
            <div className="challenge-claim-form">
              <ClaimForm
                sessionKey={this.props.sessionKey}
                isLoggedIn={!!this.props.user.id}
                challengeTypeId={this.props.challengeTypeId}
                challengeId={this.props.id}
                isConfirmationChallenge={this.props.isConfirmationChallenge}
                photoRequired={this.props.photoOnly}
                noPhoto={this.props.noPhoto}
                quizOptions={this.props.quizOptions}
                fields={this.props.fields}
                completionMessage={this.props.completionMessage}
                multiSelect={this.props.multiSelect}
                claimed={this.props.claimed}
                claimedBefore={this.props.claimedBefore}
                postClaim={this.props.postClaim}
                postQRClaim={this.props.postQRClaim}
                gridWidth={this.props.gridWidth}
                showAlertWithTimeout={this.props.showAlertWithTimeout}
                adminToClaim={this.props.adminToClaim}
                expired={this.props.expired}
                submitted={this.props.submitted}
                videoDurationLimit={this.props.videoDurationLimit}
                videoDurationLimitFormatted={
                  this.props.videoDurationLimitFormatted
                }
                externalEmbeddable={this.getFirstEmbeddableItem()}
                handleOpenClaimLoginDialog={
                  this.props.handleOpenClaimLoginDialog
                }
                showQRModal={this.props.showQRModal}
                handleOpenQRModal={this.props.handleOpenQRModal}
                handleCloseQRModal={this.props.handleCloseQRModal}
                showExternalChallengeDialog={
                  this.props.showExternalChallengeDialog
                }
                handleOpenExternalChallengeDialog={
                  this.props.handleOpenExternalChallengeDialog
                }
                handleCloseExternalChallengeDialog={
                  this.props.handleCloseExternalChallengeDialog
                }
                nextChallengeId={this.props.nextChallengeId}
                language={this.props.language}
              />
              {this.props.showClaimLoginDialog && this.renderClaimLoginDialog()}
              <LocationMap
                latitude={this.props.latitude}
                longitude={this.props.longitude}
                addressFormatted={this.props.addressFormatted}
                language={this.props.language}
              />
              <div className="challenge-activity topmargin">
                <ChallengeResult
                  id={this.props.id}
                  userName={this.props.user.name}
                  userPhoto={this.props.user.photoSmall}
                  challengeTypeId={this.props.challengeTypeId}
                  claimed={!!this.props.claimed}
                  claimedBefore={!!this.props.claimedBefore}
                  repeatUntilCorrect={this.props.repeatUntilCorrect}
                  correctAnswerNo={this.props.correctAnswerNo}
                  wrongAnswerNo={this.props.wrongAnswerNo}
                  correctAnswerTitle={this.props.correctAnswerTitle}
                  correctAnswer={this.props.correctAnswer}
                  quizOptions={this.props.quizOptions}
                  gridWidth={this.props.gridWidth}
                  privateClaim={this.props.privateClaim}
                  language={this.props.language}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  /* Custom: Render topic progress bar only for challenges and not flashcards */
  renderTopicProgressBarForChallengeOnly() {
    if (this.props.challengeType !== "non-interactive") {
      return (
        <div className="pure-g position-sticky-offset-topbar pure-u-1-1 bottommargin-10">
          <div className="pure-u-1-1 box-sizing-border-box-all">
            {this.renderTopicProgressBarForChallenge()}
          </div>
        </div>
      );
    }
  }

  /* Custom: No anchoring */
  renderText() {
    return (
      <div className="challenge-info">
        <h5 dangerouslySetInnerHTML={{ __html: this.props.title }} />
        <div dangerouslySetInnerHTML={{ __html: this.props.footnote }} />
        {this.getRepeatText(this.props.repeat)}
      </div>
    );
  }

  /* Custom: use .display-inline-block instead of .inline for buttons */
  renderActionButton() {
    if (this.props.challengeTypeId === 4) {
      return null;
    }
    if (this.props.expired) {
      return (
        <button
          id="cta"
          className="button display-inline-block inactive rightmargin-5"
        >
          {localize("button_challenge_challenge_expired", this.props.language)}
        </button>
      );
    } else if (this.props.claimed) {
      return (
        <button
          id="cta"
          className="button display-inline-block inactive rightmargin-5"
        >
          {localize(
            "button_challenge_challenge_completed",
            this.props.language
          )}
        </button>
      );
    } else if (this.props.adminToClaim) {
      return null;
    } else {
      return null;
    }
  }

  /* Custom: new function with action button and next topic button */
  renderCustomButtons() {
    if (this.props.expired || this.props.claimed || this.props.topicIdNext) {
      return (
        <div className="action-buttons-container topmargin-10">
          {this.renderActionButton()}
          {this.renderNextTopicButton()}
        </div>
      );
    } else {
      return null;
    }
  }

  /* Custom: */
  renderButtons() {
    return (
      <div className="topmargin">
        {this.renderBookmarkButton()}
        {this.props.challengeTypeId === 4 && this.renderLikeButton()}
        {this.renderChallengeCommentsButton()}
        {this.renderMenuDropdownButton()}
        {this.renderCustomButtons()}
      </div>
    );
  }

  /* Custom: wrap each return with a <div> tag */
  getRepeatText(repeat: boolean) {
    if (this.props.challengeTypeId === 4) {
      return null;
    }

    /* passcode/fixed answer challenge */
    if (this.props.challengeTypeId === 9) {
      return (
        <div className="smalltext topmargin">
          {localize("challenge_profile_repeat_once_text", this.props.language)}
        </div>
      );
    }

    if (this.props.repeatUntilCorrect) {
      return (
        <div className="smalltext topmargin">
          {localize("challenge_profile_repeat_again_text", this.props.language)}
        </div>
      );
    } else if (!repeat) {
      return (
        <div className="smalltext topmargin">
          {localize("challenge_profile_repeat_once_text", this.props.language)}
        </div>
      );
    } else if (this.props.repeatAtFormatted) {
      return (
        <div className="smalltext topmargin">
          {localize(
            "challenge_profile_repeat_again_text_with_duration",
            this.props.language
          ).format(this.props.repeatAtFormatted)}
        </div>
      );
    } else {
      return (
        <div className="smalltext topmargin">
          {localize("challenge_profile_repeat_again_text", this.props.language)}
        </div>
      );
    }
  }

  render() {
    return (
      <Fragment>
        <div className="challenge flex flex-direction-column box-sizing-border-box-all">
          {/* Fixed-positioned background for Screen */}
          <div className="background-cyberwellness"></div>
          <BreadcrumbsContainer />
          <div className="pure-g innerblock">
            <div className="pure-u-1-24" />
            <div className="pure-u-2-24 relative">
              {this.renderPreviousChallengeButton()}
            </div>
            <div className="pure-u-18-24">
              <div className="bg-white verticalpadding-10 horizontalpadding-10 verticalpadding-from-sm horizontalpadding-from-sm fullwidth">
                {this.renderChallengeInfo()}
              </div>
            </div>
            <div className="pure-u-2-24 relative">
              {this.renderNextChallengeButton()}
            </div>
            <div className="pure-u-1-24" />
          </div>
        </div>
      </Fragment>
    );
  }
}

CyberwellnessChallengePage.propTypes = propTypes;
CyberwellnessChallengePage.defaultProps = defaultProps;

export default CyberwellnessChallengePage;
