const deviceServices = {
  isIOS: function() {
    return (
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
      !window.MSStream
    );
  },
  isSafari: function() {
    return (
      !!window.navigator.userAgent.match(/WebKit/i) &&
      !window.navigator.userAgent.match(/CriOS/i) &&
      !window.navigator.userAgent.match(/FxiOS/i) &&
      !window.navigator.userAgent.match(/EdgiOS/i)
    );
  }
};

export default deviceServices;
