import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { HOME, PROJECT_HOME, TOPIC } from "App/Routes";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

const propTypes = {
  state: PropTypes.string,
  projectId: PropTypes.number,
  project: PropTypes.object,
  topic: PropTypes.object,
  challenge: PropTypes.object,
  language: PropTypes.string
};

class Breadcrumbs extends Component {
  renderLinks() {
    switch (this.props.state) {
      case "home-single":
      case "projecthome":
        return (
          <Fragment>
            <div className="display-inline-block rightmargin-5">
              <Link to={HOME}>
                {localize("icon_home", this.props.language)}
              </Link>
            </div>
            <div className="display-inline-block rightmargin-5">
              <span>&gt;</span>
            </div>
            <div className="display-inline-block">
              <span>{localize("dbs_login_title", this.props.language)}</span>
            </div>
          </Fragment>
        );
      case "topic":
        return (
          <Fragment>
            <div className="display-inline-block rightmargin-5">
              <Link to={HOME}>
                {localize("icon_home", this.props.language)}
              </Link>
            </div>
            <div className="display-inline-block rightmargin-5">
              <span>&gt;</span>
            </div>
            <div className="display-inline-block rightmargin-5">
              <Link to={PROJECT_HOME.format(this.props.projectId)}>
                {localize("dbs_login_title", this.props.language)}
              </Link>
            </div>
            <div className="display-inline-block rightmargin-5">
              <span>&gt;</span>
            </div>
            <div className="display-inline-block">
              <span>
                {this.props.topic && this.props.topic.title
                  ? this.props.topic.title.replace(/\n|\r/g, " ")
                  : ""}
              </span>
            </div>
          </Fragment>
        );
      case "challenge":
        return (
          <Fragment>
            <div className="display-inline-block rightmargin-5">
              <Link to={HOME}>
                {localize("icon_home", this.props.language)}
              </Link>
            </div>
            <div className="display-inline-block rightmargin-5">
              <span>&gt;</span>
            </div>
            <div className="display-inline-block rightmargin-5">
              <Link to={PROJECT_HOME.format(this.props.projectId)}>
                {localize("dbs_login_title", this.props.language)}
              </Link>
            </div>
            <div className="display-inline-block rightmargin-5">
              <span>&gt;</span>
            </div>
            <div className="display-inline-block rightmargin-5">
              <Link
                to={TOPIC.format(this.props.topic ? this.props.topic.id : "")}
              >
                {this.props.topic && this.props.topic.title
                  ? this.props.topic.title.replace(/\n|\r/g, " ")
                  : ""}
              </Link>
            </div>
            <div className="display-inline-block rightmargin-5">
              <span>&gt;</span>
            </div>
            <div className="display-inline-block">
              <span>
                {this.props.challenge && this.props.challenge.title
                  ? this.props.challenge.title.replace(/\n|\r/g, " ")
                  : ""}
              </span>
            </div>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Link to={HOME}>{localize("icon_home", this.props.language)}</Link>
          </Fragment>
        );
    }
  }

  render() {
    return (
      <nav className="breadcrumbs uppercase horizontalpadding-10 verticalpadding-10">
        <strong>{this.renderLinks()}</strong>
      </nav>
    );
  }
}

Breadcrumbs.propTypes = propTypes;

export default Breadcrumbs;
