import React, { Component } from "react";
import { connect } from "react-redux";

import LeaderboardPage from "./LeaderboardPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";

import { setProject, setButtons } from "actions";
import {
  GET_LEADERBOARD,
  GET_TOPIC_LEADERBOARD,
  GET_TOPICS
} from "services/api";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    project: state.project,
    projectId: state.projectId,
    team: state.team,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    }
  };
};

export class LeaderboardContainer extends Component {
  constructor() {
    super();
    this.state = {
      requests: [],
      indivReqStatus: [],
      teamReqStatus: [],
      currentBoard: "individual",
      type: "overall",
      individual: {
        overall: null,
        monthly: null,
        daily: null
      },
      team: {
        overall: null,
        monthly: null,
        daily: null
      },
      topics: null,
      topicLeaderboards: null,
      selectedTopicLeaderboard: 0,
      error: null,
      code: null
    };
  }

  getPageData(type = "project", time = "overall") {
    let requests = this.state.requests;

    if (this.props.project.teamEnabled && this.state.currentBoard === "team") {
      this.getPageTeamData(type, time);
    } else {
      if (type === "topic") {
        const req = this.getTopicLeaderboard(time);
        requests.push(req);
      } else {
        const req = this.getLeaderboard(time);
        requests.push(req);
      }

      this.setState({ requests: requests });
    }
  }

  getPageTeamData(type = "project", time = "overall") {
    let requests = this.state.requests;

    if (type === "topic") {
      const req = this.getTopicTeamLeaderboard(time, this.props.team.id);
      requests.push(req);
    } else {
      const req = this.getTeamLeaderboard(time, this.props.team.id);
      requests.push(req);
    }

    this.setState({ requests: requests });
  }

  async componentDidMount() {
    if (this.props.sessionKey) {
      this.getPageData();
    }

    // populate project in redux store
    if (this.props.projectId) {
      await this.getProject();

      if (this.props.project.teamEnabled) {
        //set default leaderboard to team if enabled
        this.setState({ currentBoard: "team" }, () => {
          this.getPageTeamData();
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.getPageData();
    }

    /* checks for team leaderboard whenever project id changes */
    if (
      prevProps.project.id !== this.props.project.id &&
      this.props.project.teamEnabled &&
      this.state.currentBoard === "team"
    ) {
      this.getPageTeamData();
    }
  }

  componentWillUnmount() {
    // cancel ongoing requests if component is unmounted
    this.state.requests.forEach(req => {
      if (req) {
        req.abort();
      }
    });
  }

  async getProject() {
    await new Promise((resolve, reject) => {
      getApiGenerator(
        GET_TOPICS.format(this.props.projectId),
        {
          page: 1
        },
        this.props.sessionKey
      ).end((err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.code === 500) {
            this.setState({
              topics: [],
              code: 500,
              error: res.body.error
            });
          }
          reject(err);
        } else {
          this.setState({
            topics: res.body.data,
            code: res.body.code,
            error: ""
          });

          const BUTTONS =
            res.body.buttons && res.body.buttons.length > 0
              ? res.body.buttons
              : null;

          this.props.setProject(res.body.game);
          this.props.setButtons(BUTTONS);
          resolve();
        }
      });
    });
  }

  getTeamLeaderboard(type, teamID, team = true) {
    let query = { team_id: teamID, type: type, team: team };
    return getApiGenerator(
      GET_LEADERBOARD.format(this.props.projectId),
      query,
      this.props.sessionKey
    ).end((err, res) => {
      let reqStatus = this.state.teamReqStatus;
      reqStatus.push(type);
      this.setState({ teamReqStatus: reqStatus });
      this.setState({ topicLeaderboards: res.body.topics });

      if (err || res.body.code !== 200) {
        /* Returns "No one scored yet" */
      } else {
        /* Returns leaderboard */
        this.setTeamLeaderboardData(type, res);
      }
    });
  }

  getTopicTeamLeaderboard(type, teamID, team = true) {
    let query = { team_id: teamID, type: type, team: team };
    return getApiGenerator(
      GET_TOPIC_LEADERBOARD.format(this.state.selectedTopicLeaderboard),
      query,
      this.props.sessionKey
    ).end((err, res) => {
      let reqStatus = this.state.teamReqStatus;
      reqStatus.push(type);
      this.setState({ teamReqStatus: reqStatus });

      if (err || res.body.code !== 200) {
        /* Returns "No one scored yet" */
      } else {
        /* Returns leaderboard */
        this.setTeamLeaderboardData(type, res);
      }
    });
  }

  getLeaderboard(type) {
    let query = { user_id: this.props.user.id, type: type };
    return getApiGenerator(
      GET_LEADERBOARD.format(this.props.projectId),
      query,
      this.props.sessionKey
    ).end((err, res) => {
      let reqStatus = this.state.indivReqStatus;
      reqStatus.push(type);
      this.setState({ indivReqStatus: reqStatus });
      this.setState({ topicLeaderboards: res.body.topics });

      if (err || res.body.code !== 200) {
        /* Returns "No one scored yet" */
      } else {
        /* Returns leaderboard */
        this.setLeaderboardData(type, res);
      }
    });
  }

  getTopicLeaderboard(type) {
    let query = { user_id: this.props.user.id, type: type };
    return getApiGenerator(
      GET_TOPIC_LEADERBOARD.format(this.state.selectedTopicLeaderboard),
      query,
      this.props.sessionKey
    ).end((err, res) => {
      let reqStatus = this.state.indivReqStatus;
      reqStatus.push(type);
      this.setState({ indivReqStatus: reqStatus });

      if (err || res.body.code !== 200) {
        /* Returns "No one scored yet" */
      } else {
        /* Returns leaderboard */
        this.setLeaderboardData(type, res);
      }
    });
  }

  setTeamLeaderboardData(type, res) {
    let tmpState = this.state;
    tmpState.team[type] = {
      mainBoard: res.body.mainBoard || [],
      userBoard: res.body.userBoard,
      type: res.body.type
    };
    this.setState(tmpState);
  }

  setLeaderboardData(type, res) {
    let tmpState = this.state;
    tmpState.individual[type] = {
      mainBoard: res.body.mainBoard || [],
      userBoard: res.body.userBoard,
      type: res.body.type
    };
    this.setState(tmpState);
  }

  handleToggleBoard = event => {
    this.setState({ currentBoard: event.currentTarget.id }, () => {
      if (this.state.selectedTopicLeaderboard === 0) {
        this.getPageData(undefined, this.state.type);
      } else {
        this.getPageData("topic", this.state.type);
      }
    });
  };

  handleToggleType = event => {
    this.setState({ type: event.currentTarget.id });
    if (this.state.selectedTopicLeaderboard === 0) {
      this.getPageData(undefined, event.currentTarget.id);
    } else {
      this.getPageData("topic", event.currentTarget.id);
    }
  };

  handleToggleTopic = event => {
    const TARGET_VALUE = Number(event.target.value);
    this.state.requests.forEach(req => {
      if (req) {
        req.abort();
      }
    });
    if (TARGET_VALUE !== this.state.selectedTopicLeaderboard) {
      this.setState(
        {
          selectedTopicLeaderboard: TARGET_VALUE,
          requests: []
        },
        () => {
          this.setState(
            {
              individual: {
                overall: null,
                monthly: null,
                daily: null
              },
              team: {
                overall: null,
                monthly: null,
                daily: null
              }
            },
            () => {
              if (this.state.selectedTopicLeaderboard === 0) {
                this.getPageData(undefined, this.state.type);
              } else {
                this.getPageData("topic", this.state.type);
              }
            }
          );
        }
      );
    }
  };

  render() {
    if (!this.props.user.id) {
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (
      this.state.indivReqStatus.indexOf("overall") >= 0 &&
      this.props.project.teamEnabled !== undefined
    ) {
      /* Retrieving individual/player overall leaderboard is the fastest */
      /* Default view is also individual/player overall leaderboard */
      /* If can't even retrieve it, send to loading screen */
      return (
        <LeaderboardPage
          projectId={this.props.projectId}
          mainBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].mainBoard
              : null
          }
          userBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].userBoard
              : null
          }
          topicLeaderboards={this.state.topicLeaderboards}
          type={this.state.type}
          board={this.state.currentBoard}
          teamAvailable={this.props.project.teamEnabled}
          handleToggleType={this.handleToggleType}
          handleToggleBoard={this.handleToggleBoard}
          handleToggleTopic={this.handleToggleTopic}
          userId={this.props.user.id}
          teamId={this.props.team.id}
          indivReqStatus={this.state.indivReqStatus}
          teamReqStatus={this.state.indivReqStatus}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardContainer);
