import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./CommentsPage.css";
import { TOPIC } from "App/Routes";
import Link from "components/shared/Link/Link";
import TextboxInput from "components/shared/Inputs/TextboxInput";
import Comment from "./Comment";
import AchievementsModal from "components/shared/Achievements/AchievementsModal/AchievementsModal";

import localize from "lang/localize";
import Loading from "components/shared/Loading";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  comments: PropTypes.array,
  more: PropTypes.bool,
  showInput: PropTypes.bool,
  input: PropTypes.string,
  handleMore: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  userId: PropTypes.number,
  removeComment: PropTypes.func,
  /* for switching to Achievements modal */
  showAchievements: PropTypes.bool,
  achievements: PropTypes.array,
  setHideAchievements: PropTypes.func,
  setAchievements: PropTypes.func
};

const ChallengesCommentsNav = props => {
  if (props.type === "quest") {
    return (
      <nav className="nav-buttons">
        <Link to={TOPIC.format(props.id)} className="button inactive">
          <span className="capitalize">
            {localize("challenges_novariable_text", props.language)
              .format("")
              .trim()}
          </span>
        </Link>
        <button className="button active">
          <span className="capitalize">
            {localize("comments_novariable_text", props.language)
              .format("")
              .trim()}
          </span>
        </button>
      </nav>
    );
  } else {
    return null;
  }
};

const Comments = props => {
  if (
    props.comments &&
    Array.isArray(props.comments) &&
    props.comments.length > 0
  ) {
    return (
      <div className="topmargin-30">
        {props.comments.map((comment, index) => (
          <Fragment key={comment.id}>
            <Comment
              id={comment.id}
              commentUserName={comment.userName}
              commentUserPhoto={comment.photoSmall}
              commentUserId={comment.userId}
              userId={props.userId}
              userRankStatus={comment.userRankStatus}
              userRankStatusImage={comment.userRankStatusImage}
              message={comment.message}
              createdAtFormatted={comment.createdAtFormatted}
              projectId={props.projectId}
              removeComment={props.removeComment}
              type={props.type}
              typeId={props.id}
              commentThreadCount={comment.commentThreadCount}
            />
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <p className="topmargin-30 text-align-center">
        {localize("empty_comment_table_text", props.language)}
      </p>
    );
  }
};

const CommentInput = props => {
  if (!!props.showInput) {
    return (
      <div id="commentInput" className="bottompadding">
        <form className="pure-form" onSubmit={props.handleSubmit}>
          <div className="pure-u-1">
            <TextboxInput
              type="text"
              value={props.input}
              placeholder={localize("comment_placeholder_text", props.language)}
              onChange={props.handleInputChange}
            />
          </div>
          <div className="pure-u-1">
            <button
              type="submit"
              disabled={props.input.trim() === "" ? true : false}
              className={
                "button nomargin floatright " +
                (props.input.trim() === "" ? "disabled" : "cta")
              }
            >
              {localize("nav_bar_title_comments_text", props.language)}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return null;
  }
};

// const MoreButtonContainer = props => {
//   if (props.more) {
//     return (
//       <div className="more-container flex justify-content-center">
//         <button id="pagination" className="button" onClick={props.handleMore}>
//           {localize("more_text", props.language)}
//         </button>
//       </div>
//     );
//   } else {
//     return null;
//   }
// };

const CommentsPage = props => {
  return (
    <Fragment>
      <ChallengesCommentsNav
        id={props.id}
        type={props.type}
        language={props.language}
      />
      <div className="comments-container container verticalpadding bottompadding-floating-extended">
        <div className="innerblock">
          <CommentInput
            showInput={props.showInput}
            input={props.input}
            handleSubmit={props.handleSubmit}
            handleInputChange={props.handleInputChange}
            language={props.language}
          />
          <Comments
            comments={props.comments}
            userId={props.userId}
            projectId={props.projectId}
            removeComment={props.removeComment}
            type={props.type}
            id={props.id}
            language={props.language}
          />
          <AchievementsModal
            showModal={props.showAchievements}
            handleCloseModal={props.setHideAchievements}
            achievements={props.achievements}
          />
          {props.isLoadingMore ? <Loading /> : null}
          {/* <MoreButtonContainer
            more={props.more}
            handleMore={props.handleMore}
            language={props.language}
          /> */}
        </div>
      </div>
    </Fragment>
  );
};

CommentsPage.propTypes = propTypes;

export default CommentsPage;
