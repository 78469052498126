import React, { Fragment } from "react";

import "./Login.css";
import login_graphic from "images/custom/login/login_graphic.png";
import TextInput from "components/shared/Inputs/TextInput";
import LoginPage from "components/Login/LoginPage";

import {
  ENABLE_EMAIL_LOGIN,
  ENABLE_LOGIN_TOPBAR,
  ENABLE_LOGIN_HOME,
  ENABLE_REGISTER_PAGES,
  PRIVACY_PAGE_LINK
} from "config";
import localize from "lang/localize";

class CyberwellnessLoginPage extends LoginPage {
  /* Custom: positioning and size of login button, forgot password link */
  renderLoginFields() {
    if (ENABLE_EMAIL_LOGIN) {
      return (
        <Fragment>
          <TextInput
            id="emailInput"
            value={this.props.email}
            onChange={this.props.handleEmailChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_email_placeholder_text",
              this.props.language
            )}
          />
          <TextInput
            id="passwordInput"
            type="password"
            value={this.props.password}
            onChange={this.props.handlePasswordChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_password_placeholder_text",
              this.props.language
            )}
          />
          {this.renderResetPasswordOrResendActivation()}
          {this.renderRecaptcha()}
          <div className="pure-g bottommargin-10">
            <div className="pure-u-1-1 flex justify-content-space-between align-items-center">
              <div className="pure-u-1-1 pure-u-md-1-2">
                <button
                  id="loginButton"
                  className={
                    "button lowercase fullwidth" +
                    (!!this.props.isSubmitLoading ? " disabled" : " cta")
                  }
                  type="submit"
                  disabled={!!this.props.isSubmitLoading}
                >
                  {!!this.props.isSubmitLoading
                    ? localize(
                        "nav_bar_title_generic_loading",
                        this.props.language
                      )
                    : localize("dbs_login_text", this.props.language)}
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  /* Custom: Fully custom layout */
  render() {
    if (
      /* checking type is important: showPrivacyAgreement is initialised as null */
      typeof this.props.showPrivacyAgreement === "boolean" &&
      this.props.showPrivacyAgreement === true
    ) {
      return (
        <div className="login-register-container container">
          {/* Fixed-positioned background for Screen */}
          <div className="background-cyberwellness"></div>

          {this.renderTopbarlessBackButton()}
          <div
            className={
              "pure-g text-white bottommargin-10" +
              (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
            }
          >
            <div className="pure-u-1-1 pure-u-md-1-24"></div>
            <div className="pure-u-1-1 pure-u-md-22-24">
              <h1 className="text-align-center">
                {localize("dbs_login_title", this.props.language)}
              </h1>
              <p className="text-align-center nomargin">
                {localize("dbs_login_subtitle", this.props.language)}
              </p>
            </div>
            <div className="pure-u-1-1 pure-u-md-1-24"></div>
          </div>
          <div className="innerblock pure-g">
            <div className="pure-u-md-4-24" />
            <div className="pure-u-1 pure-u-md-16-24">
              <div className="bg-white verticalpadding horizontalpadding box-sizing-border-box-all flex-from-md">
                <div className="pure-u-1-1 pure-u-md-1-2 horizontalpadding verticalpadding ">
                  <div className="pure-u-6-24 pure-u-md-1-1" />
                  <div className="pure-u-12-24 pure-u-md-1-1">
                    <img src={login_graphic} className="imgresponsive" alt="" />
                  </div>
                  <div className="pure-u-6-24 pure-u-md-1-1" />
                </div>
                <div className="pure-u-1-1 pure-u-md-1-2 flex-from-md align-items-center">
                  <div>
                    <h5>
                      {localize(
                        "login_view_privacy_policy_updated_title",
                        this.props.language
                      )}
                    </h5>
                    <p>
                      {localize(
                        "login_agree_to_new_terms_text",
                        this.props.language
                      )}
                    </p>
                    <div className="bottommargin-30">
                      <p className="smalltext">
                        <input
                          type="checkbox"
                          onChange={this.props.handleUpdatePrivacyAgreed}
                          checked={this.props.privacyAgreed}
                        />{" "}
                        {localize(
                          "register_agree_to_gametize_text",
                          this.props.language
                        )}
                        <a
                          className="link default"
                          href={PRIVACY_PAGE_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {localize(
                            "register_privacy_policy_text",
                            this.props.language
                          )}
                        </a>
                        .
                      </p>
                      <p className="smalltext">
                        <input
                          type="checkbox"
                          onChange={this.props.handleUpdateMarketingConsent}
                          checked={this.props.marketingConsent}
                        />{" "}
                        {localize(
                          "register_marketing_communications_text",
                          this.props.language
                        )}
                      </p>
                    </div>
                    <div className="pure-g">
                      <div className="pure-u-1-1 pure-u-md-1-2">
                        <button
                          className={
                            "button fullwidth " +
                            (this.props.privacyAgreed ? "cta" : "inactive")
                          }
                          onClick={this.props.handlePrivacySubmit}
                          disabled={!this.props.privacyAgreed}
                        >
                          {localize(
                            "button_quest_continue",
                            this.props.language
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pure-u-md-4-24" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="login-register-container container">
          {/* Fixed-positioned background for Screen */}
          <div className="background-cyberwellness"></div>

          {this.renderTopbarlessBackButton()}
          <div
            className={
              "pure-g text-white bottommargin-20" +
              (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
            }
          >
            <div className="pure-u-1-1 pure-u-md-1-24"></div>
            <div className="pure-u-1-1 pure-u-md-22-24">
              <h1 className="text-align-center">
                {localize("dbs_login_title", this.props.language)}
              </h1>
              <p className="text-align-center nomargin">
                {localize("dbs_login_subtitle", this.props.language)}
              </p>
            </div>
            <div className="pure-u-1-1 pure-u-md-1-24"></div>
          </div>
          <div className="innerblock pure-g">
            <div className="pure-u-md-4-24" />
            <div className="pure-u-1 pure-u-md-16-24">
              <div className="bg-white horizontalpadding box-sizing-border-box-all flex-from-md">
                <div className="pure-u-1-1 pure-u-md-1-2 horizontalpadding verticalpadding ">
                  <div className="pure-u-6-24 pure-u-md-1-1" />
                  <div className="pure-u-12-24 pure-u-md-1-1">
                    <img src={login_graphic} className="imgresponsive" alt="" />
                  </div>
                  <div className="pure-u-6-24 pure-u-md-1-1" />
                </div>
                <div className="pure-u-1-1 pure-u-md-1-2 flex-from-md align-items-center">
                  <div>
                    <h5 className="capitalize">
                      {localize("alert_login_text", this.props.language)}
                    </h5>
                    <form
                      className="pure-form"
                      onSubmit={this.handlePreSubmit.bind(this)}
                    >
                      {this.renderLoginFields()}
                      {this.renderGuestLoginSection()}
                      {this.renderSSOLoginSection()}
                      {ENABLE_REGISTER_PAGES && this.renderRegisterSection()}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="pure-u-md-4-24" />
          </div>
        </div>
      );
    }
  }
}

export default CyberwellnessLoginPage;
