import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import {
  PROJECT_PROFILE,
  PROJECT_TEAM,
  PROJECT_NOTIFICATIONS,
  PROJECT_INBOX,
  PROJECT_LOGIN_HOME,
  PROJECT_LOGIN,
  PROJECT_HOME
} from "App/Routes";
import "./MobileProject.css";
import swipe_icon from "images/core/project/swipe.png";
import placeholder_profile from "images/core/placeholders/default_avatar.png";
import placeholder_team from "images/core/placeholders/placeholder_team.jpg";
import placeholder_project from "images/core/placeholders/placeholder_project.png";
import SidebarLinks from "components/shared/SidebarLinks/SidebarLinks";
import Link from "components/shared/Link/Link";
import SearchBar from "components/shared/SearchBar/SearchBar";

import {
  SINGLE_PROJECT_APP,
  SET_PROJECT_BG_IMG,
  ENABLE_NOTIFICATIONS_PAGES,
  ENABLE_INBOX,
  ENABLE_LOGIN_HOME
} from "config";
import localize from "lang/localize";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const propTypes = {
  project: PropTypes.object.isRequired,
  projectId: PropTypes.number,
  projectImage: PropTypes.string,
  buttons: PropTypes.array,
  topics: PropTypes.array.isRequired,
  user: PropTypes.object,
  team: PropTypes.object,
  categoryId: PropTypes.string,
  notifications: PropTypes.number.isRequired,
  inboxUnread: PropTypes.number.isRequired,
  more: PropTypes.bool,
  randomNumber: PropTypes.number.isRequired,
  flavorTextArr: PropTypes.array.isRequired,
  showcaseProject: PropTypes.object,
  addAnchors: PropTypes.func,
  handleMore: PropTypes.func,
  handleOpenShareGameDialog: PropTypes.func.isRequired,
  renderTopics: PropTypes.func,
  language: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleSearchChange: PropTypes.func
};

const defaultProps = {
  projectImage: placeholder_project,
  projectId: null
};

class MobileProjectPage extends Component {
  constructor() {
    super();
    this.state = {
      currentSlide: 0
    };
  }
  /*
    react-slick's initialSlide does not pair well with React's re-renders.
    Thus, you want to use componentDidUpdate and slickGoTos for allowing
    the slider to jump to the first Topic card or the Project Title card
    in a Topic Category page.
  */
  componentDidUpdate(prevProps) {
    if (this.refs.slider && this.props.categoryId) {
      if (this.props.topics.length > 0) {
        this.refs.slider.slickGoTo(2);
      } else {
        this.refs.slider.slickGoTo(1);
      }
    }
  }

  setBackgroundImage() {
    if (this.props.project.backgroundImage && SET_PROJECT_BG_IMG) {
      let bgImgStyle;
      let projectBackgroundImage = this.props.project
        ? this.props.project.backgroundImage
        : "";

      bgImgStyle =
        ".project .mobile-project { " +
        "background-image: url('" +
        projectBackgroundImage +
        "'); " +
        "background-position: center center; " +
        "background-size: cover; " +
        "background-repeat: no-repeat; " +
        "}";

      return <style dangerouslySetInnerHTML={{ __html: `${bgImgStyle}` }} />;
    } else {
      return null;
    }
  }

  /*
    Note: Topic Category will jump to the first Topic on the list where available,
    otherwise it will jump to the Project title card. Topic Category jumping settings
    are in componentDidUpdate() (reasons specified there), which will override the
    initial slide settings here.
  */
  setMobileProjectInitialSlide() {
    /*
      Will not use parseInt() here first, because parseInt("123xyz", 10) === 123.
      Employing stricter controls by directly checking isNaN on the string.
    */
    let topicHashId =
      window.location.hash.indexOf("#topic") === 0
        ? window.location.hash.slice(6)
        : null;
    let slideIndex = 0;

    if (window.location.hash.indexOf("#topic") === 0) {
      topicHashId = window.location.hash.slice(6);
    } else if (window.location.hash.indexOf("#resettedtopic") === 0) {
      topicHashId = window.location.hash.slice(14);
    }

    if (isNaN(topicHashId) || !topicHashId || this.props.topics.length <= 0) {
      /* invalid topicHashId or no topics */
      return slideIndex;
    } else {
      /* slideIndex below will be undefined if topic id is not found */
      slideIndex = this.searchTopicIndexByTopicId(
        parseInt(topicHashId, 10),
        this.props.topics
      );

      if (typeof slideIndex !== "number") {
        /* can't find index */
        return 0;
      } else {
        /* add 2 for home slide and project title card slide */
        return slideIndex + 2;
      }
    }
  }

  searchTopicIndexByTopicId(topicIdKey, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === topicIdKey) {
        return i;
      }
    }
  }

  backToProjectHome() {
    this.refs.slider.slickGoTo(0);
  }

  renderSlider() {
    const settings = {
      dots: false,
      adaptiveHeight: false,
      infinite: false,
      speed: 100,
      swipeToSlide: true,
      centerMode: true,
      centerPadding: "30px",
      className: "topic-card-slider",
      initialSlide: this.setMobileProjectInitialSlide(),
      beforeChange: (oldIndex, newIndex) => {
        if (this.props.more && newIndex === this.props.topics.length + 1) {
          this.props.handleMore();
        }
      },
      afterChange: current => this.setState({ currentSlide: current })
    };

    /* isMobile is true */
    let sliderCards = this.props.renderTopics(this.props.topics, true);
    sliderCards.unshift(this.renderProjectTitle(this.props.project));
    sliderCards.unshift(this.renderMobileProjectHome());

    if (!this.props.more && !!this.props.showcaseProject) {
      sliderCards.push(this.renderProjectShowcaseCard());
    }

    return (
      <Slider ref="slider" {...settings}>
        {sliderCards}
      </Slider>
    );
  }

  renderMobileProjectHome() {
    let name = this.props.user ? this.props.user.name : "";
    let team = this.props.team;
    let teamImage = team && team.image ? team.image : placeholder_team;

    let points =
      this.props.user && this.props.user.points ? this.props.user.points : 0;

    return (
      <div
        key={"projecthome"}
        className="pure-u-g mobile-project-main toppadding-20"
      >
        <div className="swipe-to-nav">
          <img src={swipe_icon} alt="" />
          <div className="swipe-to-nav-text text-shadow">
            {localize("swipe_to_navigate", this.props.language)}
          </div>
        </div>
        {this.props.project && this.props.project.searchEnabled && (
          <SearchBar
            handleKeyPress={this.props.handleKeyPress}
            handleSearchChange={this.props.handleSearchChange}
            handleSubmit={this.props.handleSubmit}
            button={false}
            language={this.props.language}
          />
        )}
        <div className="mobile-project-sidebar">
          <div className="mobile-project-profile">
            <div className="pure-u-g mobile-project-profile-button-container">
              <div className="pure-u-7-24">
                {this.renderProfileImageLink(name)}
              </div>
              {this.renderMobileProjectInboxIcon()}
              {this.renderMobileProjectNotificationIcon()}
              {this.props.project &&
                this.props.project.teamEnabled &&
                team &&
                team.id && (
                  <div className="pure-u-4-24">
                    <Link
                      to={PROJECT_TEAM.format(this.props.projectId, team.id)}
                    >
                      <span className="square-image-wrapper">
                        <span className="square-image circle mobile-project-profile-button-border">
                          <img src={teamImage} alt="" />
                        </span>
                      </span>
                    </Link>
                  </div>
                )}
            </div>
            <div className="mobile-project-profile-link text-truncated">
              {this.renderProfileNameLink(name)}
            </div>
            <div className="mobile-project-profile-link text-truncated">
              {this.renderProfilePointsLink(points)}
            </div>
          </div>
          <SidebarLinks
            projectId={this.props.project.id}
            showTeams={this.props.project.teamEnabled}
            buttons={this.props.buttons}
            language={this.props.language}
          />
        </div>
      </div>
    );
  }

  renderProfileImageLink(name) {
    let photoSmall = this.props.user
      ? this.props.user.photoSmall
      : placeholder_profile;

    if (this.props.user && this.props.user.id) {
      return (
        <Link to={PROJECT_PROFILE.format(this.props.projectId)}>
          <span className="square-image-wrapper">
            <span className="square-image circle mobile-project-profile-button-border">
              <img src={photoSmall} alt={name} />
            </span>
          </span>
        </Link>
      );
    } else {
      return (
        <span className="square-image-wrapper">
          <span className="square-image circle mobile-project-profile-button-border">
            <img
              src={placeholder_profile}
              alt={localize("require_login_view_profile", this.props.language)}
            />
          </span>
        </span>
      );
    }
  }

  renderProfileNameLink(name) {
    if (this.props.user && this.props.user.id) {
      return (
        <Link
          to={PROJECT_PROFILE.format(this.props.projectId)}
          className="text-shadow"
        >
          {name}
        </Link>
      );
    } else {
      return (
        <span className="text-shadow">
          {localize("require_login_view_profile", this.props.language)}
        </span>
      );
    }
  }

  renderProfilePointsLink(points) {
    if (this.props.user && this.props.user.id) {
      return (
        <Link
          to={PROJECT_PROFILE.format(this.props.projectId)}
          className="text-shadow"
        >
          <strong className="mobile-project-profile-points-text">
            {points.abbreviateNumber() + " "}
          </strong>
          <span className="mobile-project-points-text">
            {localize("points_just_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return this.renderProfileLoginButton();
    }
  }

  renderProfileLoginButton() {
    return (
      <Link
        to={
          ENABLE_LOGIN_HOME
            ? PROJECT_LOGIN_HOME.format(this.props.projectId)
            : PROJECT_LOGIN.format(this.props.projectId)
        }
        className="topmargin-5 button button-login inline-block"
      >
        {localize("button_login", this.props.language)}
      </Link>
    );
  }

  renderMobileProjectInboxIcon() {
    if (ENABLE_INBOX && this.props.user && this.props.user.id) {
      return (
        <div className="pure-u-4-24">
          <Link
            to={PROJECT_INBOX.format(this.props.projectId)}
            className="mobile-project-profile-button mobile-project-profile-button-border"
          >
            <i className="fas fa-comments" aria-hidden="true" />
            {this.props.inboxUnread && this.props.inboxUnread > 0 ? (
              <div className="notification-dot" id="unreadInboxDot" />
            ) : null}
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  renderMobileProjectNotificationIcon() {
    if (this.props.user && this.props.user.id && ENABLE_NOTIFICATIONS_PAGES) {
      return (
        <div className="pure-u-4-24">
          <Link
            to={PROJECT_NOTIFICATIONS.format(this.props.projectId)}
            className="mobile-project-profile-button mobile-project-profile-button-border"
          >
            <i className="fas fa-bell" aria-hidden="true" />
            {this.props.notifications && this.props.notifications > 0 ? (
              <div className="notification-dot" id="unreadNotificationDot" />
            ) : null}
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  renderProjectTitle(project) {
    let anchoredProjectTitle = this.props.addAnchors(project.title);
    let anchoredProjectDescription = this.props.addAnchors(project.description);
    let projectImage = this.props.projectImage;

    return (
      <div className="pure-u-1" key={"projectittle"}>
        <div className="card project-title-card project-title-card-bg">
          <div className="card-height">
            <div className="inner-card">
              {this.renderProjectShareButton()}
              <img
                className="circle iconlarge imgcenter padding"
                src={projectImage}
                alt={project.title}
              />
              <h4 dangerouslySetInnerHTML={{ __html: anchoredProjectTitle }} />
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: anchoredProjectDescription }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderProjectShareButton() {
    if (this.props.project && this.props.project.projectShareLink) {
      return (
        <button
          className="button icon info inline"
          onClick={this.props.handleOpenShareGameDialog}
        >
          <i className="fas fa-share-alt"></i>
        </button>
      );
    } else {
      return null;
    }
  }

  renderProjectShowcaseCard() {
    let flavorText = this.props.flavorTextArr[this.props.randomNumber];
    let showcaseImage = this.props.showcaseProject.showcaseImage;
    let showcaseTitle = this.props.showcaseProject.showcaseTagline;
    let showcaseDescription = this.props.showcaseProject.showcaseDescription;

    return (
      <div className="pure-u-1" key={"projectshowcase"}>
        <div className="card featured-project-card featured-project-card-bg">
          <div className="card-height">
            <div className="inner-card">
              <h2 className="topmargin-10 text-pink-gametize">{flavorText}</h2>
              <img
                className="circle iconlarge imgcenter padding"
                src={showcaseImage}
                alt={showcaseTitle}
              />
              <h4 dangerouslySetInnerHTML={{ __html: showcaseTitle }} />
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: showcaseDescription }}
              />
            </div>
            {this.renderProjectShowcaseLink()}
          </div>
        </div>
      </div>
    );
  }

  renderProjectShowcaseLink() {
    let showcaseProjectId = this.props.showcaseProject.projectId;

    /*
      We do not allow routing to different projects on single-project apps.
      Thus, open a new window to the app.gametize.com project instead
    */
    if (SINGLE_PROJECT_APP) {
      return (
        <a
          className="link"
          href={"https://app.gametize.com/project/" + showcaseProjectId}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="card-action">
            <strong className="card-action-text lighttext">
              {localize("showcase_try_now", this.props.language)}
            </strong>
          </div>
        </a>
      );
    } else {
      return (
        <Link to={PROJECT_HOME.format(showcaseProjectId)}>
          <div className="card-action">
            <strong className="card-action-text lighttext">
              {localize("showcase_try_now", this.props.language)}
            </strong>
          </div>
        </Link>
      );
    }
  }

  renderProjectHomeButton() {
    return (
      <div className="project-home-button">
        <div className="mobile-project-list-icon-wrapper">
          <div className="pure-u-g">
            <div className="pure-u-5-24 pure-u-md-4-24">
              <button
                className="button icon floating inline"
                onClick={() => this.backToProjectHome()}
              >
                <i className="fas fa-angle-double-left"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.setBackgroundImage()}
        {this.renderSlider()}
        {this.state.currentSlide >= 2 ? this.renderProjectHomeButton() : null}
      </Fragment>
    );
  }
}

MobileProjectPage.propTypes = propTypes;
MobileProjectPage.defaultProps = defaultProps;

export default MobileProjectPage;
