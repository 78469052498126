import React, { Component } from "react";
import PropTypes from "prop-types";

import "./ProfileDrawer.css";
import placeholder_profile from "images/core/placeholders/default_avatar.png";
import {
  PROJECT_PROFILE,
  PROJECT_INBOX,
  PROJECT_NOTIFICATIONS,
  PROJECT_LOGIN_HOME,
  PROJECT_LOGIN
} from "App/Routes";
import Link from "components/shared/Link/Link";

import { ENABLE_LOGIN_HOME, ENABLE_NOTIFICATIONS_PAGES } from "config";
import localize from "lang/localize";

const propTypes = {
  user: PropTypes.object.isRequired,
  projectId: PropTypes.number,
  project: PropTypes.object,
  notifications: PropTypes.number.isRequired,
  inboxUnread: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  singleProjectApp: PropTypes.bool.isRequired,
  enableInbox: PropTypes.bool.isRequired,
  language: PropTypes.string
};

class ProfileDrawer extends Component {
  renderInboxIcon() {
    if (this.props.enableInbox) {
      return (
        <Link
          to={PROJECT_INBOX.format(this.props.projectId)}
          className="drawer-notifications"
        >
          <i className="fas fa-comments" aria-hidden="true" />
          {this.props.inboxUnread && this.props.inboxUnread > 0 ? (
            <div className="notification-dot" id="unreadInboxDot" />
          ) : null}
        </Link>
      );
    } else {
      return null;
    }
  }

  renderNotificationsIcon() {
    if (ENABLE_NOTIFICATIONS_PAGES) {
      return (
        <Link
          to={PROJECT_NOTIFICATIONS.format(this.props.projectId)}
          className="drawer-notifications"
        >
          <i className="fas fa-bell" aria-hidden="true" />
          {this.props.notifications && this.props.notifications > 0 ? (
            <div className="notification-dot" id="unreadNotificationDot" />
          ) : null}
        </Link>
      );
    } else {
      return null;
    }
  }

  render() {
    let photoSmall = this.props.user ? this.props.user.photoSmall : "";
    let name = this.props.user ? this.props.user.name : "";
    let projectInformationExists =
      this.props.project && Object.entries(this.props.project).length > 0;

    /* Logged in, and Project-based page */
    if (
      this.props.loggedIn &&
      this.props.projectId &&
      projectInformationExists
    ) {
      return (
        <div className="profile-drawer">
          <div className="drawer-profile-wrapper drawer-profile-wrapper-bg">
            <div className="drawer-profile-container">
              <Link
                to={PROJECT_PROFILE.format(this.props.projectId)}
                className="drawer-profile-link"
                onClick={() => this.props.handleCloseDrawer()}
              >
                {this.props.user.userRankStatusImage && (
                  <div id="rankStatus" className="photo-icon-wrap">
                    <img
                      className="rank-status s-rank-status"
                      src={this.props.user.userRankStatusImage}
                      alt={this.props.user.userRankStatus}
                    />
                  </div>
                )}
                <img
                  className="drawer-profile-img drawer-profile-img-border"
                  src={photoSmall}
                  alt={name}
                />
                <div className="drawer-profile">
                  <div className="drawer-profile-name text-truncated">
                    {name}
                  </div>
                  <div className="drawer-profile-open-points">
                    {localize("points_text", this.props.language).format(
                      this.props.points.abbreviateNumber()
                    )}
                  </div>
                </div>
              </Link>
              {this.renderInboxIcon()}
              {this.renderNotificationsIcon()}
            </div>
          </div>
        </div>
      );
    } else if (this.props.projectId && projectInformationExists) {
      /* Not logged in, but Project-based page */
      return (
        <div className="profile-drawer drawerOpen notLoggedIn ">
          <div className="drawer-profile-wrapper drawer-profile-wrapper-bg">
            <div className="drawer-profile-container">
              <img
                className="drawer-profile-img drawer-profile-img-border"
                src={placeholder_profile}
                alt={localize(
                  "require_login_view_profile",
                  this.props.language
                )}
              />
              <div className="drawer-profile">
                <div className="drawer-profile-name text-truncated">
                  {localize("require_login_view_profile", this.props.language)}
                </div>
                <Link
                  className="button button-login inline"
                  to={
                    ENABLE_LOGIN_HOME
                      ? PROJECT_LOGIN_HOME.format(this.props.projectId)
                      : PROJECT_LOGIN.format(this.props.projectId)
                  }
                >
                  {localize("button_login", this.props.language)}
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      /* Not logged in, not Project-based page */
      return null;
    }
  }
}

ProfileDrawer.propTypes = propTypes;

export default ProfileDrawer;
