import React from "react";

import { CHALLENGE } from "App/Routes";
import ClaimForm from "components/Challenge/ClaimForm/ClaimForm";
import Link from "components/shared/Link/Link";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import StandardChallenge from "components/Challenge/ClaimForm/StandardChallenge";
import VideoChallenge from "components/Challenge/ClaimForm/VideoChallenge";
import QuizChallenge from "./QuizChallenge";
import FixedChallenge from "components/Challenge/ClaimForm/FixedChallenge";
import MultiFieldChallenge from "components/Challenge/ClaimForm/MultiFieldChallenge";
import QRChallenge from "components/Challenge/ClaimForm/QRChallenge";
import ExternalChallenge from "components/Challenge/ClaimForm/ExternalChallenge";

import localize from "lang/localize";

class CyberwellnessClaimForm extends ClaimForm {
  /*
    Custom: removed .container, .innerblock,
    use .toppadding instead of .verticalpadding,
    all grids set to pure-u-1-1
  */
  switchClaim() {
    switch (this.props.challengeTypeId) {
      case 1: // standard
        return (
          <div className="toppadding">
            <div className="challenge-page-divider" />
            <div className="toppadding bottompadding" id="claimform">
              <div id="claim">
                <div className="pure-u-1-1">
                  <StandardChallenge
                    handleSubmit={this.props.postClaim}
                    photoRequired={this.props.photoRequired}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 5: // video
        return (
          <div className="toppadding">
            <div className="challenge-page-divider" />
            <div className="toppadding bottompadding" id="claimform">
              <div id="claim">
                <div className="pure-u-1-1">
                  <VideoChallenge
                    handleSubmit={this.props.postClaim}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    videoDurationLimit={this.props.videoDurationLimit}
                    videoDurationLimitFormatted={
                      this.props.videoDurationLimitFormatted
                    }
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 2: // quiz
      case 3: // prediction
      case 10: // quiz-repeat
      case 11: // survey or confirmation
      case 14: // personality quiz
        return (
          <div className="toppadding">
            <div className="challenge-page-divider" />
            <div className="toppadding bottompadding" id="claimform">
              <div id="claim">
                <div className="pure-u-1-1">
                  <QuizChallenge
                    isConfirmationChallenge={this.props.isConfirmationChallenge}
                    options={this.props.quizOptions}
                    handleSubmit={this.props.postClaim}
                    multiSelect={this.props.multiSelect}
                    gridWidth={this.props.gridWidth}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 9: // fixed answer
        return (
          <div className="toppadding">
            <div className="challenge-page-divider" />
            <div className="toppadding bottompadding" id="claimform">
              <div id="claim">
                <div className="pure-u-1-1">
                  <FixedChallenge
                    handleSubmit={this.props.postClaim}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 13: // multi-field
        return (
          <div className="toppadding">
            <div className="challenge-page-divider" />
            <div className="toppadding bottompadding" id="claimform">
              <div id="claim">
                <div className="pure-u-1-1">
                  <MultiFieldChallenge
                    fields={this.props.fields}
                    handleSubmit={this.props.postClaim}
                    photoRequired={this.props.photoRequired}
                    noPhoto={this.props.noPhoto}
                    showAlertWithTimeout={this.props.showAlertWithTimeout}
                    submitted={this.props.submitted}
                    isLoggedIn={this.props.isLoggedIn}
                    handleOpenClaimLoginDialog={
                      this.props.handleOpenClaimLoginDialog
                    }
                    language={this.props.language}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 7: // qr code
        return (
          <div className="toppadding bottompadding" id="claimform">
            <div id="claim">
              <div className="pure-u-1-1">
                <QRChallenge
                  handleSubmit={this.props.postQRClaim}
                  isLoggedIn={this.props.isLoggedIn}
                  handleOpenClaimLoginDialog={
                    this.props.handleOpenClaimLoginDialog
                  }
                  showQRModal={this.props.showQRModal}
                  handleOpenQRModal={this.props.handleOpenQRModal}
                  handleCloseQRModal={this.props.handleCloseQRModal}
                  language={this.props.language}
                />
              </div>
            </div>
          </div>
        );
      case 15: // external
        return (
          <div className="toppadding">
            <div className="challenge-page-divider" />
            <div className="toppadding" id="claimform">
              <div id="claim">
                <button
                  className={
                    "button automargin " +
                    (this.props.claimed ? "inactive" : "cta")
                  }
                  onClick={this.props.handleOpenExternalChallengeDialog}
                >
                  {this.props.claimed &&
                    localize("button_minigame_completed", this.props.language)}
                  {!this.props.claimed &&
                    this.props.claimedBefore &&
                    localize("button_minigame_play_again", this.props.language)}
                  {!(this.props.claimed || this.props.claimedBefore) &&
                    localize("button_minigame_play", this.props.language)}
                </button>
              </div>
            </div>
            {this.props.showExternalChallengeDialog && (
              <ExternalChallenge
                sessionKey={this.props.sessionKey}
                challengeId={this.props.challengeId}
                title={this.props.title}
                externalEmbeddable={this.props.externalEmbeddable}
                showDialog={this.props.showExternalChallengeDialog}
                handleOpenDialog={this.props.handleOpenExternalChallengeDialog}
                handleCloseDialog={
                  this.props.handleCloseExternalChallengeDialog
                }
              />
            )}
          </div>
        );
      case 4: // Flashcard
        if (this.props.nextChallengeId) {
          return (
            <div className="toppadding">
              <div className="challenge-page-divider" />
              <div className="toppadding bottompadding" id="claimform">
                <div id="claim">
                  <div className="pure-u-8-24" />
                  <div className="pure-u-8-24">
                    <Link
                      className="button cta"
                      to={CHALLENGE.format(this.props.nextChallengeId)}
                    >
                      {localize(
                        "button_feedback_view_next_challenge",
                        this.props.language
                      )}
                    </Link>
                  </div>
                  <div className="pure-u-8-24" />
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      default:
        return null;
    }
  }

  /* Custom: removed .challenge-page-divider, .innerblock */
  /*
    Custom: removed .challenge-page-divider,
    .container, and .innerblock,
    use .toppadding instead of .verticalpadding.
  */

  renderNotLoggedIn() {
    // add challenges to list which has challenge previews without logging in
    let filteredChallengeArr = [];
    filteredChallengeArr.push(1); // standard/photo
    filteredChallengeArr.push(2); // quiz
    filteredChallengeArr.push(3); // prediction
    filteredChallengeArr.push(4); // flashcard
    filteredChallengeArr.push(5); // video
    filteredChallengeArr.push(7); // QR
    filteredChallengeArr.push(9); // passcode/fixed answer
    filteredChallengeArr.push(10); // repeatable quiz
    filteredChallengeArr.push(11); // poll
    filteredChallengeArr.push(13); // multi-field/custom form
    filteredChallengeArr.push(14); // personality quiz
    filteredChallengeArr.push(15); // external

    // checks if not logged in, and if challenge type id is not part of filtered challenge array list
    if (
      !this.props.isLoggedIn &&
      filteredChallengeArr.indexOf(this.props.challengeTypeId) === -1
    ) {
      return (
        <div className="toppadding" id="claimform">
          <div id="notLoggedIn">
            <div className="pure-u-2-24" />
            <div className="pure-u-20-24">
              <NotLoggedInContainer
                showLogin={false}
                message={localize(
                  "require_login_complete_challenge",
                  this.props.language
                )}
                language={this.props.language}
              />
            </div>
            <div className="pure-u-2-24" />
          </div>
        </div>
      );
    }
  }

  /*
    Custom: added .challenge-page-divider, used 1 column grid,
    added .toppadding div as sibling after .challenge-page-divider
  */

  renderClaimedBefore() {
    if (this.props.claimedBefore && this.props.completionMessage) {
      return (
        <div className="toppadding">
          <div className="challenge-page-divider" />
          <div className="toppadding" id="completed">
            <div className="pure-u-1-1">
              {this.renderCompletionMessage(this.props.completionMessage)}
            </div>
          </div>
        </div>
      );
    }
  }

  renderExpired() {
    if (this.props.expired && !this.props.claimed) {
      return (
        <div className="toppadding">
          <div className="challenge-page-divider" />
          <div className="toppadding" id="expired">
            <div className="pure-u-1-1">
              {localize("challenge_profile_expired_text", this.props.language)}
            </div>
          </div>
        </div>
      );
    }
  }

  renderAdminClaim() {
    if (this.props.adminToClaim && !this.props.claimed) {
      return (
        <div className="toppadding">
          <div className="challenge-page-divider" />
          <div className="toppadding" id="adminCompletion">
            <div className="pure-u-1-1">
              {localize("only_admin_can_claim_text", this.props.language)}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CyberwellnessClaimForm;
