import React from "react";
import { connect } from "react-redux";

import { LOGIN, LOGIN_HOME } from "App/Routes";
import { HomeContainer } from "components/Home/HomeContainer";
import HomePage from "./HomePage";

import { ENABLE_LOGIN_HOME, PROJECT_ID, LOAD_MORE_COUNT } from "config";
import { setProject, setButtons, setTopicCategory, setLanguage } from "actions";
import { GET_HOME, GET_TOPICS } from "services/api";
import Router from "router";
import localStorageService from "services/localStorageService";
import getApiGenerator from "services/getApiGenerator";

import reduceLanguages from "lang/reduceLanguages";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    user: state.user,
    /* Custom: include project id */
    projectId: state.projectId || PROJECT_ID,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopicCategory: topicCategory => {
      dispatch(setTopicCategory(topicCategory));
    },
    setLanguage: language => {
      dispatch(setLanguage(language));
    }
  };
};

export class CyberwellnessHomeContainer extends HomeContainer {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    if (this.props.sessionKey === null) {
      if (ENABLE_LOGIN_HOME) {
        Router.replaceAndNavigate(LOGIN_HOME);
      } else {
        Router.replaceAndNavigate(LOGIN);
      }

      Router.check();
    }

    this.setFinalMinHeight();
    this.getHome();

    /* Initialize buttons and project information */
    this.getTopics(1);
  }

  componentWillUnmount() {
    this.resetFinalMinHeight();
  }

  setFinalMinHeight() {
    let minHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    document.documentElement.style.minHeight = minHeight + "px";
  }

  resetFinalMinHeight() {
    if (document.documentElement.style.removeProperty) {
      document.documentElement.style.removeProperty("min-height");
    } else {
      document.documentElement.style.removeAttribute("min-height");
    }
  }

  getHome() {
    getApiGenerator(GET_HOME, {}, this.props.sessionKey).end((err, res) => {
      /* no error: renders only up to first 2 flashcards */
      if (!err && res.body.code === 200) {
        if (
          res.body.userLanguage &&
          typeof res.body.userLanguage === "string"
        ) {
          let reducedLanguage = reduceLanguages(
            res.body.userLanguage.toLowerCase()
          );

          localStorageService.setItem("language", reducedLanguage);
          this.props.setLanguage(reducedLanguage);
        }

        this.setState({
          data: res.body.data ? [res.body.data[0], res.body.data[1]] : []
        });
      }
    });
  }

  getTopics(page, category_id = this.props.id || "", limit = LOAD_MORE_COUNT) {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: page,
        category_id: category_id,
        limit: limit
      },
      this.props.sessionKey
    ).end((err, res) => {
      /* No error */
      if (!err && res.body.code === 200) {
        const categories = res.body.categories
          ? res.body.categories.map(category => ({
              id: category.id,
              title: category.name,
              selected: category.selected,
              description: category.description,
              image: category.image
            }))
          : null;

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        let compiledProject = categories
          ? Object.assign({}, res.body.game, { categories: categories })
          : res.body.game;

        this.props.setProject(compiledProject);
        this.props.setButtons(BUTTONS);

        if (categories) {
          this.props.setTopicCategory(
            categories.filter(category => category.selected === true)[0]
          );
        }
      }
    });
  }

  render() {
    return <HomePage data={this.state.data} language={this.props.language} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessHomeContainer);
