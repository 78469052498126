import React, { Component } from "react";
import PropTypes from "prop-types";

import TextboxInput from "components/shared/Inputs/TextboxInput";
import NumberInput from "components/shared/Inputs/NumberInput";
import DecimalInput from "components/shared/Inputs/DecimalInput";
import SelectInput from "components/shared/Inputs/SelectInput";
import ImageInput from "components/shared/Inputs/ImageInput";
import DateInput from "components/shared/Inputs/DateInput/DateInput";
import DurationInput from "components/shared/Inputs/DurationInput";

/* For handling truncation of long options character counts in SelectInput */
import ButtonGroupInput from "components/shared/Inputs/ButtonGroupInput";

import localize from "lang/localize";

const propTypes = {
  fields: PropTypes.array.isRequired,
  photoRequired: PropTypes.bool,
  noPhoto: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  submitted: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  handleOpenClaimLoginDialog: PropTypes.func.isRequired,
  language: PropTypes.string
};

const defaultProps = {
  photoRequired: false
};

class MultiFieldChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: props.fields,
      image: null,
      imagename: null
    };

    this.onMFChangeDate = this.onMFChangeDate.bind(this);
    this.onMFChangeDuration = this.onMFChangeDuration.bind(this);
  }

  onMFChange(event) {
    const index = parseInt(event.currentTarget.name, 10);
    let fields = this.state.fields.slice();
    fields[index].value = event.currentTarget.value;
    this.setState({ fields: fields });
  }

  onMFChangeDate(date, index) {
    let fields = this.state.fields.slice();
    fields[index].value = date ? date.format("DD MMM YYYY") : null;
    this.setState({ fields: fields });
  }

  onMFChangeDuration(index, seconds) {
    let fields = this.state.fields.slice();
    fields[index].value = seconds;
    this.setState({ fields: fields });
  }

  onMFChangeButtonOptions(index, id) {
    let fields = this.state.fields.slice();
    fields[index].value = id.toString();
    this.setState({ fields: fields });
  }

  renderFields() {
    const fields = this.state.fields;
    return fields.map((field, index) => {
      let fieldTitle = field.title;

      switch (field.type) {
        case "TEXT":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <TextboxInput
                name={index}
                onChange={this.onMFChange.bind(this)}
                placeholder={localize(
                  "comment_placeholder_text",
                  this.props.language
                )}
                readOnly={!this.props.isLoggedIn}
                onClick={
                  !this.props.isLoggedIn
                    ? this.handleOpenClaimLoginDialog.bind(this)
                    : () => {}
                }
                className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
              />
            </div>
          );
        case "INTEGER":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <NumberInput
                name={index}
                onChange={this.onMFChange.bind(this)}
                placeholder={localize(
                  "claim_number_placeholder",
                  this.props.language
                )}
                readOnly={!this.props.isLoggedIn}
                onClick={
                  !this.props.isLoggedIn
                    ? this.handleOpenClaimLoginDialog.bind(this)
                    : () => {}
                }
                className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
              />
            </div>
          );
        case "FLOAT":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <DecimalInput
                name={index}
                onChange={this.onMFChange.bind(this)}
                placeholder={localize(
                  "claim_number_placeholder",
                  this.props.language
                )}
                readOnly={!this.props.isLoggedIn}
                onClick={
                  !this.props.isLoggedIn
                    ? this.handleOpenClaimLoginDialog.bind(this)
                    : () => {}
                }
                className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
              />
            </div>
          );
        case "CALENDAR":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <div>
                <DateInput
                  onMFChangeDate={this.onMFChangeDate}
                  keyIndex={index}
                  field={this.state.fields[index]}
                  disabled={!this.props.isLoggedIn}
                  handleDisabledClick={this.handleOpenClaimLoginDialog.bind(
                    this
                  )}
                  language={this.props.language}
                />
              </div>
            </div>
          );
        case "OPTION":
          // saves id as value
          let optionsNo = field.options.length;
          let longestCharCount = field.options.reduce(function(
            accumulator,
            currentValue
          ) {
            return Math.max(accumulator, currentValue.title.length);
          },
          0);

          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <div>
                {this.renderOptions(optionsNo, longestCharCount, field, index)}
              </div>
            </div>
          );
        case "DURATION":
          return (
            <div key={index} className="custom-form-row">
              <label>{fieldTitle}</label>
              <div>
                <DurationInput
                  name={index}
                  onMFChangeDuration={this.onMFChangeDuration}
                  disabled={!this.props.isLoggedIn}
                  handleDisabledClick={this.handleOpenClaimLoginDialog.bind(
                    this
                  )}
                />
              </div>
            </div>
          );
        default:
          return null;
      }
    });
  }

  onImageChange(image) {
    if (image[0]) {
      this.setState({
        image: image[0],
        imagename: image[0].name
      });
    }
  }

  onImageReset() {
    this.setState({
      image: null,
      imagename: null
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let fields = this.state.fields.map(field => ({
      field_id: field.id,
      field_entry: field.value || ""
    }));
    this.props.handleSubmit(
      { entries: JSON.stringify(fields) },
      this.state.image,
      this.state.imagename
    );
    return true;
  }

  handleOpenClaimLoginDialog(event) {
    event.preventDefault();

    this.props.handleOpenClaimLoginDialog();
  }

  renderOptions(optionsNo, longestCharCount, field, index) {
    if (optionsNo <= 5 && longestCharCount > 20) {
      return (
        <ButtonGroupInput
          options={field.options}
          index={index}
          onMFChangeButtonOptions={this.onMFChangeButtonOptions.bind(this)}
          disabled={!this.props.isLoggedIn}
          handleDisabledClick={this.handleOpenClaimLoginDialog.bind(this)}
        />
      );
    } else {
      if (this.props.isLoggedIn) {
        return (
          <SelectInput
            name={index}
            options={field.options}
            onChange={this.onMFChange.bind(this)}
            placeholder={localize(
              "claim_multiple_choice_placeholder",
              this.props.language
            )}
            disabled={!this.props.isLoggedIn}
          />
        );
      } else {
        return (
          <div className="disable-check-input-container">
            <div
              className="input-disabled-overlay cursor-not-allowed"
              onClick={this.props.handleOpenClaimLoginDialog}
            />
            <SelectInput
              name={index}
              options={field.options}
              onChange={this.onMFChange.bind(this)}
              placeholder={localize(
                "claim_multiple_choice_placeholder",
                this.props.language
              )}
              disabled={!this.props.isLoggedIn}
            />
          </div>
        );
      }
    }
  }

  render() {
    if (this.props.noPhoto) {
      return (
        <form className="pure-form">
          <fieldset>
            <div className="pure-u-1-1 pure-u-md-24-24">
              {this.renderFields()}
            </div>
            {this.props.isLoggedIn ? (
              <button
                className={
                  "button automargin " +
                  (this.props.submitted ? "inactive" : "cta")
                }
                onClick={this.handleSubmit.bind(this)}
                disabled={this.props.submitted}
                id="submitCompletionButton"
              >
                {this.props.submitted
                  ? localize("button_claim_submitting", this.props.language)
                  : localize("button_claim_get_points", this.props.language)}
              </button>
            ) : (
              <button
                className={
                  "button automargin " +
                  (this.props.submitted ? "inactive" : "cta")
                }
                onClick={this.handleOpenClaimLoginDialog.bind(this)}
                disabled={this.props.submitted}
                id="loginBeforeSubmitCompletionButton"
              >
                {this.props.submitted
                  ? localize("button_claim_submitting", this.props.language)
                  : localize("button_claim_get_points", this.props.language)}
              </button>
            )}
          </fieldset>
        </form>
      );
    } else {
      return (
        <div className="pure-form">
          {/*
              Set the above back to <form className="pure-form">
              if we are no longer using <input type="file">
              for image inputs
          */}
          <fieldset>
            <div className="pure-u-1-1 pure-u-md-15-24">
              {this.renderFields()}
            </div>
            <div className="pure-u-1-1 pure-u-md-1-24" />
            <div className="pure-u-1-1 pure-u-md-8-24">
              <ImageInput
                imagename={this.state.imagename}
                onDrop={this.onImageChange.bind(this)}
                resetFiles={this.onImageReset.bind(this)}
                showAlertWithTimeout={this.props.showAlertWithTimeout}
                disabled={!this.props.isLoggedIn}
                handleDisabledClick={this.handleOpenClaimLoginDialog.bind(this)}
                language={this.props.language}
              />
            </div>
            {this.props.isLoggedIn ? (
              <button
                className={
                  "button automargin " +
                  (this.props.submitted ||
                  (this.props.photoRequired && this.state.image == null)
                    ? "inactive"
                    : "cta")
                }
                onClick={this.handleSubmit.bind(this)}
                disabled={
                  this.props.submitted ||
                  (this.props.photoRequired && this.state.image == null)
                }
                id="submitCompletionButton"
              >
                {this.props.submitted
                  ? localize("button_claim_submitting", this.props.language)
                  : localize("button_claim_get_points", this.props.language)}
              </button>
            ) : (
              <button
                className={
                  "button automargin " +
                  (this.props.submitted ||
                  (this.props.photoRequired && this.state.image == null)
                    ? "inactive"
                    : "cta")
                }
                onClick={this.handleOpenClaimLoginDialog.bind(this)}
                disabled={
                  this.props.submitted ||
                  (this.props.photoRequired && this.state.image == null)
                }
                id="loginBeforeSubmitCompletionButton"
              >
                {this.props.submitted
                  ? localize("button_claim_submitting", this.props.language)
                  : localize("button_claim_get_points", this.props.language)}
              </button>
            )}
          </fieldset>
          {/*
            Set the below back to </form> if we are
            no longer using <input type="file"> for
            image inputs
        */}
        </div>
      );
    }
  }
}

MultiFieldChallenge.propTypes = propTypes;
MultiFieldChallenge.defaultProps = defaultProps;

export default MultiFieldChallenge;
