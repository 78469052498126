import React, { Component } from "react";
import { connect } from "react-redux";

import Breadcrumbs from "custom-components/shared/Breadcrumbs/Breadcrumbs";

export const mapStateToProps = (state, ownProps) => {
  return {
    state: state.topbar.state,
    projectId: state.projectId,
    project: state.topbar.project,
    topic: state.topic,
    challenge: state.topbar.challenge,
    language: state.language
  };
};

export class BreadcrumbsContainer extends Component {
  render() {
    return (
      <Breadcrumbs
        state={this.props.state}
        projectId={this.props.projectId}
        project={this.props.project}
        topic={this.props.topic}
        challenge={this.props.challenge}
        language={this.props.language}
      />
    );
  }
}

export default connect(mapStateToProps)(BreadcrumbsContainer);
