import React from "react";

import SurveyResult from "components/Challenge/ChallengeResult/SurveyResult";

class CyberwellnessSurveyResult extends SurveyResult {
  /*
    Custom: remove parent <Fragment>, remove .innerblock in <div>.
  */
  render() {
    return (
      <div className="verticalpadding">
        {this.props.quizOptions.map((option, index) => (
          <div
            className="pure-g survey-result flex align-items-center"
            key={index}
          >
            <div className="pure-u-4-24 flex justify-content-center relative">
              {this.renderResultIcons(option)}
            </div>
            <div className="pure-u-18-24">
              {this.renderResultOptionBar(option)}
            </div>
            <div className="pure-u-2-24" />
          </div>
        ))}
      </div>
    );
  }
}

export default CyberwellnessSurveyResult;
