import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import placeholder_profile from "images/core/placeholders/default_avatar.png";
import placeholder_team from "images/core/placeholders/placeholder_team.jpg";
import {
  PROJECT_TEAM_INVITE,
  TEAM_INVITE,
  PROJECT_TEAM_UPDATE,
  TEAM_UPDATE
} from "App/Routes";
import Link from "components/shared/Link/Link";

import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

import localize from "lang/localize";

const propTypes = {
  projectId: PropTypes.number,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  joined: PropTypes.bool.isRequired,
  teamCreator: PropTypes.bool.isRequired,
  teamCreateEnabled: PropTypes.bool.isRequired,
  teamJoinEnabled: PropTypes.bool.isRequired,
  teamJoinedInProject: PropTypes.bool.isRequired,
  user: PropTypes.object,
  members: PropTypes.array.isRequired,
  points: PropTypes.number.isRequired,
  handleJoinTeam: PropTypes.func.isRequired,
  handleLeaveTeam: PropTypes.func.isRequired,
  language: PropTypes.string
};
// should use default pic

class Team extends Component {
  renderMenuDropdownButton() {
    const SHOW_JOIN_TEAM =
      this.props.teamJoinEnabled && !this.props.teamJoinedInProject;
    const SHOW_LEAVE_TEAM = this.props.teamJoinEnabled && this.props.joined;
    const SHOW_UPDATE_TEAM =
      this.props.teamCreateEnabled && this.props.teamCreator;

    if (SHOW_JOIN_TEAM || SHOW_LEAVE_TEAM || SHOW_UPDATE_TEAM) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            onSelect={(eventKey, event) => {
              event.preventDefault();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={e => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>{this.renderMenuDropdownLinks()}</Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  renderMenuDropdownLinks() {
    if (this.props.joined) {
      return (
        <Fragment>
          {this.renderMenuDropdownUpdateLink()}
          <Link
            to={
              !!this.props.projectId
                ? PROJECT_TEAM_INVITE.format(
                    this.props.projectId,
                    this.props.id
                  )
                : TEAM_INVITE.format(this.props.id)
            }
          >
            {localize("invite_players_text", this.props.language)}
          </Link>
          <MenuItem
            eventKey={1}
            onSelect={(eventKey, event) => {
              this.props.handleLeaveTeam(event);
            }}
          >
            {localize("leave_team", this.props.language)}
          </MenuItem>
        </Fragment>
      );
    } else {
      return (
        <MenuItem
          eventKey={2}
          onSelect={(eventKey, event) => {
            this.props.handleJoinTeam(event);
          }}
        >
          {localize("join_team", this.props.language)}
        </MenuItem>
      );
    }
  }

  renderMenuDropdownUpdateLink() {
    if (this.props.teamCreateEnabled && this.props.teamCreator) {
      return (
        <Link
          to={
            !!this.props.projectId
              ? PROJECT_TEAM_UPDATE.format(this.props.projectId, this.props.id)
              : TEAM_UPDATE.format(this.props.id)
          }
        >
          {localize("update_team_text", this.props.language)}
        </Link>
      );
    } else {
      return null;
    }
  }

  renderUserProfileImage() {
    if (
      !!this.props.joined &&
      !!this.props.user &&
      !!this.props.user.photoLarge
    ) {
      return (
        <span className="square-image-wrapper team-profile-user-image">
          <span className="square-image circle border-gray-light">
            <img src={this.props.user.photoLarge} alt={this.props.user.name} />
          </span>
        </span>
      );
    } else if (!!this.props.joined) {
      return (
        <span className="square-image-wrapper team-profile-user-image">
          <span className="square-image circle border-gray-light">
            <img src={placeholder_profile} alt="" />
          </span>
        </span>
      );
    }
  }

  renderDescription() {
    if (this.props.description !== "") {
      return (
        <div className="topmargin">
          <p className="text-align-center nomargin">
            <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
          </p>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    let image = this.props.image || placeholder_team;
    let name = this.props.name;

    return (
      <div>
        <div className="hide-from-md pure-g team-container">
          <div className="team-profile-button-image-container flex">
            {this.renderUserProfileImage()}
            {this.renderMenuDropdownButton()}
          </div>
          <div className="pure-u-3-8 flex flex-direction-column justify-content-center align-items-center">
            <p className="nomargin">
              <strong>{this.props.points.abbreviateNumber()}</strong>
            </p>
            <p className="nomargin">
              {localize("points_just_text", this.props.language)}
            </p>
          </div>
          <div className="pure-u-1-4">
            <span className="square-image-wrapper">
              <span className="square-image circle border-gray-light">
                <img src={image} alt={name} />
              </span>
            </span>
          </div>
          <div className="pure-u-3-8" />
          <div className="pure-u-1-1">
            <h2 className="team-name topmargin-5">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </h2>
            {this.renderDescription()}
          </div>
        </div>

        <div className="hide-below-md pure-g team-container">
          <div className="pure-u-md-4-24 flex align-items-center" />
          <div className="pure-u-md-4-24 flex align-items-center">
            <span className="square-image-wrapper fullwidth">
              <span className="square-image circle border-gray-light">
                <img src={image} alt={name} />
              </span>
            </span>
          </div>
          <div className="pure-u-md-12-24 horizontalpadding flex align-items-center justify-content-center relative">
            <div className="textcenter">
              <h2 className="team-name">
                <div dangerouslySetInnerHTML={{ __html: name }} />
              </h2>
              {this.renderDescription()}
              <div className="topmargin">
                <p className="text-align-center nomargin">
                  <strong>{this.props.points.abbreviateNumber() + " "}</strong>
                  {localize("points_just_text", this.props.language)}
                </p>
              </div>
            </div>
          </div>
          <div className="pure-u-md-4-24 flex align-items-center">
            {this.renderUserProfileImage()}
            {this.renderMenuDropdownButton()}
          </div>
        </div>
      </div>
    );
  }
}

Team.propTypes = propTypes;

export default Team;
