import React from "react";
import { connect } from "react-redux";

import { PROJECT_HOME, HOME } from "App/Routes";
import {
  mapStateToProps,
  mapDispatchToProps,
  TopicContainer
} from "components/Topic/TopicContainer";
import TopicPage from "./TopicPage";
import sessionStorageService from "services/sessionStorageService";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { LOAD_MORE_COUNT } from "config";
import { GET_CHALLENGES } from "services/api";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

export class CyberwellnessTopicContainer extends TopicContainer {
  /* Custom: inclusion of topicIdNext into this.state.topic */
  getChallenges(page) {
    getApiGenerator(
      GET_CHALLENGES.format(this.state.id),
      {
        category_id: this.props.categoryId,
        page: page,
        limit: LOAD_MORE_COUNT
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body && res.body.game) {
          this.setState(
            {
              challenges: [],
              errorProject: res.body.game,
              isLoadingMore: false
            },
            () => {
              this.props.setProject(this.state.errorProject);
            }
          );
        } else {
          this.setState({ challenges: [], isLoadingMore: false });
        }
      } else {
        const CATEGORIES = res.body.categories
          ? res.body.categories.map(category => ({
              id: category.id,
              title: category.name,
              selected: category.selected,
              description: category.description,
              image: category.image
            }))
          : null;

        const TOPIC_ID_NEXT = res.body.topicIdNext || null;

        const challenges = this.state.challenges
          ? this.state.challenges.slice()
          : [];

        let compiledTopic = CATEGORIES
          ? Object.assign({}, res.body.quest, { categories: CATEGORIES })
          : res.body.quest;

        compiledTopic = Object.assign({}, compiledTopic, {
          topicIdNext: TOPIC_ID_NEXT
        });

        this.setState({
          project: res.body.game,
          topic: compiledTopic,
          achievements: res.body.wins ? res.body.wins : [],
          challenges: challenges.concat(res.body.data),
          more: res.body.more,
          page: this.state.page + 1,
          contactEmail: res.body.game.contactEmail,
          topicIdNext: res.body.topicIdNext,
          isLoadingMore: false
        });
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        this.props.setTopic(compiledTopic);

        if (CATEGORIES) {
          this.props.setChallengeCategory(
            CATEGORIES.filter(category => category.selected === true)[0]
          );
        }
      }
    });
  }

  render() {
    const UNAVAILABLE_TOPIC_ERROR_LIST = [
      localize("unavailable_topic_locked_private", this.props.language),
      localize("unavailable_topic_private_project", this.props.language),
      localize("unavailable_topic_unpublished", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language),
      localize("unavailable_topic_not_exist", this.props.language)
    ];
    const UNAVAILABLE_TOPIC_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_topic_locked_private_loggedin",
        this.props.language
      ),
      localize(
        "unavailable_topic_private_project_loggedin",
        this.props.language
      ),
      localize("unavailable_topic_unpublished", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language),
      localize("unavailable_topic_not_exist", this.props.language)
    ];
    const UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST = [
      localize("unavailable_topic_locked_private", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language)
    ];
    const UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_topic_locked_private_loggedin",
        this.props.language
      ),
      localize("unavailable_topic_no_challenges", this.props.language)
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    if (this.state.challenges && this.state.challenges.length !== 0) {
      return (
        <TopicPage
          isLoadingMore={this.state.isLoadingMore}
          topicIdNext={this.state.topicIdNext}
          sessionKey={this.props.sessionKey}
          project={this.state.project}
          challenges={this.state.challenges}
          categoryId={this.props.categoryId}
          more={this.state.more}
          achievements={this.state.achievements}
          handleMore={this.handleMore}
          handleBookmark={this.handleBookmark.bind(this)}
          handleLike={this.handleLike.bind(this)}
          totalChallenges={this.state.topic.challengeNo}
          completedChallenges={this.state.topic.userChallengeCompletedNo}
          personality={this.state.topic.tag}
          allowReset={this.state.topic.allowReset || false}
          isSearch={false}
          showTopicChallengesCompleted={this.state.showTopicChallengesCompleted}
          showTopicAchievements={this.state.showTopicAchievements}
          topicTitle={this.state.topic.title}
          topicId={this.state.topic.id}
          showBookmarkLoginDialog={this.state.showBookmarkLoginDialog}
          handleOpenBookmarkLoginDialog={this.handleOpenBookmarkLoginDialog}
          handleCloseBookmarkLoginDialog={this.handleCloseBookmarkLoginDialog}
          showLikeLoginDialog={this.state.showLikeLoginDialog}
          handleOpenLikeLoginDialog={this.handleOpenLikeLoginDialog}
          handleCloseLikeLoginDialog={this.handleCloseLikeLoginDialog}
          showResetActivityDialog={this.state.showResetActivityDialog}
          handleOpenResetActivityDialog={this.handleOpenResetActivityDialog}
          handleCloseResetActivityDialog={this.handleCloseResetActivityDialog}
          isLoggedIn={!!this.props.user.id}
          language={this.props.language}
          contactEmail={this.state.contactEmail}
          /* Custom: topic description */
          topicDescription={this.state.topic.description}
        />
      );
    } else if (
      this.state.challenges &&
      this.state.errorProject &&
      !this.props.sessionKey
    ) {
      /* Project information is returned, but not logged in */
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST}
          endMessage={localize(
            "unavailable_topic_only_login_prompt",
            this.props.language
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges && this.state.errorProject) {
      /* Project information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={PROJECT_HOME.format(this.state.errorProject.id)}
          routeName={"“" + this.state.errorProject.title + "”"}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST_LOGGEDIN}
          endMessage={localize(
            "unavailable_topic_return_project_prompt",
            this.props.language
          ).format(this.state.errorProject.title)}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges && !this.props.sessionKey) {
      /* No information is returned, but not logged in */
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_ERROR_LIST}
          endMessage={localize(
            "unavailable_topic_login_prompt",
            this.props.language
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges) {
      /* No information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessTopicContainer);
