import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import placeholder_team from "images/core/placeholders/placeholder_team.jpg";

import LeaderboardPlayer from "./LeaderboardPlayer";
import SelectInput from "components/shared/Inputs/SelectInput";
import leaderboard_dots from "images/core/styling/leaderboard_dots.png";

import Loading from "components/shared/Loading";

import localize from "lang/localize";

const propTypes = {
  projectId: PropTypes.number.isRequired,
  mainBoard: PropTypes.array,
  userBoard: PropTypes.array,
  topicLeaderboards: PropTypes.array,
  type: PropTypes.oneOf(["overall", "monthly", "daily"]).isRequired,
  board: PropTypes.oneOf(["individual", "team"]).isRequired,
  teamAvailable: PropTypes.bool.isRequired,
  handleToggleType: PropTypes.func.isRequired,
  handleToggleBoard: PropTypes.func.isRequired,
  userId: PropTypes.number,
  teamId: PropTypes.number,
  indivReqStatus: PropTypes.array.isRequired,
  teamReqStatus: PropTypes.array.isRequired,
  language: PropTypes.string
};

class LeaderboardPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("leaderboardPage")) {
      bodyDOM.className += " leaderboardPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("leaderboardPage")) {
      bodyDOM.classList.remove("leaderboardPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderTimeButtons() {
    return (
      <div className="textcenter">
        <button
          id="overall"
          className={
            "button inline toggle " +
            (this.props.type === "overall" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleType}
        >
          <span>
            {localize("score_summary_segment_all_time", this.props.language)}
          </span>
        </button>
        <button
          id="monthly"
          className={
            "button inline toggle " +
            (this.props.type === "monthly" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleType}
        >
          <span>
            {localize("score_summary_segment_monthly", this.props.language)}
          </span>
        </button>
        <button
          id="daily"
          className={
            "button inline toggle " +
            (this.props.type === "daily" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleType}
        >
          <span>
            {localize("score_summary_segment_daily", this.props.language)}
          </span>
        </button>
      </div>
    );
  }

  renderTopicToggle() {
    if (
      this.props.topicLeaderboards &&
      Array.isArray(this.props.topicLeaderboards) &&
      this.props.topicLeaderboards.length > 0
    ) {
      return (
        <form className="pure-form flex justify-content-center">
          <SelectInput
            name="Hello"
            className="select-text-align-center"
            options={this.props.topicLeaderboards}
            onChange={this.props.handleToggleTopic}
            placeholder={localize("category_all_text", this.props.language)}
          />
        </form>
      );
    } else {
      return null;
    }
  }

  renderBoardNav() {
    return (
      <nav className="nav-buttons">
        <button
          id="team"
          className={
            "button " + (this.props.board === "team" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleBoard}
        >
          <span>
            {localize("icon_leaderboard_mode_team", this.props.language)}
          </span>
        </button>
        <button
          id="individual"
          className={
            "button " +
            (this.props.board === "individual" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleBoard}
        >
          <span>
            {localize("icon_leaderboard_mode_user", this.props.language)}
          </span>
        </button>
      </nav>
    );
  }

  renderEmpty() {
    return (
      <div id="empty" className="textcenter verticalpadding">
        {localize("empty_score_table_text", this.props.language)}
      </div>
    );
  }

  renderBoard(board) {
    return (
      <div className="toppadding">
        {board.map((player, index) => (
          <LeaderboardPlayer
            key={player.id}
            id={player.id}
            projectId={this.props.projectId}
            userId={this.props.userId}
            rank={player.rank}
            userRankStatus={player.userRankStatus}
            userRankStatusImage={player.userRankStatusImage}
            name={player.name}
            points={player.points}
            image={
              player.photoLarge ||
              player.image ||
              (this.props.board === "team" ? placeholder_team : undefined)
            }
            self={
              this.props.board === "individual"
                ? player.id === this.props.userId
                : player.id === this.props.teamId
            }
            isTeam={this.props.board === "team"}
            language={this.props.language}
          />
        ))}
      </div>
    );
  }

  renderMainBoard(empty) {
    return this.renderBoard(this.props.mainBoard);
  }

  renderUserBoard(userBoard) {
    if (userBoard) {
      return (
        <div>
          <img className="pure-img automargin" src={leaderboard_dots} alt="" />
          {this.renderBoard(userBoard)}
        </div>
      );
    } else {
      return null;
    }
  }

  renderBoards(empty) {
    if (empty) {
      return this.renderEmpty();
    } else if (this.props.mainBoard) {
      return (
        <Fragment>
          {this.renderMainBoard()}
          {this.renderUserBoard(this.props.userBoard)}
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }

  render() {
    /* empty only if API call has been made, and main board is an array of at least 1 item */
    let empty =
      this.props.mainBoard &&
      Array.isArray(this.props.mainBoard) &&
      this.props.mainBoard.length === 0;

    return (
      <React.Fragment>
        {/*
          If teams are available, show Team/Player as tabs.
          Otherwise, show Time (All-Time / Monthly / Daily).
        */}
        {this.props.teamAvailable && this.renderBoardNav()}
        <div className="leaderboard container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1 pure-u-md-4-24" />
            <div className="pure-u-1 pure-u-md-16-24">
              {/*
                When teams are available, Team/Player are the navtabs.
                Use Time (All-Time, Monthly, Daily) as buttons.
              */}
              {this.renderTimeButtons()}
              {this.renderTopicToggle()}
              {this.renderBoards(empty)}
            </div>
            <div className="pure-u-1 pure-u-md-4-24" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

LeaderboardPage.propTypes = propTypes;

export default LeaderboardPage;
