import React, { Component } from "react";
import PropTypes from "prop-types";
import Achievement from "./Achievement";

const propTypes = {
  items: PropTypes.array.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number
};

class AchievementsPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("achievementsPage")) {
      bodyDOM.className += " achievementsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("achievementsPage")) {
      bodyDOM.classList.remove("achievementsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderItems() {
    const items = this.props.items;
    return items.map((item, index) => (
      <Achievement
        key={item.id}
        id={item.id}
        title={item.name}
        img={item.imageSmall}
        earned={item.earned}
        language={this.props.language}
        projectId={this.props.projectId}
      />
    ));
  }

  render() {
    return (
      <div className="container verticalpadding bottompadding-floating">
        <div className="innerblock pure-g">
          <div className="pure-u-1 pure-u-md-16-24 automargin">
            {this.renderItems()}
          </div>
        </div>
      </div>
    );
  }
}

AchievementsPage.propTypes = propTypes;

export default AchievementsPage;
