import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { TOPIC } from "App/Routes";
import placeholder_topic from "images/core/placeholders/placeholder_topic.jpg";
import Link from "components/shared/Link/Link";

import { configureAnchors } from "react-scrollable-anchor";

import localize from "lang/localize";

const propTypes = {
  sessionKey: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  id: PropTypes.number,
  locked: PropTypes.bool,
  expired: PropTypes.bool,
  started: PropTypes.bool,
  completed: PropTypes.bool,
  totalChallenges: PropTypes.number,
  language: PropTypes.string
};

const defaultProps = {
  img: placeholder_topic
};

/*
  Offset accounts for topbar (50px).
  Scroll duration at 1 ms more or less removes the animation
*/
configureAnchors({ offset: -50, scrollDuration: 1 });

class TopicRow extends Component {
  renderLockedOrExpiredIcon() {
    if (this.props.locked) {
      return <i className="fas fa-lock" />;
    } else {
      return <i className="fas fa-clock" />;
    }
  }

  renderExpiredOrLockedLabel() {
    if (this.props.locked) {
      return (
        <div className="topmargin">
          <div className="display-inline-block badge status-badge">
            {localize("button_quest_locked", this.props.language)}
          </div>
        </div>
      );
    } else if (this.props.expired) {
      return (
        <div className="topmargin">
          <div className="display-inline-block badge status-badge">
            {localize(
              "button_challenge_challenge_expired",
              this.props.language
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderClickableArea() {
    if (!this.props.locked && !this.props.expired) {
      return (
        <Link
          to={TOPIC.format(this.props.id)}
          className="topic-row pure-g horizontalpadding"
        >
          <div className="pure-u-2-24 pure-u-sm-1-24 relative">
            <div className="progress-line" />
            <div className="progress-dot" />
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-5-24 pure-u-sm-3-24 pure-u-md-2-24 verticalpadding flex align-items-center">
            <span className="square-image-wrapper fullwidth">
              <span className="square-image">
                <img src={this.props.img} alt={this.props.title} />
              </span>
            </span>
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-15-24 pure-u-sm-18-24 pure-u-md-19-24 verticalpadding">
            <h4>{this.props.title}</h4>
            <div
              className="text-white"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
            {this.renderActionButton()}
            {this.renderExpiredOrLockedLabel()}
          </div>
          <div className="pure-u-1-24" />
        </Link>
      );
    } else {
      return (
        <div className="topic-row pure-g horizontalpadding">
          <div className="pure-u-2-24 pure-u-sm-1-24 relative">
            <div className="progress-line" />
            <div className="progress-dot" />
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-5-24 pure-u-sm-3-24 pure-u-md-2-24 verticalpadding flex align-items-center">
            <span className="square-image-wrapper fullwidth">
              <span className="square-image-wrapper-overlay">
                {this.renderLockedOrExpiredIcon()}
              </span>
              <span className="square-image">
                <img src={this.props.img} alt={this.props.title} />
              </span>
            </span>
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-15-24 pure-u-sm-18-24 pure-u-md-19-24 verticalpadding">
            <h4>{this.props.title}</h4>
            <div
              className="text-white"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
            {this.renderExpiredOrLockedLabel()}
          </div>
          <div className="pure-u-1-24" />
        </div>
      );
    }
  }

  renderActionButton() {
    if (
      !this.props.locked &&
      !this.props.expired &&
      (!this.props.completed || this.props.totalChallenges === 0)
    ) {
      return (
        <div className="button cta medium topmargin topic-begin-button">
          <strong className="button-text lighttext">
            {this.getActionButtonText()}
          </strong>
        </div>
      );
    }
  }

  getActionButtonText() {
    if (!this.props.started) {
      return localize("button_quest_start", this.props.language);
    } else {
      return localize("button_quest_continue", this.props.language);
    }
  }

  render() {
    return (
      <Fragment>
        {/* Custom: Empty span just so we can stack scrollable anchors */}
        <div id={"resettedtopic" + this.props.id}>
          <span></span>
        </div>
        {/* Custom: Empty span just so we can stack scrollable anchors */}
        <div id={"topic" + this.props.id}>
          <span></span>
        </div>
        {this.renderClickableArea()}
      </Fragment>
    );
  }
}

TopicRow.propTypes = propTypes;
TopicRow.defaultProps = defaultProps;

export default TopicRow;
