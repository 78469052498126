import React, { Component } from "react";
import PropTypes from "prop-types";

import "./StandardChallenge.css";
import TextboxInput from "components/shared/Inputs/TextboxInput";
import ImageInput from "components/shared/Inputs/ImageInput";

import localize from "lang/localize";

const propTypes = {
  photoRequired: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  submitted: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  handleOpenClaimLoginDialog: PropTypes.func.isRequired,
  language: PropTypes.string
};

const defaultProps = {
  photoRequired: false
};

class StandardChallenge extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
      imagename: null,
      image: null
    };
  }

  onTextChange(event) {
    this.setState({ text: event.target.value });
  }

  onImageChange(image) {
    if (image[0]) {
      this.setState({
        image: image[0],
        imagename: image[0].name
      });
    }
  }

  onImageReset() {
    this.setState({
      image: null,
      imagename: null
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.handleSubmit(
      { comment: this.state.text },
      this.state.image,
      this.state.imagename
    );
  }

  handleOpenClaimLoginDialog(event) {
    event.preventDefault();

    this.props.handleOpenClaimLoginDialog();
  }

  render() {
    return (
      <React.Fragment>
        <div className="pure-form">
          {/*
              Set the above back to <form className="pure-form">
              if we are no longer using <input type="file">
              for image inputs
          */}
          <div className="pure-u-1-1 pure-u-md-16-24 align-fields-to-image-input">
            <TextboxInput
              placeholder={localize(
                "comment_placeholder_text",
                this.props.language
              )}
              onChange={this.onTextChange.bind(this)}
              readOnly={!this.props.isLoggedIn}
              onClick={
                !this.props.isLoggedIn
                  ? this.handleOpenClaimLoginDialog.bind(this)
                  : () => {}
              }
              className={!this.props.isLoggedIn ? "cursor-not-allowed" : ""}
            />
          </div>
          <div className="pure-u-1-1 pure-u-md-8-24">
            <ImageInput
              imagename={this.state.imagename}
              onDrop={this.onImageChange.bind(this)}
              resetFiles={this.onImageReset.bind(this)}
              showAlertWithTimeout={this.props.showAlertWithTimeout}
              disabled={!this.props.isLoggedIn}
              handleDisabledClick={this.handleOpenClaimLoginDialog.bind(this)}
              language={this.props.language}
            />
          </div>
        </div>
        <div>
          {this.props.isLoggedIn ? (
            <button
              className={
                "button automargin " +
                (this.props.submitted ||
                (this.props.photoRequired && this.state.image == null)
                  ? "inactive"
                  : "cta")
              }
              onClick={this.handleSubmit.bind(this)}
              disabled={
                this.props.submitted ||
                (this.props.photoRequired && this.state.image == null)
              }
              id="submitCompletionButton"
            >
              {this.props.submitted
                ? localize("button_claim_submitting", this.props.language)
                : localize("button_claim_get_points", this.props.language)}
            </button>
          ) : (
            <button
              className={
                "button automargin " +
                (this.props.submitted ||
                (this.props.photoRequired && this.state.image == null)
                  ? "inactive"
                  : "cta")
              }
              onClick={this.handleOpenClaimLoginDialog.bind(this)}
              disabled={
                this.props.submitted ||
                (this.props.photoRequired && this.state.image == null)
              }
              id="loginBeforeSubmitCompletionButton"
            >
              {this.props.submitted
                ? localize("button_claim_submitting", this.props.language)
                : localize("button_claim_get_points", this.props.language)}
            </button>
          )}
          {/*
            Set the below back to </form> if we are
            no longer using <input type="file"> for
            image inputs
          */}
        </div>
      </React.Fragment>
    );
  }
}

StandardChallenge.propTypes = propTypes;
StandardChallenge.defaultProps = defaultProps;

export default StandardChallenge;
