import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  LOGIN,
  PROJECT_LOGIN,
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
  LOGIN_TOUR,
  PROJECT_HOME,
  PROJECT_SETTINGS,
  SETTINGS,
  PROJECT_SEARCH_CHALLENGES,
  PROJECT_SELECT_CATEGORY,
  TOPIC_SELECT_CATEGORY_NO_ID,
  PROJECT_SHARE,
  PROJECT_REFER_A_FRIEND
} from "App/Routes";
/* Custom: import from custom-components */
// import LogoutContainer from "components/Login/LogoutContainer";
import LogoutContainer from "custom-components/Login/LogoutContainer";
import Link from "components/shared/Link/Link";

import { slide as Menu } from "react-burger-menu";

import {
  SHOW_CHALLENGE_CATEGORIES,
  SHOW_TOPIC_CATEGORIES,
  ENABLE_TOUR,
  ENABLE_LOGIN_HOME,
  ENABLE_SETTINGS_PAGES
} from "config";
import localize from "lang/localize";
import urlServices from "services/urlServices";
import { SINGLE_PROJECT_APP } from "../../../config";

const propTypes = {
  showMenu: PropTypes.bool.isRequired,
  handleShowMenu: PropTypes.func.isRequired,
  handleHideMenu: PropTypes.func.isRequired,
  state: PropTypes.string,
  loggedIn: PropTypes.bool,
  projectId: PropTypes.number,
  project: PropTypes.object,
  topic: PropTypes.object,
  topicsList: PropTypes.array,
  challenge: PropTypes.object,
  handleOpenSearchChallengeDialog: PropTypes.func,
  handleOpenSelectTopicCategoryDialog: PropTypes.func,
  handleOpenTopicsListDialog: PropTypes.func,
  handleOpenTourModal: PropTypes.func,
  handleOpenShareGameDialog: PropTypes.func,
  handleRefresh: PropTypes.func.isRequired,
  language: PropTypes.string,
  isMobileProjectPage: PropTypes.bool
};

class TopbarMenu extends Component {
  setStyles() {
    return {
      bmItem: {
        display: "flex",
        alignItems: "center"
      },
      bmCrossButton: {
        position: "absolute",
        top: "1.5em",
        right: "1.5em"
      }
    };
  }

  showSettings(event) {
    event.preventDefault();
  }

  toggleMenu(state) {
    if (!state.isOpen) {
      return this.handleHideMenu();
    }
  }

  renderTopicsListItem(showTopicsList) {
    if (showTopicsList) {
      return (
        <Link
          to={urlServices.defaultPath()}
          className="menu-item link"
          onClick={e => {
            this.props.handleHideMenu();
            this.props.handleOpenTopicsListDialog(e);
          }}
        >
          <i className="fas fa-list" />
          <span>
            {localize("nav_bar_title_games_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return null;
    }
  }

  renderTopicCategoriesItem(showTopicCategories) {
    if (SHOW_TOPIC_CATEGORIES && showTopicCategories) {
      return (
        <a
          className="menu-item link"
          onClick={e => {
            this.props.handleHideMenu();
            this.props.handleOpenSelectTopicCategoryDialog(e);
          }}
          href={PROJECT_SELECT_CATEGORY.format(this.props.projectId)}
        >
          <i className="fas fa-layer-group" />
          <span>{localize("icon_categories", this.props.language)}</span>
        </a>
      );
    } else {
      return null;
    }
  }

  renderChallengeCategoriesItem(showChallengeCategories) {
    if (SHOW_CHALLENGE_CATEGORIES && showChallengeCategories) {
      return (
        <a
          className="menu-item link"
          onClick={e => {
            this.props.handleHideMenu();
            this.props.handleOpenSelectChallengeCategoryDialog(e);
          }}
          href={TOPIC_SELECT_CATEGORY_NO_ID}
        >
          <i className="fas fa-layer-group" />
          <span>{localize("icon_categories", this.props.language)}</span>
        </a>
      );
    } else {
      return null;
    }
  }

  renderSearchChallengesItem(showSearchChallenges) {
    if (showSearchChallenges && this.props.loggedIn) {
      return (
        <a
          className="menu-item link"
          onClick={e => {
            this.props.handleHideMenu();
            this.props.handleOpenSearchChallengeDialog(e);
          }}
          href={PROJECT_SEARCH_CHALLENGES.format(this.props.projectId)}
        >
          <i className="fas fa-search" />
          <span>
            {localize("general_search_placeholder_text", this.props.language)}
          </span>
        </a>
      );
    } else {
      return null;
    }
  }

  renderShareGameItem(showShareGame) {
    if (showShareGame) {
      return (
        <a
          className="menu-item link"
          onClick={e => {
            this.props.handleHideMenu();
            this.props.handleOpenShareGameDialog(e);
          }}
          href={PROJECT_SHARE.format(this.props.projectId)}
        >
          <i className="fas fa-share-alt" />
          <span>{localize("share_text", this.props.language)}</span>
        </a>
      );
    } else {
      return null;
    }
  }

  renderReferAFriendItem(showReferAFriend) {
    if (showReferAFriend) {
      return (
        <Link
          to={PROJECT_REFER_A_FRIEND.format(this.props.projectId)}
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-user-plus rightmargin-5" />
          <span>{localize("project_referral_text", this.props.language)}</span>
        </Link>
      );
    } else {
      return null;
    }
  }

  renderBackToProjectItem(showBackToProject, projectTitle) {
    if (showBackToProject && this.props.loggedIn) {
      return (
        <Link
          to={PROJECT_HOME.format(this.props.projectId)}
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-arrow-alt-circle-left" />
          <span className="back-to-link">
            {SINGLE_PROJECT_APP
              ? localize("icon_home", this.props.language)
              : projectTitle}
          </span>
        </Link>
      );
    } else {
      return null;
    }
  }

  renderDivider(showDivider) {
    if (showDivider && this.props.loggedIn) {
      return <div className="menu-divider" />;
    } else {
      return null;
    }
  }

  renderRefreshItem() {
    return (
      <a
        className="menu-item link"
        onClick={e => {
          this.props.handleHideMenu();
          this.props.handleRefresh(e);
        }}
        href={urlServices.defaultPath()}
      >
        <i className="fas fa-redo" />
        <span>{localize("refresh_placeholder_text", this.props.language)}</span>
      </a>
    );
  }

  renderSettingsItem() {
    if (this.props.loggedIn && ENABLE_SETTINGS_PAGES) {
      return (
        <Link
          to={
            this.props.projectId
              ? PROJECT_SETTINGS.format(this.props.projectId)
              : SETTINGS
          }
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-cog" />
          <span>
            {localize("nav_bar_title_settings_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return null;
    }
  }

  renderAboutTourItem() {
    if (this.props.state !== "login-tour" && ENABLE_TOUR) {
      return (
        <a
          className="menu-item link"
          onClick={e => {
            this.props.handleHideMenu();
            this.props.handleOpenTourModal(e);
          }}
          href={LOGIN_TOUR}
        >
          <i className="fas fa-info-circle" />
          <span>{localize("icon_tour", this.props.language)}</span>
        </a>
      );
    } else {
      return null;
    }
  }

  renderLogoutItem() {
    if (this.props.loggedIn) {
      return (
        <LogoutContainer
          className="menu-item logout-link"
          handleHideMenu={this.props.handleHideMenu}
        >
          <i className="fas fa-sign-out-alt" />
          <span>{localize("icon_logout", this.props.language)}</span>
        </LogoutContainer>
      );
    } else {
      return null;
    }
  }

  getLoginRoute() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  renderLoginItem() {
    if (!this.props.loggedIn) {
      return (
        <Link
          to={this.getLoginRoute()}
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-sign-in-alt" />
          <span>
            {localize("nav_bar_title_login_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return null;
    }
  }

  render() {
    let showChallengeCategories =
      (this.props.state === "topic" ||
        this.props.state === "topic-comments-thread" ||
        this.props.state === "challenge-category") &&
      this.props.topic &&
      Array.isArray(this.props.topic.categories) &&
      this.props.topic.categories.length > 0;

    let showFirstDivider = SHOW_CHALLENGE_CATEGORIES
      ? showChallengeCategories
      : false;

    let showTopicCategories =
      (this.props.state === "projecthome" ||
        this.props.state === "home-single" ||
        this.props.state === "topic-category") &&
      this.props.project &&
      Array.isArray(this.props.project.categories) &&
      this.props.project.categories.length > 0;

    let showTopicsList =
      this.props.project &&
      Array.isArray(this.props.topicsList) &&
      this.props.topicsList.length > 0;

    let showSearchChallenges =
      this.props.project &&
      this.props.project.challengeSearchEnabled &&
      !this.props.isMobileProjectPage === true;

    let showShareGame =
      this.props.project &&
      this.props.project.projectShareLink &&
      this.props.projectId;

    let showReferAFriend =
      this.props.project &&
      this.props.project.projectReferralLink &&
      (this.props.state === "projecthome" ||
        this.props.state === "home-single" ||
        this.props.state === "topic-category" ||
        this.props.state === "project" ||
        this.props.state === "topic" ||
        this.props.state === "topic-comments-thread" ||
        this.props.state === "challenge" ||
        this.props.state === "challenge-category" ||
        this.props.state === "challenge-comments" ||
        this.props.state === "challenge-comments-thread" ||
        this.props.state === "challenge-subpage" ||
        this.props.state === "claim-comments" ||
        this.props.state === "claim-comments-thread");

    /* The pages below (this.props.state) have a route to original Project */
    let showBackToProject =
      this.props.projectId &&
      (this.props.state === "topic" ||
        this.props.state === "topic-comments-thread" ||
        this.props.state === "challenge" ||
        this.props.state === "project" ||
        this.props.state === "topic-category" ||
        this.props.state === "challenge-category" ||
        this.props.state === "challenge-comments" ||
        this.props.state === "challenge-comments-thread" ||
        this.props.state === "challenge-subpage" ||
        this.props.state === "claim-comments" ||
        this.props.state === "claim-comments-thread" ||
        this.props.state === "login-tour");

    let showSecondDivider = SHOW_TOPIC_CATEGORIES
      ? showSearchChallenges ||
        showShareGame ||
        showBackToProject ||
        showTopicCategories
      : showSearchChallenges || showShareGame || showBackToProject;

    let projectTitle = this.props.project ? this.props.project.title : "";

    return (
      <Menu
        isOpen={this.props.showMenu}
        handleHideMenu={this.props.handleHideMenu}
        onStateChange={this.toggleMenu}
        styles={this.setStyles()}
        right
      >
        {/* You should be able to select categories without logging in */}
        {this.renderChallengeCategoriesItem(showChallengeCategories)}
        {this.renderDivider(showFirstDivider)}
        {this.renderTopicCategoriesItem(showTopicCategories)}
        {this.renderTopicsListItem(showTopicsList)}
        {/* Logged in links */}
        {this.renderSearchChallengesItem(showSearchChallenges)}
        {this.renderShareGameItem(showShareGame)}
        {this.renderReferAFriendItem(showReferAFriend)}
        {this.renderBackToProjectItem(showBackToProject, projectTitle)}
        {this.renderDivider(showSecondDivider)}
        {this.renderRefreshItem()}
        {this.renderSettingsItem()}
        {this.renderAboutTourItem()}
        {this.renderLogoutItem()}
        {/* Logged out links */}
        {this.renderLoginItem()}
      </Menu>
    );
  }
}

TopbarMenu.propTypes = propTypes;

export default TopbarMenu;
