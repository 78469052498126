import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import TeamsPage from "./TeamsPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { LOAD_MORE_COUNT } from "config";
import { GET_TEAMS, GET_TOPICS } from "services/api";
import { setProject, setButtons } from "actions";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    user: state.user,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    }
  };
};

export class TeamsContainer extends Component {
  constructor() {
    super();
    this.state = {
      teams: null,
      more: false,
      page: 1,
      searchInput: "",
      beganSearch: false,
      topics: null,
      error: null,
      code: null,
      teamCreateEnabled: false,
      teamCreatedInProject: false,
      isLoadingMore: false
    };
    this.handleMore = this.handleMore.bind(this);
    this.getTeams = this.getTeams.bind(this);
  }

  componentDidMount() {
    /* Retrieve Project information */
    if (this.props.projectId) {
      this.getTopics();
      this.getTeams(this.state.page);
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: ""
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  handleSearchChange(event) {
    if (!this.state.beganSearch) {
      this.setState({ beganSearch: true });
    }

    this.setState({ searchInput: event.target.value }, () => {
      this.getTeams(1, this.state.searchInput);
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleMore = debounce(() => {
    const {
      getTeams,
      state: { more, isLoadingMore, page, search }
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.scrollingElement.scrollHeight * 0.9
      ) {
        this.setState(() => ({
          isLoadingMore: true
        }));
        getTeams(page, search);
      }
    }
  }, 100);

  getTeams(page, search = null) {
    getApiGenerator(
      GET_TEAMS.format(this.props.projectId),
      {
        page: page,
        keywords: search,
        limit: LOAD_MORE_COUNT
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            teams: [],
            more: false,
            page: 1,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamCreatedInProject: !!res.body.teamCreatedInProject,
            isLoadingMore: false
          });
        }
      } else {
        if (page === 1) {
          this.setState({
            teams: res.body.data,
            more: res.body.more,
            page: page + 1,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamCreatedInProject: !!res.body.teamCreatedInProject,
            isLoadingMore: false
          });
        } else {
          this.setState({
            teams: this.state.teams
              ? this.state.teams.slice().concat(res.body.data)
              : res.body.data,
            more: res.body.more,
            page: this.state.page + 1,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamCreatedInProject: !!res.body.teamCreatedInProject,
            isLoadingMore: false
          });
        }
      }
    });
  }

  render() {
    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (!!this.state.teams || this.state.beganSearch) {
      return (
        <TeamsPage
          isLoadingMore={this.state.isLoadingMore}
          teams={this.state.teams}
          teamCreateEnabled={this.state.teamCreateEnabled}
          teamCreatedInProject={this.state.teamCreatedInProject}
          user={this.props.user}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          handleSubmit={this.handleSubmit.bind(this)}
          handleSearchChange={this.handleSearchChange.bind(this)}
          beganSearch={this.state.beganSearch}
          language={this.props.language}
          projectId={this.props.projectId}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsContainer);
