import { connect } from "react-redux";

import {
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
  LOGIN,
  PROJECT_LOGIN
} from "App/Routes";
import {
  mapStateToProps,
  LogoutContainer
} from "components/Login/LogoutContainer";

import Router from "router";
import localStorageService from "services/localStorageService";
/* Custom: inclusion of setLanguage */
import {
  setSessionKey,
  setUser,
  showAlertWithTimeout,
  setLanguage
} from "actions";
import sessionStorageService from "services/sessionStorageService";
import popupStorage from "services/popupStorage";

/* Custom: inclusion of DEFAULT_LANGUAGE */
import { ENABLE_LOGIN_HOME, DEFAULT_LANGUAGE } from "config";
import localize from "lang/localize";
/* Custom: inclusion of reduceLanguages */
import reduceLanguages from "lang/reduceLanguages";

/* Custom: inclusion of setLanguage */
export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: sessionKey => {
      dispatch(setSessionKey(sessionKey));
    },
    setUser: user => {
      dispatch(setUser(user));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    },
    setLanguage: language => {
      dispatch(setLanguage(language));
    }
  };
};

export class CyberwellnessLogoutContainer extends LogoutContainer {
  handlePostLogout() {
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    /*
      Theoretically, it makes more sense to make navigation the last action conducted upon logout.
      This is because *LoginContainer will re-route to app's Home page when sessionKey and userId
      still exist. However, we will make it the first action for now. This is because alerts are
      broken (they show, but disappear in a split second) when routing is moved to the last action.
    */

    if (IS_EMBEDDED_PROJECT && this.props.projectId && ENABLE_LOGIN_HOME) {
      Router.navigate(PROJECT_LOGIN_HOME.format(this.props.projectId));
    } else if (IS_EMBEDDED_PROJECT && this.props.projectId) {
      Router.navigate(PROJECT_LOGIN.format(this.props.projectId));
    } else if (ENABLE_LOGIN_HOME) {
      Router.navigate(LOGIN_HOME);
    } else {
      Router.navigate(LOGIN);
    }

    this.props.showAlertWithTimeout({
      text: localize("alert_logout_success", this.props.language),
      type: "success"
    });

    localStorageService.saveState({
      user_id: null,
      sessionKey: null
    });
    this.props.setSessionKey(null);
    this.props.setUser({ id: null });
    sessionStorageService.removeItem("loginRedirectUrl");
    popupStorage.clearAll();

    /* Custom: Set language; use DEFAULT_LANGUAGE or browser language */
    let language =
      typeof DEFAULT_LANGUAGE === "string"
        ? DEFAULT_LANGUAGE
        : (navigator.languages && navigator.languages[0]) ||
          navigator.language ||
          navigator.userLanguage;

    /* Custom: reset language */
    let reducedLanguage = reduceLanguages(language.toLowerCase());

    localStorageService.setItem("language", reducedLanguage);
    this.props.setLanguage(reducedLanguage);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessLogoutContainer);
