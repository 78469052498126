import Router from "router";
import { connect } from "react-redux";

import ReactGA from "react-ga4";

import {
  SingleProjectApp,
  mapStateToProps,
  mapDispatchToProps
} from "./SingleProjectApp";
import * as Pages from "./Pages";
import * as Urls from "./UrlVariables";

import {
  USE_GAMETIZE_APP_LAYOUT,
  ENABLE_HOME_SEARCH,
  ENABLE_FEATURED_GAMES,
  ENABLE_MY_GAMES,
  ENABLE_LOGIN_HOME,
  USE_LOGIN_HOME_TEMPLATE_1,
  ENABLE_OAUTH_LOGIN,
  ENABLE_REGISTER_PAGES,
  ENABLE_SETTINGS_PAGES,
  ENABLE_NOTIFICATIONS_PAGES,
  ENABLE_PROJECT_SPECIFIC_ACCOUNT_PAGES,
  ENABLE_INBOX,
  ENABLE_TOPIC_COMMENTS,
  ENABLE_CHALLENGE_COMMENTS,
  ENABLE_CLAIM_COMMENTS,
  ENABLE_PROJECTLESS_INBOX,
  ENABLE_SSO_LOGIN,
  ENABLE_TOUR,
  SHOW_TOPIC_CATEGORIES,
  SHOW_CHALLENGE_CATEGORIES,
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
  ENABLE_GOOGLE_ANALYTICS
} from "config";

class MultiProjectApp extends SingleProjectApp {
  componentDidMount() {
    /* Initialising Google Analytics */
    if (ENABLE_GOOGLE_ANALYTICS) {
      ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);
      ReactGA.send("pageview");
    }

    this.initializeEmbeddedProject();
    this.initialize640DesktopProject();
    this.setIOSBodyStyles(true);
    this.initializeLanguage();
    this.restoreSession();
    this.addRoutes();
    this.reloadOnLogin();
  }

  addRoutes() {
    const setPage = (
      page,
      { projectId = null, id = null, id2 = null } = {}
    ) => {
      /*
        By default: set topic to null upon loading every page,
        and let API call regenerate Topic
      */
      this.props.setTopic({});
      if (projectId !== this.props.projectId && projectId !== false) {
        this.props.setProjectId(projectId);
      }
      this.setState({ component: page.component(id, id2) });

      this.props.setTopbar({
        state: page.topbarState,
        title: page.topbarTitle
      });
      this.props.setProfileDrawer({
        newPage: true,
        state: page.profileDrawerState
      });
      this.props.setSidebarState(page.sidebarState);

      /* Floating buttons */
      this.props.setFloatingButtonsState(page.floatingButtonsState);
    };

    /* REGEX TO TAKE NOTE OF */
    /*
       ((\/)?\?.*)?
       This regex is for matching parameterised URLs with '?'s annd '='s and '&'s. We
       use this for handling appended parameters to existing URLs, that the marketing
       team needs for tracking their URLs. Our app does not need or use the parameters
       for now, but this ensures that users can still access our URLs through the
       parameters, without error 404s due to our strict regex-URL matching.
       - '(\/)?\?' matches 1 or 0 forward slashes, then a question mark
       - '.*' matches 0 or more of any character (except line breaks) after the question mark
       - '((\/)?\?.*)?' putting them together, '/whatever', '/whatever/?',
         '/whatever?stuff1=here&stuff2=there', and '/whatever/?stuff1=here&stuff2=there'
         all work
    */
    Router.add(Urls.UPDATE_DEVICE_TOKEN, id2 =>
      setPage(Pages.UPDATE_DEVICE_TOKEN, { id2 })
    )
      .add(Urls.LOGIN, () => setPage(Pages.LOGIN))
      .add(Urls.POST_LOGIN_INTERCEPT, () => setPage(Pages.POST_LOGIN_INTERCEPT))
      .add(Urls.FRAME_LOGIN_SUCCESS, id =>
        setPage(Pages.FRAME_LOGIN_SUCCESS, { id })
      )
      .add(Urls.RESET_PASSWORD, () => setPage(Pages.RESET_PASSWORD))
      .add(Urls.PROJECT_PROFILE, () => setPage(Pages.PROFILE))
      .add(Urls.PROJECT_USER, id => setPage(Pages.USER, { id }))
      .add(Urls.PROJECT_USER_ACHIEVEMENTS, id =>
        setPage(Pages.USER_ACHIEVEMENTS, { id })
      )
      .add(Urls.ACHIEVEMENTS, () => setPage(Pages.ACHIEVEMENTS))
      .add(Urls.PROJECT_BOOKMARKS, () => setPage(Pages.BOOKMARKS))
      .add(Urls.TEAM, id => setPage(Pages.TEAM, { id }))
      .add(Urls.TEAM_INVITE, id => setPage(Pages.TEAM_INVITE, { id }))
      .add(Urls.TEAM_UPDATE, id => setPage(Pages.TEAM_UPDATE, { id }))
      .add(() => setPage(Pages.ERROR))
      .add(Urls.FRAME, id => setPage(Pages.FRAME, { id }))
      .add(Urls.PROJECT_HOME_MULTI, projectId =>
        setPage(Pages.PROJECT_HOME, { projectId })
      )
      .add(Urls.TOPIC, id => setPage(Pages.TOPIC, { id, projectId: false }))
      .add(Urls.CHALLENGE, id =>
        setPage(Pages.CHALLENGE, { id, projectId: false })
      )
      .add(Urls.CHALLENGE_A_FRIEND, id =>
        setPage(Pages.CHALLENGE_A_FRIEND, { id, projectId: false })
      )
      .add(Urls.CHALLENGE_ENQUIRY, id =>
        setPage(Pages.CHALLENGE_ENQUIRY, { id, projectId: false })
      )
      .add(Urls.PROJECT_ACHIEVEMENTS_MULTI, projectId =>
        setPage(Pages.PROJECT_ACHIEVEMENTS, { projectId })
      )
      .add(Urls.PROJECT_USER_ACHIEVEMENTS_MULTI, (projectId, id) =>
        setPage(Pages.PROJECT_USER_ACHIEVEMENTS, { projectId, id })
      )
      .add(Urls.PROJECT_FRAME, (projectId, id) =>
        setPage(Pages.PROJECT_FRAME, { projectId, id })
      )
      .add(Urls.PROJECT_BOOKMARKS_MULTI, projectId =>
        setPage(Pages.PROJECT_BOOKMARKS, { projectId })
      )
      .add(Urls.PROJECT_PROFILE_MULTI, projectId =>
        setPage(Pages.PROJECT_PROFILE, { projectId })
      )
      .add(Urls.PROJECT_SEARCH_MULTI, (projectId, id) =>
        setPage(Pages.PROJECT_SEARCH, { projectId, id })
      )
      .add(Urls.PROJECT_TEAMS_MULTI, projectId =>
        setPage(Pages.PROJECT_TEAMS, { projectId })
      )
      .add(Urls.PROJECT_TEAM_CREATE_MULTI, projectId =>
        setPage(Pages.PROJECT_TEAM_CREATE, { projectId })
      )
      .add(Urls.PROJECT_USER_MULTI, (projectId, id) =>
        setPage(Pages.PROJECT_USER, { projectId, id })
      )
      .add(Urls.PROJECT_USER_ENQUIRY_MULTI, (projectId, id) =>
        setPage(Pages.PROJECT_USER_ENQUIRY, { projectId, id })
      )
      .add(Urls.PROJECT_USERS_MULTI, projectId =>
        setPage(Pages.PROJECT_USERS, { projectId })
      )
      .add(Urls.PROJECT_TEAM_MULTI, (projectId, id) =>
        setPage(Pages.PROJECT_TEAM, { projectId, id })
      )
      .add(Urls.PROJECT_TEAM_INVITE_MULTI, (projectId, id) =>
        setPage(Pages.PROJECT_TEAM_INVITE, { projectId, id })
      )
      .add(Urls.PROJECT_TEAM_UPDATE_MULTI, (projectId, id) =>
        setPage(Pages.PROJECT_TEAM_UPDATE, { projectId, id })
      )
      .add(Urls.PROJECT_REWARDS_MULTI, projectId =>
        setPage(Pages.PROJECT_REWARDS, { projectId })
      )
      .add(Urls.ITEM, id => setPage(Pages.ITEM, { id }))
      .add(Urls.PROJECT_ITEM_MULTI, (projectId, id) =>
        setPage(Pages.PROJECT_ITEM, { projectId, id })
      )
      .add(Urls.PROJECT_LEADERBOARD_MULTI, projectId =>
        setPage(Pages.PROJECT_LEADERBOARD, { projectId })
      )
      .add(Urls.PROJECT_REFER_A_FRIEND_MULTI, projectId =>
        setPage(Pages.PROJECT_REFER_A_FRIEND, { projectId })
      )
      .add(Urls.PROJECT_ACTIVITY_MULTI, projectId =>
        setPage(Pages.PROJECT_ACTIVITY, { projectId })
      )
      .add(Urls.CHALLENGE_ACTIVITY, id =>
        setPage(Pages.CHALLENGE_ACTIVITY, { id, projectId: false })
      )
      .add(Urls.CHALLENGE_MY_ACTIVITY, id =>
        setPage(Pages.CHALLENGE_MY_ACTIVITY, { id, projectId: false })
      )
      .add(Urls.CLAIM, id => setPage(Pages.CLAIM, { id, projectId: false }))
      .add(Urls.CLAIM_ENQUIRY, id =>
        setPage(Pages.CLAIM_ENQUIRY, { id, projectId: false })
      );

    if (ENABLE_NOTIFICATIONS_PAGES) {
      Router.add(Urls.PROJECT_NOTIFICATIONS, () =>
        setPage(Pages.NOTIFICATIONS)
      ).add(Urls.PROJECT_NOTIFICATIONS_MULTI, projectId =>
        setPage(Pages.PROJECT_NOTIFICATIONS, { projectId })
      );
    }

    if (SHOW_TOPIC_CATEGORIES) {
      Router.add(Urls.TOPIC_CATEGORY_MULTI, (projectId, id) =>
        setPage(Pages.TOPIC_CATEGORY, { projectId, id })
      );
    }

    if (SHOW_CHALLENGE_CATEGORIES) {
      Router.add(Urls.CHALLENGE_CATEGORY, (id, id2) =>
        setPage(Pages.CHALLENGE_CATEGORY, { id, id2 })
      );
    }

    if (ENABLE_TOUR) {
      Router.add(Urls.LOGIN_TOUR, () => setPage(Pages.LOGIN_TOUR));
    }

    if (ENABLE_INBOX) {
      Router.add(Urls.PROJECT_INBOX, projectId =>
        setPage(Pages.PROJECT_INBOX, { projectId })
      );

      if (ENABLE_PROJECTLESS_INBOX) {
        Router.add(Urls.INBOX, () => setPage(Pages.INBOX));
      }
    }

    if (ENABLE_TOPIC_COMMENTS) {
      Router.add(Urls.TOPIC_COMMENTS, id =>
        setPage(Pages.TOPIC_COMMENTS, { id, projectId: false })
      ).add(Urls.TOPIC_COMMENTS_THREAD, (id, id2) =>
        setPage(Pages.TOPIC_COMMENTS_THREAD, { id, id2, projectId: false })
      );
    }

    if (ENABLE_CHALLENGE_COMMENTS) {
      Router.add(Urls.CHALLENGE_COMMENTS, id =>
        setPage(Pages.CHALLENGE_COMMENTS, { id, projectId: false })
      ).add(Urls.CHALLENGE_COMMENTS_THREAD, (id, id2) =>
        setPage(Pages.CHALLENGE_COMMENTS_THREAD, { id, id2, projectId: false })
      );
    }

    if (ENABLE_CLAIM_COMMENTS) {
      Router.add(Urls.CLAIM_COMMENTS, id =>
        setPage(Pages.CLAIM_COMMENTS, { id, projectId: false })
      ).add(Urls.CLAIM_COMMENTS_THREAD, (id, id2) =>
        setPage(Pages.CLAIM_COMMENTS_THREAD, { id, id2, projectId: false })
      );
    }

    if (ENABLE_LOGIN_HOME) {
      Router.add(Urls.LOGIN_HOME, () => setPage(Pages.LOGIN_HOME));

      if (USE_LOGIN_HOME_TEMPLATE_1 && ENABLE_OAUTH_LOGIN) {
        Router.add(Urls.LOGIN_SSO, () => setPage(Pages.LOGIN_SSO));
      }
    }

    if (ENABLE_REGISTER_PAGES) {
      Router.add(Urls.REGISTER, () => setPage(Pages.REGISTER));
    }

    if (ENABLE_SETTINGS_PAGES) {
      Router.add(Urls.SETTINGS, () => setPage(Pages.SETTINGS));
    }

    if (ENABLE_SSO_LOGIN) {
      Router.add(Urls.SSO_LOGIN_SUCCESS, id =>
        setPage(Pages.SSO_LOGIN_SUCCESS, { id })
      );
    }

    if (ENABLE_PROJECT_SPECIFIC_ACCOUNT_PAGES) {
      Router.add(Urls.PROJECT_RESET_PASSWORD_MULTI, projectId =>
        setPage(Pages.PROJECT_RESET_PASSWORD, { projectId })
      )
        .add(Urls.PROJECT_LOGIN_MULTI, projectId =>
          setPage(Pages.PROJECT_LOGIN, { projectId })
        )
        .add(Urls.PROJECT_POST_LOGIN_INTERCEPT, projectId =>
          setPage(Pages.PROJECT_POST_LOGIN_INTERCEPT, { projectId })
        )
        .add(Urls.PROJECT_FRAME_LOGIN_SUCCESS, (projectId, id) =>
          setPage(Pages.PROJECT_FRAME_LOGIN_SUCCESS, { projectId, id })
        );

      if (ENABLE_LOGIN_HOME) {
        Router.add(Urls.PROJECT_LOGIN_HOME_MULTI, projectId =>
          setPage(Pages.PROJECT_LOGIN_HOME, { projectId })
        );

        if (USE_LOGIN_HOME_TEMPLATE_1 && ENABLE_OAUTH_LOGIN) {
          Router.add(Urls.PROJECT_LOGIN_SSO, projectId =>
            setPage(Pages.PROJECT_LOGIN_SSO, { projectId })
          );
        }
      }

      if (ENABLE_REGISTER_PAGES) {
        Router.add(Urls.PROJECT_REGISTER_MULTI, projectId =>
          setPage(Pages.PROJECT_REGISTER, { projectId })
        );
      }

      if (ENABLE_SETTINGS_PAGES) {
        Router.add(Urls.PROJECT_SETTINGS_MULTI, projectId =>
          setPage(Pages.PROJECT_SETTINGS, { projectId })
        );
      }

      if (ENABLE_SSO_LOGIN) {
        Router.add(Urls.PROJECT_SSO_LOGIN_SUCCESS, (projectId, id) =>
          setPage(Pages.PROJECT_SSO_LOGIN_SUCCESS, { projectId, id })
        );
      }
    }

    if (USE_GAMETIZE_APP_LAYOUT) {
      Router.add(Urls.HOME, () => setPage(Pages.HOME)).add(Urls.GAME, id =>
        setPage(Pages.GAME, { id })
      );

      if (ENABLE_HOME_SEARCH) {
        Router.add(Urls.SEARCH_PROJECTS, id =>
          setPage(Pages.SEARCH_PROJECTS, { id })
        );
      }

      if (ENABLE_FEATURED_GAMES) {
        Router.add(Urls.FEATURED_PROJECTS, () =>
          setPage(Pages.FEATURED_PROJECTS)
        );
      }

      if (ENABLE_MY_GAMES) {
        Router.add(Urls.MY_PROJECTS, () => setPage(Pages.MY_PROJECTS));
      }
    } else {
      Router.add(Urls.HOME, () => setPage(Pages.PROJECTS_LIST));
    }

    Router.check();
    Router.listen();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiProjectApp);
