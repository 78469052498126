import React, { Component } from "react";
import PropTypes from "prop-types";

import "./PopupAlertDialog.css";

import Modal from "react-modal";

const propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleClosePopupAlertDialog: PropTypes.func.isRequired,
  popup: PropTypes.object
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class PopupAlertDialog extends Component {
  renderImage() {
    let image = this.props.popup ? this.props.popup.image : "";
    let title = this.props.popup ? this.props.popup.title : "";

    if (this.props.popup.url) {
      return (
        <a
          href={this.props.popup.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="popup-alert-dialog-image"
            src={image}
            alt={this.props.popup.title ? title : ""}
          />
        </a>
      );
    } else {
      return (
        <img
          className="popup-alert-dialog-image"
          src={image}
          alt={this.props.popup.title ? title : ""}
        />
      );
    }
  }

  renderEmbed() {
    let embed = this.props.popup ? this.props.popup.alertEmbedUrl : "";
    let title = this.props.popup ? this.props.popup.title : "";

    return (
      <iframe
        className="popup-alert-dialog-embed"
        src={embed}
        title={this.props.popup.title ? title : ""}
      />
    );
  }

  render() {
    let title = this.props.popup ? this.props.popup.title : "";

    return (
      <Modal
        isOpen={this.props.showDialog}
        contentLabel={this.props.popup.title ? title : " "}
        onRequestClose={this.props.handleClosePopupAlertDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog popup-alert-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header pure-g">
            <div className="pure-u-7-8">
              <h5 className="dialog-title">
                {this.props.popup.title ? title : " "}
              </h5>
            </div>
            <div className="pure-u-1-8">
              <button
                id="closePopupAlertDialogButton"
                onClick={e => {
                  this.props.handleClosePopupAlertDialog(e);
                }}
                className="close-button-container"
              >
                <i className="fa close-button" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="dialog-body">
            <div className="popup-alert-dialog-image-wrapper">
              {this.props.popup.alertType === "standard"
                ? this.renderImage()
                : this.renderEmbed()}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

PopupAlertDialog.propTypes = propTypes;

export default PopupAlertDialog;
