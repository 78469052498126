import React, { Fragment } from "react";

import ChallengeResult from "components/Challenge/ChallengeResult/ChallengeResult";
import QuizResult from "./QuizResult";
import SurveyResult from "./SurveyResult";
import ViewCompleted from "components/Challenge/ChallengeResult/ViewCompleted";

class CyberwellnessChallengeResult extends ChallengeResult {
  /* Custom: layout changes */
  renderResults() {
    switch (this.props.challengeTypeId) {
      case 1: // standard/photo
      case 5: // video
      case 13: // multi-field
        return (
          <ViewCompleted id={this.props.id} language={this.props.language} />
        );
      case 2: // quiz
      case 10: // repeatable quiz
        return (
          <Fragment>
            <QuizResult
              id={this.props.id}
              correctAnswerNo={this.props.correctAnswerNo}
              wrongAnswerNo={this.props.wrongAnswerNo}
              claimed={this.props.claimed}
              claimedBefore={this.props.claimedBefore}
              repeatUntilCorrect={this.props.repeatUntilCorrect}
              correctAnswerTitle={this.props.correctAnswerTitle}
              correctAnswer={this.props.correctAnswer}
              quizOptions={this.props.quizOptions}
              userName={this.props.userName}
              userPhoto={this.props.userPhoto}
              gridWidth={this.props.gridWidth}
              privateClaim={this.props.privateClaim}
              language={this.props.language}
            />
            <ViewCompleted id={this.props.id} language={this.props.language} />
          </Fragment>
        );
      case 3: // prediction - show results if user has completed, else nothing
      case 11: // survey
        if (!this.props.claimed) {
          return null;
        } else {
          /*
            Custom: use a div with .toppadding instead of <Fragment>.
            Removed .container from parent div of SurveyResult.
          */
          return (
            <div className="toppadding">
              <div className="challenge-page-divider" />
              <SurveyResult
                quizOptions={this.props.quizOptions}
                userName={this.props.userName}
                userPhoto={this.props.userPhoto}
              />
              <ViewCompleted
                id={this.props.id}
                language={this.props.language}
              />
            </div>
          );
        }
      default:
        // flashcard, validation code, key, invite, personality type + any others
        return null;
    }
  }
}

export default CyberwellnessChallengeResult;
