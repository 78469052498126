import React, { Fragment } from "react";

import QuizResult from "components/Challenge/ChallengeResult/QuizResult";

import localize from "lang/localize";

class CyberwellnessQuizResult extends QuizResult {
  /**
   * Render quiz results for default view
   */
  renderDefaultQuizResult() {
    return (
      <Fragment>
        <div className="challenge-page-divider" />
        <div className="container verticalpadding">
          <h5 className="text-align-center nomargin">
            {localize("nav_bar_title_feedback_view", this.props.language)}
          </h5>
          {this.renderPrivateCompletionText()}
          {this.renderCorrectAnswerText()}
        </div>
        {this.renderDefaultQuizResultOptions()}
        {/* CUSTOM FOR CYBERWELLNESS - UI FIX */}
        <div className="toppadding">{this.renderPlayerResultCount()}</div>
      </Fragment>
    );
  }

  /**
   * Renders the number of players who got the answer correct/wrong
   *
   * Customisations to fix UI issues in mobile
   * - Remove container
   * - Remove rightmargin and use custom CSS (use 20px for desktop, 10px for mobile)
   */
  renderPlayerResultCount() {
    if (!this.props.privateClaim) {
      return (
        <div
          className={
            "verticalpadding" +
            ((this.props.claimed || this.props.claimedBefore) &&
            !this.props.repeatUntilCorrect
              ? " verticalpadding"
              : "")
          }
        >
          <div className="innerblock">
            <div className="pure-g" id="quizActivityLinkContainer">
              <div className="pure-u-4-24">
                <span className="challengeicon quiz-result floatright right" />
              </div>
              <div className="pure-u-8-24">
                <strong>{this.props.correctAnswerNo}</strong>
                <div className="smalltext">
                  {localize(
                    "challenge_profile_quiz_correct_short",
                    this.props.language
                  )}
                </div>
              </div>
              <div className="pure-u-4-24">
                <span className="challengeicon quiz-result floatright wrong" />
              </div>
              <div className="pure-u-8-24">
                <strong>{this.props.wrongAnswerNo}</strong>
                <div className="smalltext">
                  {localize(
                    "challenge_profile_quiz_incorrect_short",
                    this.props.language
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default CyberwellnessQuizResult;
