import React from "react";
import PropTypes from "prop-types";

import Link from "components/shared/Link/Link";

import { CHALLENGE_ACTIVITY } from "App/Routes";
import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number.isRequired,
  language: PropTypes.string
};

const viewCompleted = props => (
  <Link to={CHALLENGE_ACTIVITY.format(props.id)}>
    <div className="challenge-page-divider" />
    <div className="container verticalpadding">
      <div className="innerblock">
        <div className="pure-u-2-24" />
        <div className="pure-u-20-24">
          <div className="pure-g">
            <div className="pure-u-23-24 textcenter">
              {localize("challenge_profile_all_claims_text", props.language)}
            </div>
            <div className="pure-u-1-24">
              <span className="floatright">
                <span className="right-caret alignmiddle" />
              </span>
            </div>
          </div>
        </div>
        <div className="pure-u-2-24" />
      </div>
    </div>
  </Link>
);

viewCompleted.propTypes = propTypes;

export default viewCompleted;
