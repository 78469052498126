import React from "react";
import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
  ChallengeCompletedModalContainer
} from "components/Challenge/ChallengeCompletedModal/ChallengeCompletedModalContainer";
import ChallengeCompletedModal from "./ChallengeCompletedModal";

/* for routing after challenge completed */
import Router from "router";
import { TOPIC, HOME } from "App/Routes";

export class CyberwellnessChallengeCompletedModalContainer extends ChallengeCompletedModalContainer {
  constructor() {
    super();
    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.goToNextChallenge = this.goToNextChallenge.bind(this);
    this.goToChallengeAFriend = this.goToChallengeAFriend.bind(this);
    /* Custom: goToNextTopic and goToHome */
    this.goToNextTopic = this.goToNextTopic.bind(this);
    this.goToHome = this.goToHome.bind(this);
  }

  goToNextTopic(topicIdNext, lastChallengeCompletedId) {
    Router.navigate(TOPIC.format(topicIdNext));
    this.handleCloseModal(lastChallengeCompletedId);
  }

  goToHome(lastChallengeCompletedId) {
    Router.navigate(HOME);
    this.handleCloseModal(lastChallengeCompletedId);
  }

  render() {
    return (
      <ChallengeCompletedModal
        challengeCompletedArr={this.props.challengeCompletedArr}
        showModal={this.state.showModal}
        handleCloseModal={this.handleCloseModal}
        goToNextChallenge={this.goToNextChallenge}
        goToChallengeAFriend={this.goToChallengeAFriend}
        language={this.props.language}
        /* Custom: goToNextTopic and goToHome */
        goToNextTopic={this.goToNextTopic}
        goToHome={this.goToHome}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessChallengeCompletedModalContainer);
