import React, { Fragment } from "react";
import PropTypes from "prop-types";

import TextboxInput from "components/shared/Inputs/TextboxInput";
import Comment from "components/Comments/Comment";
import AchievementsModal from "components/shared/Achievements/AchievementsModal/AchievementsModal";

import localize from "lang/localize";
import Loading from "components/shared/Loading";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  comment: PropTypes.object,
  subComments: PropTypes.array,
  more: PropTypes.bool,
  showInput: PropTypes.bool,
  input: PropTypes.string,
  handleMore: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  typeId: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  userId: PropTypes.number,
  removeSubComment: PropTypes.func,
  showAchievements: PropTypes.bool,
  achievements: PropTypes.array,
  setHideAchievements: PropTypes.func,
  setAchievements: PropTypes.func
};

const MainComment = props => {
  if (props.comment) {
    return (
      <div>
        <Comment
          id={props.comment.id}
          commentUserName={props.comment.userName}
          commentUserPhoto={props.comment.photoSmall}
          commentUserId={props.comment.userId}
          userId={props.userId}
          userRankStatus={props.comment.userRankStatus}
          userRankStatusImage={props.comment.userRankStatusImage}
          message={props.comment.message}
          isMainCommentInThreadPage={true}
          createdAtFormatted={props.comment.createdAtFormatted}
          projectId={props.projectId}
          removeComment={props.removeComment}
          type={props.type}
          typeId={props.typeId}
          commentThreadCount={props.comment.commentThreadCount}
        />
      </div>
    );
  } else {
    return null;
  }
};

const SubComments = props => {
  if (
    props.subComments &&
    Array.isArray(props.subComments) &&
    props.subComments.length > 0
  ) {
    return (
      <div className="topmargin-30">
        {props.subComments.map((comment, index) => (
          <Fragment key={comment.id}>
            <Comment
              id={comment.id}
              commentUserName={comment.userName}
              commentUserPhoto={comment.photoSmall}
              commentUserId={comment.userId}
              userId={props.userId}
              userRankStatus={comment.userRankStatus}
              userRankStatusImage={comment.userRankStatusImage}
              message={comment.message}
              isSubComment={true}
              createdAtFormatted={comment.createdAtFormatted}
              projectId={props.projectId}
              removeComment={props.removeSubComment}
              type={props.type}
              typeId={props.id}
              commentThreadCount={comment.commentThreadCount}
            />
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <p className="topmargin-30 text-align-center">
        {localize("empty_comment_table_text", props.language)}
      </p>
    );
  }
};

const CommentInput = props => {
  if (!!props.showInput) {
    return (
      <div id="commentInput" className="bottompadding">
        <form className="pure-form" onSubmit={props.handleSubmit}>
          <div className="pure-u-1">
            <TextboxInput
              type="text"
              value={props.input}
              placeholder={localize("comment_placeholder_text", props.language)}
              onChange={props.handleInputChange}
            />
          </div>
          <div className="pure-u-1">
            <button
              type="submit"
              disabled={props.input.trim() === "" ? true : false}
              className={
                "button nomargin floatright " +
                (props.input.trim() === "" ? "disabled" : "cta")
              }
            >
              {localize("nav_bar_title_comments_text", props.language)}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return null;
  }
};

// const MoreButtonContainer = props => {
//   if (props.more) {
//     return (
//       <div className="more-container flex justify-content-center">
//         <button id="pagination" className="button" onClick={props.handleMore}>
//           {localize("more_text", props.language)}
//         </button>
//       </div>
//     );
//   } else {
//     return null;
//   }
// };

const CommentsThreadPage = props => {
  return (
    <Fragment>
      <div className="comments-container container verticalpadding bottompadding-floating-extended">
        <div className="innerblock">
          <MainComment
            comment={props.comment}
            userId={props.userId}
            projectId={props.projectId}
            removeComment={props.removeComment}
            type={props.type}
            typeId={props.typeId}
          />
          <CommentInput
            showInput={props.showInput}
            input={props.input}
            handleSubmit={props.handleSubmit}
            handleInputChange={props.handleInputChange}
            language={props.language}
          />
          <hr />
          <SubComments
            subComments={props.subComments}
            userId={props.userId}
            projectId={props.projectId}
            removeSubComment={props.removeSubComment}
            type={props.type}
            id={props.id}
            language={props.language}
          />
          <AchievementsModal
            showModal={props.showAchievements}
            handleCloseModal={props.setHideAchievements}
            achievements={props.achievements}
          />
          {props.isLoadingMore ? <Loading /> : null}
          {/* <MoreButtonContainer
            more={props.more}
            handleMore={props.handleMore}
            language={props.language}
          /> */}
        </div>
      </div>
    </Fragment>
  );
};

CommentsThreadPage.propTypes = propTypes;

export default CommentsThreadPage;
