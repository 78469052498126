import React from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import {
  ProjectsListContainer,
  mapStateToProps
} from "./ProjectsListContainer";
import getApiGenerator from "services/getApiGenerator";

import NotLoggedIn from "components/shared/NotLoggedIn/NotLoggedIn";
import MyProjectsPage from "./MyProjectsPage";

import { LOAD_MORE_COUNT } from "config";

class MyProjectsContainer extends ProjectsListContainer {
  constructor() {
    super();
    this.state = {
      projects: null,
      more: false,
      page: 1,
      user: null,
      searchInput: "",
      isLoadingMore: false
    };

    this.removeProject = this.removeProject.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getProjects = this.getProjects.bind(this);
  }

  getProjects(page, keywords) {
    getApiGenerator(
      "users/" + this.props.user.id + "/bundles.json",
      {
        page: page,
        limit: LOAD_MORE_COUNT,
        keywords: keywords
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ projects: [], isLoadingMore: false });
        }
      } else {
        this.setState({
          projects: this.state.projects
            ? this.state.projects.slice().concat(res.body.data)
            : res.body.data,
          more: res.body.more,
          page: this.state.page + 1,
          isLoadingMore: false
        });
      }
    });
  }

  removeProject(projectId) {
    this.setState({
      projects: this.state.projects.filter(element => element.id !== projectId)
    });
  }

  handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();

      this.handleSubmit(event);
    }
  };

  handleSearchChange = event => {
    let value = event.target.value.trim().replace(/[\W_]+/g, " ");

    this.setState({ searchInput: value });
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ projects: null, page: 1 }, () => {
      this.getProjects(this.state.page, this.state.searchInput);
    });
  };

  handleMore = debounce(() => {
    const {
      getProjects,
      state: { more, isLoadingMore, page, searchInput }
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.scrollingElement.scrollHeight * 0.9
      ) {
        this.setState(() => ({
          isLoadingMore: true
        }));
        getProjects(page, searchInput);
      }
    }
  }, 100);

  render() {
    if (!this.props.user.id) {
      return <NotLoggedIn language={this.props.language} showLogin={false} />;
    } else {
      return (
        <MyProjectsPage
          isLoadingMore={this.state.isLoadingMore}
          projects={this.state.projects}
          more={this.state.more}
          handleMore={() => this.handleMore()}
          handleKeyPress={this.handleKeyPress}
          handleSearchChange={this.handleSearchChange}
          handleSubmit={this.handleSubmit}
          language={this.props.language}
          removeProject={this.removeProject}
        />
      );
    }
  }
}

export default connect(mapStateToProps)(MyProjectsContainer);
