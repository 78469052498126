import React from "react";
import PropTypes from "prop-types";

import "./Home.css";
import { PROJECT_HOME } from "App/Routes";
import HomeFlashcard from "./HomeFlashcard";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

const propTypes = {
  data: PropTypes.array,
  language: PropTypes.string
};

const HomePage = props => (
  <div className="home container">
    {/* Fixed-positioned background for Screen */}
    <div className="background-cyberwellness"></div>

    <div className="cyberwellness-home-container">
      <div className="pure-g text-white bottommargin">
        <div className="pure-u-1-1 pure-u-md-1-24"></div>
        <div className="pure-u-1-1 pure-u-md-22-24">
          <h2 className="text-align-center nomargin home-title">
            {localize("dbs_login_title", props.language)}
          </h2>
          <p className="text-align-center nomargin home-subtitle">
            {localize("dbs_login_subtitle", props.language)}
          </p>
        </div>
        <div className="pure-u-1-1 pure-u-md-1-24"></div>
      </div>
      <CardsContainer data={props.data} language={props.language} />
      <div className="flex justify-content-center">
        <Link
          className="button cta medium nomargin home-begin-button"
          to={PROJECT_HOME.format(props.projectId)}
        >
          {localize("dbs_begin_button", props.language)}
        </Link>
      </div>
      <div className="bottommargin-80"></div>
    </div>
  </div>
);

const CardsContainer = props => {
  return (
    <div className="innerblock">
      <div className="pure-g">
        <Cards data={props.data} language={props.language} />
      </div>
    </div>
  );
};

const Cards = props =>
  props.data.map((data, index) => (
    <div className="pure-u-1-1 pure-u-sm-1-1 bottommargin-below-sm" key={index}>
      <HomeFlashcard
        title={data.title}
        description={data.footnote}
        image={data.imageLarge}
        language={props.language}
      />
    </div>
  ));

HomePage.propTypes = propTypes;

export default HomePage;
