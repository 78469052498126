import React, { Component } from "react";
import { connect } from "react-redux";
import Router from "router";
import debounce from "lodash.debounce";

import {
  HOME,
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME,
  PROJECT_SEARCH
} from "App/Routes";
import ProjectPage from "./ProjectPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { LOAD_MORE_COUNT, ENABLE_LOGIN_HOME, SINGLE_PROJECT_APP } from "config";
import {
  setProject,
  setButtons,
  setTopicCategory,
  showAlertWithTimeout
} from "actions";
import getApiGenerator from "services/getApiGenerator";
import {
  GET_TOPICS,
  GET_PROJECT_POPUP,
  GET_PROJECT_CHALLENGES
} from "services/api";
import localize from "lang/localize";

import sessionStorageService from "services/sessionStorageService";
import popupStorage from "services/popupStorage";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    buttons: state.floatingButtons
      ? state.floatingButtons.buttons || null
      : null,
    projectId: state.projectId,
    user: state.user,
    team: state.team,
    /* Rendering notifications */
    notifications: state.profileDrawer.notifications,
    /* Rendering inbox unread */
    inboxUnread: state.profileDrawer.inboxUnread,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopicCategory: topicCategory => {
      dispatch(setTopicCategory(topicCategory));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

/*
  In Project Container, this.props.id refers to category ID
  while this.props.projectId refers to projectId.
*/
export class ProjectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      topics: null,
      categories: null,
      categoriesData: [],
      more: false,
      page: 1,
      showcaseProject: null,
      code: null,
      error: null,
      popup: null,
      showPopup: false,
      showShareGameDialog: false,
      search: "",
      isLoadingMore: false
    };

    this.handleClosePopupAlertDialog = this.handleClosePopupAlertDialog.bind(
      this
    );
    this.handleOpenShareGameDialog = this.handleOpenShareGameDialog.bind(this);
    this.handleCloseShareGameDialog = this.handleCloseShareGameDialog.bind(
      this
    );

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getTopics = this.getTopics.bind(this);
  }

  /**
   * Update state with search keywords
   */
  handleSearchChange = event => {
    this.setState({ search: event.target.value });
  };

  /**
   * Keypress event handler
   */
  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  };

  /**
   * Submit event handler
   * E.g. Invoked when the user performs a search on topics
   */
  handleSubmit = event => {
    event.preventDefault();
    if (this.state.search) {
      getApiGenerator(
        GET_PROJECT_CHALLENGES.format(this.props.projectId),
        { keywords: this.state.search },
        this.props.sessionKey
      ).end((err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error"
            });
          }
        } else {
          Router.navigate(
            PROJECT_SEARCH.format(this.props.projectId, this.state.search)
          );
        }
        this.setState({ search: "" });
      });
    }
  };

  /**
   * Initialize project state and retrieve project information from API
   */
  initializeProject() {
    /* popupStates do not exist in sessionStorage */
    if (!sessionStorageService.getItem("popupStates")) {
      popupStorage.initialize(this.props.projectId);
    } else {
      /* popupStates exist in sessionStorage */
      /* popupState for this projectId does not exist */
      if (!popupStorage.checkIdExist(this.props.projectId)) {
        /* add popup state */
        popupStorage.addId(this.props.projectId);
      }
    }

    if (this.props.id) {
      this.getTopics(1, this.props.id);
    } else {
      this.getTopics(this.state.page);
    }
  }

  /**
   * Retrieve project popup content
   */
  getProjectPopup() {
    getApiGenerator(
      GET_PROJECT_POPUP.format(this.props.projectId),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
      } else {
        this.setState({ popup: res.body }, () => {
          this.setState({
            showPopup:
              this.state.popup &&
              !popupStorage.checkPopupShown(this.props.projectId)
          });
        });
      }
    });
  }

  /**
   * Retrieve project information from API and list of topics
   *
   * @param {number} page - Page number
   * @param {number} category_id - Category ID
   * @param {number} limit - No. of topics to retrieve
   */
  getTopics(page, category_id = this.props.id || "", limit = LOAD_MORE_COUNT) {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: page,
        category_id: category_id,
        limit: limit
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            project: res.body.game,
            topics: [],
            code: 500,
            error: res.body.error
          });
        }
      } else {
        const categories = res.body.categories
          ? res.body.categories.map(category => ({
              id: category.id,
              title: category.name,
              selected: category.selected,
              description: category.description,
              image: category.image
            }))
          : null;

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        let compiledProject = categories
          ? Object.assign({}, res.body.game, { categories: categories })
          : res.body.game;

        this.setState({
          isLoadingMore: false,
          categories: categories,
          project: res.body.game,
          topics:
            this.state.topics && page !== 1
              ? this.state.topics.slice().concat(res.body.data)
              : res.body.data,
          more: res.body.more,
          page: page + 1,
          showcaseProject: !!res.body.showcaseProjects
            ? res.body.showcaseProjects[0]
            : null,
          code: res.body.code,
          error: ""
        });
        this.props.setProject(compiledProject);
        this.props.setButtons(BUTTONS);

        if (categories) {
          this.props.setTopicCategory(
            categories.filter(category => category.selected === true)[0]
          );
        }
        this.getProjectPopup();
      }
    });
  }

  /**
   * Invoked immediately after the Project component is mounted.
   * Initialization that requires DOM nodes should go here.
   */
  componentDidMount() {
    this.initializeProject();

    /* Use hashing to show success alert for reset activity */
    if (window.location.hash.slice(0, 14) === "#resettedtopic") {
      this.props.showAlertWithTimeout({
        text: localize("alert_reset_activity_success", this.props.language),
        type: "success"
      });
    }
    window.addEventListener("scroll", this.handleMore);
  }

  /**
   * Invoked immediately after the Project component is updated.
   *
   * @param {object} prevProps - Previous property object
   * @param {object} prevState - Previous state object
   */
  componentDidUpdate(prevProps, prevState) {
    // mitigating race conditions in joining user into game
    // checks: (1) if this.state.error and prevState.error are different,
    //         (2) error message check (search for 'join' keyword),
    //         (3) prevState.error is not string (null check)
    // then: call api to get topics again
    // won't go into loop because this.state.error will only be null (otherwise it is a string) before api is called
    if (
      this.state.error !== prevState.error &&
      this.state.error &&
      this.state.error.indexOf("join") !== -1 &&
      typeof prevState.error !== "string"
    ) {
      if (this.props.id) {
        this.getTopics(1, this.props.id);
      } else {
        this.getTopics(this.state.page);
      }
    }

    /* when switching projects */
    if (
      this.props.projectId !== prevProps.projectId &&
      typeof this.props.projectId === "number" &&
      typeof prevProps.projectId === "number"
    ) {
      /* reset states */
      this.setState(
        {
          project: null,
          topics: null,
          categories: null,
          categoriesData: [],
          more: false,
          page: 1,
          showcaseProject: null,
          code: null,
          error: null,
          popup: null,
          showPopup: false
        },
        () => {
          /* reinitialize project */
          this.initializeProject();
        }
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  /**
   * Retrieve link to login page
   */
  getLoginRoute() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  /**
   * Load more topics based on current page number
   */
  handleMore = debounce(() => {
    const {
      getTopics,
      state: { more, isLoadingMore, page }
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.scrollingElement.scrollHeight * 0.9
      ) {
        this.setState(() => ({
          isLoadingMore: true
        }));
        getTopics(page);
      }
    }
  }, 100);

  /**
   * Close popup alert
   *
   * @param {object} e - Event
   */
  handleClosePopupAlertDialog(e) {
    e.preventDefault();

    this.setState(
      {
        showPopup: false
      },
      () => {
        popupStorage.setHidePopup(this.props.projectId);
      }
    );
  }

  /**
   * Open "Share Project" popup dialog
   *
   * @param {object} event - Event
   */
  handleOpenShareGameDialog(event) {
    event.preventDefault();

    this.setState({
      showShareGameDialog: true
    });
  }

  /**
   * Close "Share Project" popup dialog
   */
  handleCloseShareGameDialog() {
    this.setState({
      showShareGameDialog: false
    });
  }

  /**
   * Render view
   */
  render() {
    const UNAVAILABLE_GAME_ERROR_LIST = [
      localize("unavailable_game_unpublished", this.props.language),
      localize("unavailable_game_deleted", this.props.language),
      localize("unavailable_game_no_topics", this.props.language),
      localize("unavailable_game_invalid", this.props.language)
    ];
    const UNAVAILABLE_GAME_ERROR_LIST_LOGGEDIN = [
      localize("unavailable_game_unpublished", this.props.language),
      localize("unavailable_game_deleted", this.props.language),
      localize("unavailable_game_no_topics", this.props.language),
      localize("unavailable_game_invalid", this.props.language)
    ];
    const PRIVATE_GAME_ERROR = localize(
      "unavailable_game_private",
      this.props.language
    );
    const PRIVATE_GAME_ERROR_LOGGEDIN = localize(
      "unavailable_game_private_loggedin",
      this.props.language
    );
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    if (
      this.state.topics &&
      (this.state.topics.length !== 0 || this.state.categories)
    ) {
      /*
        Setting a key prop for ProjectPage will allow the interface to be reset.
        This is evident in mobile Project view, where you will be directed back
        to the first slide. States are reset in componentDidUpdate().
      */
      return (
        <ProjectPage
          isLoadingMore={this.state.isLoadingMore}
          sessionKey={this.props.sessionKey}
          ref={projectPage => (this.projectPage = projectPage)}
          key={this.props.projectId}
          projectId={this.props.projectId}
          id={this.props.id}
          project={this.state.project}
          projectImage={this.state.project.bannerImage}
          buttons={this.props.buttons}
          categories={this.state.categories}
          topics={this.state.topics}
          more={this.state.more}
          showcaseProject={this.state.showcaseProject}
          popup={this.state.popup}
          showPopup={this.state.showPopup}
          handleClosePopupAlertDialog={this.handleClosePopupAlertDialog}
          showShareGameDialog={this.state.showShareGameDialog}
          handleOpenShareGameDialog={this.handleOpenShareGameDialog}
          handleCloseShareGameDialog={this.handleCloseShareGameDialog}
          handleMore={() => this.handleMore()}
          user={this.props.user}
          team={this.props.team}
          notifications={this.props.notifications || 0}
          inboxUnread={this.props.inboxUnread || 0}
          language={this.props.language}
          handleKeyPress={this.handleKeyPress}
          handleSearchChange={this.handleSearchChange}
          handleSubmit={event => {
            this.handleSubmit(event);
          }}
        />
      );
    } else if (this.state.topics && !this.props.sessionKey) {
      /* No information is returned, but not logged in */

      if (this.state.project != null && this.state.project.private) {
        /* Private project */
        return (
          <GenericErrorPage
            routeUrl={this.getLoginRoute()}
            routeName={localize("button_login", this.props.language)}
            image={this.state.project.projectBannerImage}
            title={this.state.project.projectTitle}
            message={PRIVATE_GAME_ERROR}
            isBackRoute={false}
            language={this.props.language}
          />
        );
      } else {
        /* Other project error scenarios (eg. unpublished, deleted... etc) */
        return (
          <GenericErrorPage
            routeUrl={this.getLoginRoute()}
            routeName={localize("button_login", this.props.language)}
            message={localize("unavailable_game_long", this.props.language)}
            messageList={UNAVAILABLE_GAME_ERROR_LIST}
            isBackRoute={false}
            language={this.props.language}
          />
        );
      }
    } else if (this.state.topics) {
      /* No information is returned, but logged in */

      if (this.state.project != null && this.state.project.private) {
        /* Private project */
        return (
          <GenericErrorPage
            image={this.state.project.projectBannerImage}
            title={this.state.project.projectTitle}
            message={PRIVATE_GAME_ERROR_LOGGEDIN}
            isBackRoute={false}
            language={this.props.language}
          />
        );
      } else {
        /* Other project error scenarios (eg. unpublished, deleted... etc) */
        return (
          <GenericErrorPage
            routeUrl={SINGLE_PROJECT_APP || IS_EMBEDDED_PROJECT ? null : HOME}
            routeName={localize("icon_home", this.props.language)}
            message={localize("unavailable_game_long", this.props.language)}
            messageList={UNAVAILABLE_GAME_ERROR_LIST_LOGGEDIN}
            language={this.props.language}
          />
        );
      }
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer);
