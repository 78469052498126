import React from "react";
import { connect } from "react-redux";

import { HOME } from "App/Routes";
import {
  mapStateToProps,
  mapDispatchToProps,
  ChallengeContainer
} from "components/Challenge/ChallengeContainer";
import ChallengePage from "./ChallengePage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import sessionStorageService from "services/sessionStorageService";
import localize from "lang/localize";

import { getChallengeType, getPoints } from "services/challengeServices";

export class CyberwellnessChallengeContainer extends ChallengeContainer {
  render() {
    const data = this.state.data;
    const errorTopic = this.state.errorTopic;
    const UNAVAILABLE_CHALLENGE_ERROR_LIST = [
      localize("unavailable_challenge_private_topic", this.props.language),
      localize("unavailable_challenge_private_project", this.props.language),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language)
    ];
    const UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_challenge_private_topic_loggedin",
        this.props.language
      ),
      localize(
        "unavailable_challenge_private_project_loggedin",
        this.props.language
      ),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language)
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    if (data) {
      return (
        <ChallengePage
          key={data.id}
          user={this.props.user}
          sessionKey={this.props.sessionKey}
          // claimform
          challengeTypeId={data.challengeTypeId}
          isConfirmationChallenge={
            data.challengeTypeId === 11 && data.confirmation === true
          }
          photoOnly={data.photoOnly}
          noPhoto={data.noPhoto}
          quizOptions={data.quizOptions}
          privateClaim={data.privateClaim}
          fields={data.fields}
          completionMessage={data.completionMessage}
          multiSelect={!!data.multiSelect}
          gridWidth={data.gridWidth ? data.gridWidth : 0}
          claimed={data.claimed}
          claimedBefore={data.claimedBefore}
          expired={!!data.expired}
          submitted={this.state.submitted}
          videoDurationLimit={data.videoDurationLimit}
          // location
          latitude={data.latitude}
          longitude={data.longitude}
          addressFormatted={data.addressFormatted}
          // challengeresult
          id={data.id}
          correctAnswerNo={data.correctAnswerNo}
          wrongAnswerNo={data.wrongAnswerNo}
          correctAnswerTitle={data.correctAnswerTitle}
          correctAnswer={data.correctAnswer}
          // image
          imageMedium={data.imageMedium}
          // embed
          medias={data.medias ? data.medias : []}
          // text
          footnote={data.footnote}
          title={data.title}
          repeat={data.repeat}
          repeatUntilCorrect={!!data.repeatUntilCorrect}
          repeatAtFormatted={data.repeatAtFormatted}
          videoDurationLimitFormatted={data.videoDurationLimitFormatted}
          // stats
          commentNo={data.commentNo}
          claimNo={data.claimNo}
          previousChallengeId={data.previousChallengeId}
          nextChallengeId={data.nextChallengeId}
          challengeType={getChallengeType(
            data.challengeTypeId,
            data.photoOnly,
            data.multiSelect,
            data.challengeType
          )}
          points={getPoints(data.challengeTypeId, data.points)}
          challengeReferralLink={data.challengeReferralLink}
          isLiked={!!this.state.isLiked}
          likeNo={this.state.likeNo}
          isBookmarked={!!this.state.isBookmarked}
          handleBookmark={this.handleBookmark.bind(this)}
          handleLike={this.handleLike}
          postClaim={this.postClaim}
          postQRClaim={this.postQRClaim}
          showAlertWithTimeout={this.props.showAlertWithTimeout}
          adminToClaim={this.state.adminToClaim}
          // login dialogs
          showClaimLoginDialog={this.state.showClaimLoginDialog}
          handleOpenClaimLoginDialog={this.handleOpenClaimLoginDialog}
          handleCloseClaimLoginDialog={this.handleCloseClaimLoginDialog}
          showBookmarkLoginDialog={this.state.showBookmarkLoginDialog}
          handleOpenBookmarkLoginDialog={this.handleOpenBookmarkLoginDialog}
          handleCloseBookmarkLoginDialog={this.handleCloseBookmarkLoginDialog}
          showLikeLoginDialog={this.state.showLikeLoginDialog}
          handleOpenLikeLoginDialog={this.handleOpenLikeLoginDialog}
          handleCloseLikeLoginDialog={this.handleCloseLikeLoginDialog}
          // image dialogs
          showImageDialog={this.state.showImageDialog}
          handleOpenImageDialog={this.handleOpenImageDialog}
          handleCloseImageDialog={this.handleCloseImageDialog}
          // embed dialogs
          showEmbedDialog={this.state.showEmbedDialog}
          handleOpenEmbedDialog={this.handleOpenEmbedDialog}
          handleCloseEmbedDialog={this.handleCloseEmbedDialog}
          // qr dialogs
          showQRModal={this.state.showQRModal}
          handleOpenQRModal={this.handleOpenQRModal}
          handleCloseQRModal={this.handleCloseQRModal}
          // minigame dialogs
          showExternalChallengeDialog={this.state.showExternalChallengeDialog}
          handleOpenExternalChallengeDialog={
            this.handleOpenExternalChallengeDialog
          }
          handleCloseExternalChallengeDialog={
            this.handleCloseExternalChallengeDialog
          }
          // language
          language={this.props.language}
          // contact email
          contactEmail={this.state.contactEmail}
          // locked content handling
          locked={this.state.locked}
          lockedChallengeTopicId={errorTopic ? errorTopic.id : null}
          lockedChallengeTopicTitle={errorTopic ? errorTopic.title : ""}
          // project and topic information
          project={data.game}
          topic={data.quest}
          /* Custom: next topic id */
          topicIdNext={data.topicIdNext}
        />
      );
    } else if (data === false && !this.props.sessionKey) {
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST}
          endMessage={localize(
            "unavailable_challenge_login_prompt",
            this.props.language
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (data === false) {
      return (
        <GenericErrorPage
          routeUrl={IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessChallengeContainer);
