import React from "react";
import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
  ProfileDrawerContainer
} from "components/ProfileDrawer/ProfileDrawerContainer";
import ProfileDrawer from "components/ProfileDrawer/ProfileDrawer";

import { SINGLE_PROJECT_APP, ENABLE_INBOX } from "config";

export class CyberwellnessProfileDrawerContainer extends ProfileDrawerContainer {
  /* Custom: Removed IS_MOBILE_PROJECT_PAGE check */
  render() {
    if (this.props.profileDrawer.state === "no-profile-drawer") {
      return null;
    } else {
      return (
        <ProfileDrawer
          user={this.props.user}
          projectId={this.props.projectId}
          project={this.props.project}
          notifications={this.props.notifications || 0}
          inboxUnread={this.props.inboxUnread || 0}
          points={this.props.points || 0}
          loggedIn={this.isLoggedIn()}
          singleProjectApp={SINGLE_PROJECT_APP}
          // passing in ENABLE_INBOX as a prop for Jest tests
          enableInbox={ENABLE_INBOX}
          language={this.props.language}
        />
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberwellnessProfileDrawerContainer);
