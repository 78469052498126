import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import ProfilePage from "./ProfilePage";
import Loading from "components/shared/Loading";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import {
  showAlertWithTimeout,
  addProfileDrawerPoints,
  showAwardableActionWithTimeout,
  showAchievements,
  setProject,
  setButtons
} from "actions";
import { LOAD_MORE_COUNT } from "config";
import getApiGenerator from "services/getApiGenerator";
import {
  GET_USER,
  GET_PROJECT_ACTIVTY,
  GET_PROJECT_ITEMS,
  GET_TOPICS
} from "services/api";
import {
  PROJECT_ACHIEVEMENTS,
  PROJECT_USER_ACHIEVEMENTS,
  ACHIEVEMENTS,
  USER_ACHIEVEMENTS
} from "App/Routes";
import localize from "lang/localize";

import { likeClaim } from "services/challengeServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    },
    addProfileDrawerPoints: points => {
      dispatch(addProfileDrawerPoints(points));
    },
    showAwardableActionWithTimeout: awardableAction => {
      dispatch(showAwardableActionWithTimeout(awardableAction));
    },
    showAchievements: achievements => {
      dispatch(showAchievements(achievements));
    },
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    }
  };
};

const propTypes = {
  id: PropTypes.string
};

const defaultProps = {
  id: null
};

export class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      ownProfile: !props.id || props.user.id === parseInt(props.id, 10),
      id: props.id || props.user.id,
      claims: null,
      page: 1,
      more: false,
      type: "project",
      tab: "achievements",
      contactEmail: null,
      topics: null,
      items: [],
      itemsLoading: false,
      error: null,
      code: null,
      isLoadingMore: false
    };

    this.removeClaim = this.removeClaim.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.getActivity = this.getActivity.bind(this);
  }

  componentDidMount() {
    if (!this.state.ownProfile || this.props.sessionKey) {
      /* handling for when achievements take a while to load */
      this.setState({ itemsLoading: true }, () => {
        this.getUserProfile();
      });
    }

    if (this.props.projectId) {
      this.getTopics();
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sessionKey !== this.props.sessionKey) {
      this.setState(
        {
          id: this.props.id || this.props.user.id,
          ownProfile:
            !this.props.id || this.props.user.id === parseInt(this.props.id, 10)
        },
        this.getUserProfile
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error
          });
        }
      } else {
        /*
          Specific only to ProfileContainer: when populating Project information,
          we will also be saving the contactEmail for reporting purposes
        */
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: "",
          contactEmail: res.body.game.contactEmail
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  getUserProfile() {
    getApiGenerator(
      GET_USER.format(this.state.id),
      {
        bundle_id: this.props.projectId
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            user: false,
            itemsLoading: false
          });
        }
      } else {
        this.setState(
          {
            user: res.body
          },
          () => {
            this.getActivity(this.state.page);

            /* use different API (sessionKey and projectId required, and must be own profile) to get item list */
            if (
              this.props.projectId &&
              this.props.sessionKey &&
              this.state.ownProfile
            ) {
              this.getProjectItems();
            } else {
              /* item list from default getUserProfile() does not show locked achievements */
              this.setState({
                items: res.body.itemPhotos ? res.body.itemPhotos : [],
                itemsLoading: false
              });
            }
          }
        );
      }
    });
  }

  getProjectItems() {
    // does not support pagination
    getApiGenerator(
      GET_PROJECT_ITEMS.format(this.props.projectId),
      {
        user_id: this.props.user.id
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            items: [],
            itemsLoading: false
          });
        }
      } else {
        this.setState({
          items: res.body.data,
          itemsLoading: false
        });
      }
    });
  }

  getNavLink() {
    const projectId = this.props.projectId;
    const ownProfile = this.state.ownProfile;
    const user = this.state.user;
    if (user) {
      if (projectId && ownProfile) {
        return PROJECT_ACHIEVEMENTS.format(projectId);
      } else if (projectId && !ownProfile) {
        return PROJECT_USER_ACHIEVEMENTS.format(projectId, user.id);
      } else if (!projectId && ownProfile) {
        return ACHIEVEMENTS;
      } else if (!projectId && !ownProfile) {
        return USER_ACHIEVEMENTS.format(user.id);
      } else {
        return "/";
      }
    } else {
      return "/";
    }
  }

  getActivity(page) {
    if (this.state.user) {
      getApiGenerator(
        GET_PROJECT_ACTIVTY.format(this.props.projectId),
        {
          page: page,
          user_id: this.state.user.id,
          limit: LOAD_MORE_COUNT
        },
        this.props.sessionKey
      ).end((err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.code === 500) {
            this.setState({
              claims: [],
              more: false,
              page: 1,
              isLoadingMore: false
            });
          }
        } else {
          this.setState({
            claims: this.state.claims
              ? this.state.claims.slice().concat(res.body.data)
              : res.body.data,
            page: this.state.page + 1,
            more: res.body.more,
            isLoadingMore: false
          });
        }
      });
    }
  }

  handleLike = (event, id) => {
    likeClaim(event, id, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        if (res.body.actionPoints && res.body.actionPoints > 0) {
          this.props.addProfileDrawerPoints(res.body.actionPoints);
          this.props.showAwardableActionWithTimeout({
            numberStr: "" + res.body.actionPoints.abbreviateNumber(),
            unit: localize("points_just_text", this.props.language)
          });
        }
        if (res.body.items) {
          this.props.showAchievements(res.body.items);
        }
        const claims = this.state.claims.slice();
        const claim = claims.filter(claim => claim.id === id)[0];
        claim.ratedLike = res.body.rated;
        claim.likeNo = res.body.likeNo;
        this.setState({
          claims: claims
        });
      }
    });
  };

  handleMore = debounce(() => {
    const {
      getActivity,
      state: { more, isLoadingMore, page }
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.scrollingElement.scrollHeight * 0.9
      ) {
        this.setState(() => ({
          isLoadingMore: true
        }));
        getActivity(page);
      }
    }
  }, 100);

  handleToggleTab = event => {
    this.setState({ tab: event.currentTarget.id });
  };

  removeClaim(claimId) {
    this.setState({
      claims: this.state.claims.filter(element => element.id !== claimId)
    });
  }

  handleComments = (id, commentNo) => {
    const claims = this.state.claims.slice();
    const claim = claims.filter(claim => claim.id === id)[0];
    claim.commentNo = commentNo;
    this.setState({
      claims: claims
    });
  };

  render() {
    const user = this.state.user;
    const navLink = this.getNavLink();
    if (this.state.ownProfile && !this.props.user.id) {
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (user) {
      return (
        <ProfilePage
          isLoadingMore={this.state.isLoadingMore}
          sessionKey={this.props.sessionKey}
          id={user.id}
          name={user.name}
          points={user.points}
          userRankStatus={user.userRankStatus}
          userRankStatusImage={user.userRankStatusImage}
          showCompletions={!!this.props.projectId}
          completions={user.claimNo}
          img={user.photoLarge}
          meta={user.meta || null}
          items={this.state.items}
          itemsLoading={this.state.itemsLoading}
          navLink={navLink}
          team={user.team ? user.team.name : null}
          teamId={user.team ? user.team.id : null}
          teamImg={user.team ? user.team.image : null}
          claims={this.state.claims}
          type={this.state.type}
          projectId={this.props.projectId || 0}
          more={this.state.more}
          handleMore={this.handleMore}
          handleLike={this.handleLike}
          removeClaim={this.removeClaim}
          userId={this.props.user.id}
          tab={this.state.tab}
          handleToggleTab={this.handleToggleTab}
          handleComments={this.handleComments}
          language={this.props.language}
          contactEmail={this.state.contactEmail}
        />
      );
    } else if (user === false) {
      return (
        <GenericErrorPage
          message={localize("unavailable_profile", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

ProfileContainer.propTypes = propTypes;
ProfileContainer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
